import { ColorScheme } from "../models/customisation";

export const colorSchemes: { [scheme: string]: ColorScheme } = {
  // spring: {
  //   primary: "#9aad63",
  //   secondary: "#9BB8CD",
  //   success: "#3498db",
  //   info: "#a862cb",
  //   warning: "#EEC759",
  //   danger: "#ec8d5f",
  //   light: "#e7e5e2",
  //   dark: "#2c3e50",
  // },
  // autumn: {
  //   primary: "#b865d9",
  //   secondary: "#607D8B",
  //   success: "#8BC34A",
  //   info: "#2196F3",
  //   warning: "#FFC107",
  //   danger: "#F44336",
  //   light: "#e7ecf1",
  //   dark: "#455A64",
  // },
  // winter: {
  //   primary: "#39A7FF",
  //   secondary: "#b48244",
  //   success: "#8BC34A",
  //   info: "#87C4FF",
  //   warning: "#FFC107",
  //   danger: "#F44336",
  //   light: "#d5e7ef",
  //   dark: "#455A64",
  // },
  // summer: {
  //   primary: "#FF90BC",
  //   secondary: "#8ACDD7",
  //   success: "#8BC34A",
  //   info: "#FFC0D9",
  //   warning: "#FFC107",
  //   danger: "#F44336",
  //   light: "#e7e7e7",
  //   dark: "#455A64",
  // },
  default: {
    primary: "#007bff",
    secondary: "#a4a8ab",
    success: "#28a745",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#e2e2e2",
    dark: "#343a40",
  },
  vodafone: {
    primary: "#e60000",
    secondary: "#bebebe",
    success: "#008a00",
    info: "#005ea5",
    warning: "#eb9700",
    danger: "#5d1010",
    light: "#e2e2e2",
    dark: "#0d0d0d",
  },
}; // used to move the most important filters to the beginning (query optimisation)
export const DEFAULT_COLORSCHEME = "vodafone";
