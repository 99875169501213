import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: "root",
})
export class TitleWrapperService {
  private appName = "PCS Portal";

  constructor(private title: Title, private router: Router, private logger: LoggerService) {}

  public setVcsTitle(): void {
    try {
      const [csp, section, page] = this.router.url
        .split("?")[0]
        .replace(new RegExp("^/vcloudsmart/(v[0-9]+)?", "i"), "")
        .split("/")
        .filter(Boolean)
        .slice(0, 3)
        .map(decodeURIComponent);

      let prefix = csp ? csp.toUpperCase() : "VCS";

      if (page) {
        prefix += ` ${page}`;
      } else if (section) {
        prefix += ` ${section}`;
      }

      this.setTitle(prefix);
    } catch (e) {
      this.logger.error(e);
    }
  }

  public getTitle(): string {
    return this.title.getTitle();
  }

  public setTitle(newTitle: string): void {
    try {
      if (!environment.production) {
        let env: string;
        if (environment.local) {
          env = "LOCAL";
        } else {
          env = environment.urlPcsApi.split(".")[0].split("/")[2].split("-")[0].toUpperCase();
        }
        this.title.setTitle(`${this.appName} [${env}] ${newTitle}`.trim());
      } else {
        this.title.setTitle(`${this.appName} ${newTitle}`.trim());
      }
    } catch (e) {
      this.logger.error(e);
    }
  }
}
