// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export enum logLevel {
  ERROR = 0,
  WARNING = 1,
  INFO = 2,
  DEBUG = 3,
}

export const environment = {
  production: false,
  local: false,
  cypress: false,
  log_level: logLevel.INFO,
  featureToggles: {
    asyncExport: true,
    budgetAndAlerts: true,
    requestAccess: true,
  },
  urlPcsApi: "https://dev-api.aws-shared.vodafone.com",
  url: "https://api.dev.pcs.vodafone.com/oaf/",
  callback: "https://portal.dev.pcs.vodafone.com/",
  urlVcloudApi: "https://vcs-v2-7qhomfz2.ew.gateway.dev",
  oauthUrl: "https://dev-pcs-api-auth.auth.eu-west-1.amazoncognito.com",
};
