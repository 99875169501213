import { ChartOptions } from "../models/google-chart";
import { ValidChartType } from "../models/interactive-chart";

export const CHART_OPTIONS: { [chartType: string]: ChartOptions } = {
  ColumnChart: {
    chartArea: {
      top: 60,
      right: 20,
      left: 50,
      bottom: 60,
    },
    height: 400,
    legend: { position: "none" },
    animation: {
      startup: true,
      duration: 1000,
      easing: "inAndOut",
    },
  },
  PieChart: {
    chartArea: {
      top: 60,
      right: 20,
      left: 20,
      bottom: 20,
    },
    legend: { position: "top", alignment: "center" },
    pieHole: 0.5,
    height: 400,
    // pieSliceText: "value",
    animation: {
      startup: true,
      duration: 1000,
      easing: "inAndOut",
    },
  },
  BarChart: {
    chartArea: {
      top: 0,
      right: 20,
      left: 180,
      bottom: 20,
    },
    height: 400,
    legend: { position: "none" },
    animation: {
      startup: true,
      duration: 1000,
      easing: "inAndOut",
    },
  },
  AreaChart: {
    chartArea: {
      top: 20,
      right: 20,
      left: 50,
      bottom: 80,
    },
    height: 400,
    legend: { position: "none" },
    animation: {
      startup: true,
      duration: 1000,
      easing: "inAndOut",
    },
  },
  // Bar: {
  //   chartArea: {
  //     top: 60,
  //     right: 20,
  //     left: 20,
  //     bottom: 0,
  //   },
  //   height: 400,
  //   legend: { position: "none" },
  //   animation: {
  //     startup: true,
  //     duration: 1000,
  //     easing: "inAndOut",
  //   },
  // },
};

export const VALID_CHARTS: ValidChartType[] = Object.keys(CHART_OPTIONS) as ValidChartType[];
