import { Definition } from "../models/definitions";
import * as showdown from "showdown";

export const markdownConverter = new showdown.Converter({
  backslashEscapesHTMLTags: false,
  completeHTMLDocument: false,
  disableForced4SpacesIndentedSublists: true,
  ellipsis: true,
  emoji: false,
  encodeEmails: false,
  ghCodeBlocks: false,
  ghMentions: false,
  headerLevelStart: 3,
  literalMidWordUnderscores: true,
  noHeaderId: true,
  openLinksInNewWindow: true,
  parseImgDimensions: false,
  requireSpaceBeforeHeadingText: true,
  simpleLineBreaks: true,
  simplifiedAutoLink: true,
  smartIndentationFix: false,
  smoothLivePreview: false,
  splitAdjacentBlockquotes: true,
  strikethrough: true,
  tables: true,
  tablesHeaderId: false,
  tasklists: false,
  underline: true,
  metadata: false,
});

const REGEX_BOLD = /\*\*(.*?)\*\*/g;
const REGEX_UNDERLINE = /__(.*?)__/g;
const REGEX_LINK = /\[([^\]]+)\]\(([^)]+)\)/g;
const REGEX_DOLLAR = /\\+[$]/g;

export function convertMarkdownToHTML(s: string): string {
  s = s.trim();
  try {
    for (const tag of ["p", "div", "span"]) {
      const start = `<${tag}>`;
      const end = `</${tag}>`;
      if (s.startsWith(start) && s.endsWith(end)) {
        s = s.slice(start.length, -end.length);
      }
    }
    s = s.replace(REGEX_DOLLAR, "$").trim();
    return markdownConverter
      .makeHtml(s)
      .replace(REGEX_BOLD, "<strong>$1</strong>")
      .replace(REGEX_UNDERLINE, "<u>$1</u>")
      .replace(REGEX_LINK, '<a target="_blank" href="$2">$1</a>')
      .trim();
  } catch (e) {
    return s;
  }
}

const commonDefinitions: Definition[] = [
  {
    term: "Exchange rate",
    needsExpanding: true,
    definition:
      "VCloudSmart allows you to view data in multiple currencies (USD/GBP/EUR). Select the currency on the left in the sliding sidebar while on a VCloudSmart report. The rate is a fixed value which we get from VAMS (SAMS) at the beginning of each financial year.",
  },
  {
    term: "Discounts",
    needsExpanding: true,
    definition:
      "Discounts refer to the various pricing incentives and cost-saving mechanisms offered by the cloud provider. These discounts are designed to encourage long-term commitment, higher usage volumes, or specific usage patterns within the cloud ecosystem.",
  },
  {
    term: "Credits",
    needsExpanding: true,
    definition:
      "Credits refer to a form of currency provided by the cloud provider to customers, typically as part of a promotional offer or partnership agreement. These credits can be used by customers to offset the costs associated with using cloud services. ",
  },
  {
    term: "Forecasting",
    needsExpanding: true,
    definition: `
    
The practice of predicting (ie estimating) future cloud spend, usually based on a combination of historical spending and an evaluation of future plans.
 
You can use these predicted values to drive your business decisions.

    `,
  },
  {
    term: "Infrastructure as a Service (IaaS)",
    needsExpanding: true,
    definition: `
is a fundamental cloud computing model that delivers on-demand access to virtualized computing resources over the internet. It abstracts away the complexities of physical hardware management, allowing organizations to provision and scale resources rapidly and efficiently.

**Key Components of IaaS:**

* **Virtual Machines (VMs):**  Software-defined emulations of physical servers, providing dedicated processing power, memory, and storage.
* **Storage:** Scalable and durable storage solutions, including object storage, block storage, and file storage.
* **Networking:**  Virtual networks, load balancers, firewalls, and other networking components for secure and reliable connectivity.

**Characteristics of IaaS:**

* **Highly Automated:**  Resource provisioning, configuration, and management are typically automated through user interfaces or APIs.
* **Self-Service Provisioning:** Users can access and manage resources independently, without requiring intervention from the cloud provider.
* **Resource Pooling:**  Cloud providers aggregate resources from multiple physical servers and dynamically allocate them to users as needed.
* **Rapid Elasticity:**  Resources can be scaled up or down quickly and easily to accommodate fluctuating workloads.
* **Measured Service:**  Usage is tracked and billed based on consumption, allowing for granular cost control.

**Advantages of IaaS:**

* **Cost Efficiency:**  Eliminates the capital expenses associated with owning and maintaining physical infrastructure.
* **Agility and Scalability:**  Rapidly adapt to changing business needs by scaling resources on demand.
* **Flexibility and Customization:**  Choose from a wide range of operating systems, software, and configurations.
* **Focus on Core Business:**  Free up IT teams from managing infrastructure, allowing them to focus on strategic initiatives.
      
      `,
  },
  {
    term: "Platform as a Service (PaaS)",
    needsExpanding: true,
    definition: `
Provides a complete environment for developing, running, and managing applications without the overhead of managing the underlying infrastructure. Think of it as a ready-made platform where you bring your code and let the provider handle the rest.

**Key Features of PaaS:**

* **Pre-Configured Environment:** Offers a pre-configured runtime environment with operating systems, middleware, databases, and other essential tools.
* **Application-Centric Tools:** Provides integrated development tools, deployment automation, and application monitoring capabilities.
* **Scalability and High Availability:**  Built-in scalability and high availability features, allowing applications to handle fluctuating workloads.
* **Simplified Deployment:** Streamlines application deployment and management, reducing time to market.
* **Managed Security:**  Includes security features such as access control, data encryption, and vulnerability management.

**Benefits of PaaS:**

* **Accelerated Development:** Focus on building applications, leaving infrastructure management to the provider.
* **Reduced Operational Complexity:**  Eliminate the need to manage servers, operating systems, and other infrastructure components.
* **Faster Time-to-Market:** Streamlined deployment processes allow for faster application releases.
* **Cost Optimization:** Pay only for the resources consumed, reducing operational costs.
* **Improved Collaboration:** Enables development teams to work together efficiently in a shared environment.
      
      `,
  },
  {
    term: "Software as a Service (SaaS)",
    needsExpanding: true,
    definition: `
delivers software applications over the internet on a subscription basis. It's the most complete cloud computing model, where users access fully functional applications without managing any infrastructure or software.

**Key Features of SaaS:**

* **Ready-to-Use Applications:** Provides access to fully functional software applications, typically accessible through a web browser.
* **Centralized Management:**  The provider handles all aspects of software maintenance, updates, and security.
* **Subscription-Based Pricing:**  Users pay a recurring fee for access to the software, often with tiered pricing based on usage or features.
* **Accessibility and Collaboration:**  Applications are accessible from anywhere with an internet connection, facilitating collaboration among users.
* **Integration Capabilities:**  Often includes APIs and integrations with other software systems.

**Benefits of SaaS:**

* **Lower Upfront Costs:**  Avoid large upfront investments in software licenses and hardware.
* **Reduced IT Burden:**  Eliminate the need for software installation, maintenance, and updates.
* **Scalability and Flexibility:**  Easily scale up or down usage based on changing needs.
* **Automatic Updates:** Benefit from continuous software updates and new features delivered by the provider.
* **Accessibility:** Access applications from any device with an internet connection.

**Examples of SaaS Providers:**

* Salesforce
* Microsoft 365
* Google Workspace
* Dropbox
* Zoom
      `,
  },
].map((d) => ({ ...d, definition: convertMarkdownToHTML(d.definition) }));

export const gcpDefinitions: Definition[] = [
  {
    term: "Actual Cost",
    formula: "Actual Cost = Usage Cost + Credits",
    definition: `Actual cost, also referred to as GCP cost, is the sum of expenses directly related to the usage of GCP services.
      
This includes charges for:

* **Compute Time:**  The amount of time your virtual machines (VMs) are running.
* **Storage Usage:** The amount of data stored in Cloud Storage, databases, and other GCP storage services.
* **Network Egress:**  The amount of data transferred out of GCP to external networks (e.g., transferring data to users outside of GCP). 
      `,
  },
  {
    term: "Total Cost",
    formula: "Total Cost = Actual Cost + Support Cost + Other Org. Cost + VPC charges + PCS Enabler Cost",
    definition:
      "Total cost represents the financial impact of our cloud usage including all charges, credits and discounts from Google as well as internal charges.",
  },
  {
    term: "VPC Charge",
    formula: "VPC Cost = 4.5% * (Actual Cost + Support Cost + Other Org. Charges)",
    definition:
      "Additional charge from the VPC (Vodafone Procurement Company) team to cover the effort related to finance.",
  },
  {
    term: "PCS Enabler Cost",
    readMore: "https://confluence.sp.vodafone.com/x/kQR4C",
    formula: "PCS Enabler Cost = 3% * Usage Cost",
    definition:
      "Additional charge from Public Cloud Services (PCS) to cover the effort related to managing and configuring the cloud estate and for dealing with compliance and security. ",
  },
  {
    term: "Other Org. Charges",
    needsExpanding: true,
    definition:
      "Other org. charges include the cost incurred for common software licenses and SCC(Security Control Center).Charges are not specific to projects and it is billing account level. We are distributing these charges to project level",
    formula: "Other Org. Charges = (Project's cost/Billing account's cost) * Support Cost (or) Other Org Cost",
  },
  {
    term: "Promotion",
    needsExpanding: true,
    definition: `
      
discounts applied to your Google Cloud usage. These discounts can come in various forms, such as:

* **Sustained use discounts:** Automatic discounts applied based on the amount of resources you use over a given month.  You don't need to do anything to get these; they're applied automatically if you meet the usage thresholds.
* **Committed use discounts:** Discounts you get by committing to use a specific amount of resources for a set term (1 or 3 years). These offer steeper discounts than sustained use discounts.
* **Free Tier:**  Usage within the free tier for certain services doesn't incur charges. This is considered a type of promotion.
* **Credits:** Google sometimes offers promotional credits that can be applied towards your bill.  These might be for new customers, specific product trials, or other marketing campaigns.
* **Spend-based discounts:** Discounts awarded when you reach certain spending thresholds within a billing cycle.
      
      `,
  },
  {
    term: "Usage Cost",
    needsExpanding: true,
    definition:
      "The amount charged based on the resources and services you utilize. It encompasses compute, storage, networking, and additional services like databases, machine learning, and Big Data processing...",
  },
  {
    term: "Support Cost & Other Org. Costs",
    needsExpanding: true,
    definition:
      "Charges are not specific to projects and it is billing account level. We are distributing these charges to project level based on below formula: (Project's cost/Billing account's cost) * Support cost (or) Other org cost",
  },
  {
    term: "CUDs (Commitments)",
    needsExpanding: true,
    definition: `
    
Committed Use Discounts (CUDs) offer discounted pricing on GCP services in exchange for committing to a minimum level of spending over a fixed term (usually one or three years). 

**How they work:**

* **Commitment:** You agree to use a specific amount of a service (e.g., a certain amount of compute capacity or storage) for the term of the CUD.
* **Discount:** In return for your commitment, you receive a significant discount on the services included in the CUD.
* **Minimum Spend:** It's important to note that you'll be billed for the minimum commitment amount even if your actual usage is lower. 

**Example:**  If you commit to using $10,000 worth of compute resources per month for a year with a 20% CUD, you'll save $2,000 per month, but you'll still pay $8,000 even if your actual usage is lower.

    
    `,
  },
  {
    term: "Serverless",
    needsExpanding: true,
    readMore: "https://cloud.google.com/solutions/serverless",
    definition:
      "Serverless technologies feature automatic scaling, built-in high availability, and a pay-for-use billing model. These technologies eliminate infrastructure management tasks like capacity provisioning and patching, so you can focus on writing code. Example services: Cloud Functions, Cloud Storage",
  },
  {
    term: "Rightsizing",
    needsExpanding: true,
    readMore:
      "https://medium.com/@NickHystax/rightsizing-recommendations-get-gcp-costs-down-with-google-cloud-optimization-services-60cdf8b6f1bf",
    definition:
      "Rightsizing refers to the optimization of your cloud resources to ensure that you are using the most appropriate instance types and sizes for your workloads. This process involves analyzing your current usage patterns and performance requirements to match them with the most cost-effective and efficient GCP resources.",
  },
  {
    term: "Google Cloud Support",
    needsExpanding: true,
    readMore: "https://cloud.google.com/support/premium?hl=en",
    definition:
      "Provides us with 24/7, 15-minute initial response time for business-critical issues as well as guidance from a named Technical Account Manager.",
  },
  {
    term: "On-Demand Instances (Compute Engine)",
    needsExpanding: true,
    readMore: "https://cloud.google.com/compute/all-pricing",
    definition:
      "With On-Demand Instances, you pay for compute capacity by the second with no long-term commitments. You have full control over the instance's lifecycle—you decide when to launch, stop, hibernate, start, reboot, or terminate it.\n\nThere is no long-term commitment required when you purchase On-Demand Instances. ",
  },
  {
    term: "Budget Variance",
    needsExpanding: true,
    readMore: "https://www.investopedia.com/terms/b/budget-variance.asp",
    definition: "Difference between budgeted cost and usage cost.",
  },
  {
    term: "BigQuery",
    needsExpanding: true,
    readMore: "https://cloud.google.com/bigquery/?hl=en",
    definition:
      "BigQuery is a fully managed date warehouse. You can use it to store structured and semi-structured data and run complex SQL queries on it.",
  },
  {
    term: "Cloud Storage",
    needsExpanding: true,
    readMore: "https://cloud.google.com/storage/?hl=en",
    definition:
      "Cloud Storage is a managed service for storing unstructured data. You can store any amount of data and retrieve it as often as you like. It's used for use cases such as data lakes, websites, mobile applications, backup and restore, archive, enterprise applications, IoT devices, and big data analytics. Data is organised into buckets and objects.",
  },
  {
    term: "Compute Engine",
    needsExpanding: true,
    readMore: "https://cloud.google.com/products/compute/?hl=en",
    definition: "Allows to easily create and run online VMs on high-performance, reliable cloud infrastructure.",
  },
  {
    term: "Compute Storage Disks",
    needsExpanding: true,
    readMore: "https://cloud.google.com/compute/docs/disks/",
    definition: `
In Google Compute Engine (GCE), storage volumes are used to provide persistent storage for your virtual machine (VM) instances. When you create a VM, you need to attach a **boot disk** that contains the operating system. You can also attach **additional disks** to store data, applications, or other files.

**Types of Persistent Disks:**

* **Standard HDD:** Cost-effective hard disk drives suitable for frequently accessed data and workloads that are less sensitive to storage latency. 
* **SSD:** Solid-state drives that provide higher performance, lower latency, and are ideal for I/O-intensive applications, databases, and boot disks.
* **Balanced PD:** A balance of price and performance, well-suited for general-purpose applications.

**When to Use Additional Disks:**

* **Separate Data from OS:** Store application data, logs, and other files on separate disks from the boot disk for better organization and performance.
* **Increased Storage Needs:** Provision additional disks as your storage requirements grow, ensuring your applications have sufficient space.
* **Performance Optimization:** Use higher-performance disks (like SSDs) for workloads that demand low latency and high throughput.

By carefully selecting the appropriate disk types and optimizing your storage configuration, you can improve the performance and cost-efficiency of your applications running on Compute Engine.`,
  },
]
  .map((d: Definition): Definition => ({ ...d, definition: convertMarkdownToHTML(d.definition) }))
  .concat(commonDefinitions);

export const awsDefinitions: Definition[] = [
  {
    term: "Actual Cost",
    formula:
      "Actual cost = Usage amount + Tax + Fee + Discounts + Others (Saving Plan negation + Saving Plan Covered Usage + Credits)",
    readMore:
      "https://aws.amazon.com/blogs/aws-cloud-financial-management/understanding-your-aws-cost-datasets-a-cheat-sheet/",
    definition: `"Actual Cost" in AWS refers to the unblended cost of your AWS resource usage, recorded on the day the charges are applied to your account. This reflects a cash basis accounting approach, where costs are recognized upon payment.`,
  },
  {
    term: "Total Cost",
    formula: "Total Cost = Actual cost + VPC charges + PCS Enabler Cost",
    definition:
      "Total cost represents the financial impact of our cloud usage including all charges, credits and discounts from AWS as well as internal charges.",
  },
  {
    term: "Tax",
    needsExpanding: true,
    readMore: "https://aws.amazon.com/tax-help/",
    definition: `
  Taxes refer to compulsory financial charges or levies imposed by governmental authorities (such as federal, state, local, or international jurisdictions) on your AWS service usage. These taxes are applied based on applicable tax laws and regulations, and they may vary depending on several factors:
        
  - **Account's Country of Operation**: The tax rates and types of taxes may differ based on the country or region where your AWS account is registered.
  - **Services Used**: Different AWS services may attract different tax rates or categories. Some regions may exempt certain services from taxes, while others may impose higher rates on specific service types.
  - **Customer’s Tax Exempt Status**: If your organization qualifies for tax exemptions (e.g., non-profit status, government entity), those exemptions may reduce or eliminate the taxes on certain AWS services. These settings can be configured in your AWS account's billing console.
  - **Tax Settings**: AWS allows account holders to configure their tax settings, including setting up tax exemptions, tax identification numbers (TINs), and other relevant details. These settings will determine how taxes are calculated and applied to your billing.
  - **Tax Invoicing and Reporting**: AWS provides detailed tax invoices, which specify the taxes applied to your services, broken down by jurisdiction. These invoices are important for compliance with local tax laws and for use in accounting and tax filings.
  `,
  },
  {
    term: "Usage Cost",
    formula: "Usage Cost = Usage Quantity * Usage Rate",
    definition:
      "Cost before any discounts, credits, upfront fees, or taxes are factored in. It's based on the service's pricing model and how much of the service you consumed.",
  },
  {
    term: "VPC Charge",
    formula: "VPC Charge = 4.5% * Actual Cost",
    definition:
      "Additional charge from the VPC (Vodafone Procurement Company) team to cover the effort related to finance.",
  },
  {
    term: "PCS Enabler Cost",
    readMore: "https://confluence.sp.vodafone.com/x/AbOACQ",
    needsExpanding: true,
    formula: "PCS Enabler Cost = 3% * (Actual Cost - Tax)",
    definition:
      "Additional charge from Public Cloud Services (PCS) to cover the effort related to managing and configuring the cloud estate and for dealing with compliance and security.",
  },
  {
    term: "EDP (Enterprise) Discount",
    needsExpanding: true,
    readMore: "https://cloud.folio3.com/blog/aws-enterprise-discount-program/",
    definition: `The AWS Enterprise Discount Program (EDP) offers huge cost savings on AWS usage fees to enterprise cloud users. 
      
In exchange for a committed long-term relationship, typically ranging from one to five years, customers receive a substantial, fixed discount on their AWS expenses.`,
  },
  {
    term: "Savings Plans",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/savingsplans/latest/userguide/what-is-savings-plans.html",
    definition: `
      
Savings Plans (SP) offer a flexible pricing model that provides savings on AWS usage. 
      
- You can save up to 72 percent on your AWS compute workloads.
- Compute Savings Plans provide lower prices on Amazon EC2 instance usage regardless of instance family, size, OS, tenancy, or AWS Region.

**IMPORTANT**: the cost savings and spend in SP recommendations DO NOT include tax or EDP discount.

`,
  },
  {
    term: "Reserved Instances",
    needsExpanding: true,
    readMore: "https://aws.amazon.com/aws-cost-management/aws-cost-optimization/reserved-instances/",
    definition: `
For certain services like Amazon EC2 and Amazon RDS, you can invest in reserved capacity.

With Reserved Instances (RI, also known as Reservations), you can save up to 72% over equivalent on-demand capacity. 

Reserved Instances are available in 3 options:

* All up-front (AURI)
* Partial up-front (PURI)
* No upfront payments (NURI)
 
When you buy Reserved Instances, **the larger the upfront payment, the greater the discount**.
 
To maximize your savings, you can pay all up-front and receive the largest discount.
 
**IMPORTANT**: the cost savings and spend in RI recommendations DO NOT include tax or EDP discount.
 `,
  },
  {
    term: "Spot Instances",
    needsExpanding: true,
    readMore: "https://aws.amazon.com/ec2/spot/",
    definition: `
Spot Instances offer a cost-effective method for utilizing spare EC2 compute capacity. Users bid on unused resources, gaining access to instances at significantly discounted rates compared to On-Demand pricing.  The trade-off lies in the potential for interruption, as Amazon can reclaim Spot Instances with a two-minute warning if capacity is needed elsewhere.

**Key Features:**

* **Dynamic Pricing:** Spot Instance prices fluctuate based on real-time supply and demand.
* **Interruption Risk:** Instances are subject to interruption, making them unsuitable for workloads requiring high availability.
* **Bidding System:** Users specify their maximum bid price, and instances run as long as the spot price remains below their bid.
* **Spot Fleets:**  Enable launching and managing a diversified collection of Spot Instances for greater capacity availability.

**Benefits:**

* **Significant Cost Reduction:**  Spot Instances can achieve cost savings of up to 90% compared to On-Demand instances.
* **Access to Excess Capacity:** Leverage a larger pool of compute resources for cost-sensitive workloads.

**Optimal Use Cases:**

* **Fault-Tolerant Applications:**  Workloads capable of handling interruptions and resuming operations seamlessly.
* **Short-Lived or Batch Processes:**  Jobs with a defined completion time or those that can be readily interrupted and resumed.
* **Flexible Workloads:** Applications that can adapt to varying instance types and availability.
      `,
  },
  {
    term: "AWS Support",
    needsExpanding: true,
    readMore: "https://aws.amazon.com/premiumsupport/",
    definition:
      "Provide you with proactive planning and communications, advisory, automation, and cloud expertise to help achieve business outcomes. With **Enterprise Support**, you get 24/7 technical support to support our environments. You also get access to a **Technical Account Manager** (TAM) who provides consultative architectural and operational guidance.",
  },
  {
    term: "Fee",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/cur/latest/userguide/Lineitem-columns.html",
    definition:
      "Any upfront annual fee that you paid for subscriptions. For example, the upfront fee that you paid for an All Upfront RI or a Partial Upfront RI",
  },
  {
    term: "RI Fee",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/cur/latest/userguide/Lineitem-columns.html",
    definition:
      "The monthly recurring fee for subscriptions. For example, the recurring fee for Partial Upfront RIs, No Upfront RIs, and All Upfronts that you pay every month.",
  },
  {
    term: "Discounted Usage",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/cur/latest/userguide/Lineitem-columns.html",
    definition: "The rate for any instances for which you had Reserved Instance (RI) benefits.",
  },
  {
    term: "Bundled Discount",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/cur/latest/userguide/Lineitem-columns.html",
    definition:
      "A usage-based discount that provides free or discounted usage of a service or feature based on the usage of another service or feature.",
  },
  {
    term: "Region",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html",
    definition: `In AWS resources are deployed into geographically separated "Regions".
  
This achieves the greatest possible fault tolerance and stability.

You can also achieve lower latency by deploying resources into a region that is geographically close to your target users.`,
  },
  {
    term: "Zone",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html",
    definition: `
Each Region is further subdivided into multiple, isolated locations known as Availability Zones (AZs).

While AZs within a Region are interconnected with **low-latency links**, they are designed to have different **risk profiles** due to separate infrastructure like power grids, cooling systems, and network connectivity.

This means that if one AZ is affected by an event like a flood, other AZs within the same Region can likely remain operational.

AZs are identified by their Region code followed by a letter, for example:

Region: us-east-1
Zone: us-east-1a

`,
  },
  {
    term: "Serverless",
    needsExpanding: true,
    readMore: "https://aws.amazon.com/serverless/",
    definition: `Serverless technologies feature automatic scaling, built-in high availability, and a pay-for-use billing model.
      
These technologies eliminate infrastructure management tasks like capacity provisioning and patching, so you can focus on writing code.

Example services: AWS Lambda, S3 Storage`,
  },
  {
    term: "On-Demand Instances (EC2)",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-on-demand-instances.html",
    definition: `With On-Demand Instances, you pay for compute capacity by the second with no long-term commitments. 
      
You have full control over the instance's lifecycle—you decide when to launch, stop, hibernate, start, reboot, or terminate it.

There is no long-term commitment required when you purchase On-Demand Instances.`,
  },
  {
    term: "Rightsizing",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/cost-management/latest/userguide/ce-rightsizing.html",
    definition: `
    
Rightsizing is the ongoing process of making sure your cloud resources are the right size and type for your needs. It's about finding the perfect balance between good performance and low cost.

Here's the simplified process:

1. **Take stock:** Figure out what resources you're using and how much they costs.
2. **Keep an eye on things:** Track how your resources are performing and how much of them you're actually using.
3. **Know your workloads:** Understand what your applications need to run smoothly.
4. **Make adjustments:** Based on what you learn, change your cloud setup:
    * Use smaller or fewer instances if you can.
    * Pick the right storage type for your data.
    * Use autoscaling to automatically adjust resources.
    * Get rid of anything you don't need anymore.
    * Consider serverless if it fits your needs.

By regularly checking and adjusting, you'll make sure you're getting the most out of your cloud, without overspending.
    
**IMPORTANT**: the cost savings and spend in rightsizing recommendations DO NOT include tax or EDP discount.
    `,
  },
  {
    term: "AWS EC2 (Virtual Machines - VMs)",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/concepts.html",
    definition: `
You can use Amazon EC2 to launch as many or as few virtual servers as you need, configure security and networking, and manage storage. 

You can add capacity (scale up) to handle compute-heavy tasks, such as monthly or yearly processes, or spikes in website traffic.`,
  },
  {
    term: "AWS S3 (Simple Storage Service)",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AmazonS3/latest/userguide/Welcome.html",
    definition: `Object storage service that offers scalability, availability, security, and performance. 
      
Use it to store any amount of data for a range of use cases, such as data lakes, websites, mobile applications, backup and restore, archive, enterprise applications, IoT devices, and big data analytics.

S3 organises data into buckets. Each 'file' is uploaded and becomes an 'S3 object'.`,
  },
  {
    term: "AWS Lambda (Serverless Functions)",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/lambda/latest/dg/welcome.html",
    definition: `Serverless compute service that lets you run code without provisioning or managing servers. 
      
Lambda runs your code on a high-availability compute infrastructure and performs all of the administration of the compute resources, including server and operating system maintenance, capacity provisioning and automatic scaling, and logging. 

All you need to do is supply your code in one of the language runtimes that Lambda supports. The Lambda runs your function only when needed and scales automatically.

You only pay for the compute time that you consume—there is no charge when your code is not running.`,
  },
  {
    term: "AWS RDS (Relational Database Service)",
    needsExpanding: true,
    readMore: "https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Welcome.html",
    definition: `Managed relational database service.
      
It makes it easier to set up, operate, and scale a relational database such as Postgres or MySQL in the AWS Cloud.

It provides cost-efficient, resizable capacity for an industry-standard relational database and manages common database administration tasks (patching/upgrades etc.).`,
  },
]
  .map((d: Definition): Definition => ({ ...d, definition: convertMarkdownToHTML(d.definition) }))
  .concat(commonDefinitions);

export const azureDefinitions: Definition[] = [
  {
    term: "Azure Cost",
    needsExpanding: false,
    formula: "Azure Cost = Usage Cost + Credits",
    definition:
      "Total cost includes usage of Azure and credits. It excludes: charges not specific to subscriptions (e.g. Support Cost) and internal charges (VPC Charges, PCS Enabler Costs)",
  },
  {
    term: "Total Cost",
    needsExpanding: false,
    formula: "Total Cost = Azure Cost + Azure Support Cost + Other Org. Cost + VPC Charges + PCS Enabler Cost",
    definition:
      "Total cost includes usage of Azure and credits. It excludes: charges not specific to subscriptions (e.g. Support Cost) and internal charges (VPC Charges, PCS Enabler Costs)",
  },
  {
    term: "VPC Charge",
    needsExpanding: false,
    formula: "VPC Charge = 4.5% * (Azure Cost + Azure Support Cost + Other Org. Cost)",
    definition:
      "Additional charge from the VPC (Vodafone Procurement Company) team to cover the effort related to finance.",
  },
  {
    term: "PCS Enabler Cost",
    needsExpanding: false,
    readMore: "https://confluence.sp.vodafone.com/x/kQR4C",
    definition:
      "Additional charge from Public Cloud Services (PCS) to cover the effort related to managing and configuring the cloud estate and for dealing with compliance and security.",
  },
  {
    term: "Subscription",
    needsExpanding: true,
    readMore:
      "https://learn.microsoft.com/en-us/azure/cloud-adoption-framework/ready/landing-zone/design-area/resource-org-subscriptions",
    definition:
      "Subscriptions are the agreements with Microsoft to use cloud services, including Azure. Every resource is associated with a subscription. Subscriptions contain resource groups.",
  },
  {
    term: "Resource Group",
    needsExpanding: true,
    readMore: "https://learn.microsoft.com/en-us/azure/azure-resource-manager/management/manage-resource-groups-portal",
    definition: "A resource group is a container that holds related resources for an Azure solution.",
  },
  {
    term: "Management Group",
    needsExpanding: true,
    readMore:
      "https://learn.microsoft.com/en-us/azure/cloud-adoption-framework/ready/landing-zone/design-area/resource-org-management-groups",
    definition:
      "Azure management groups help you efficiently manage: Access, Policies & Compliance. Each management group contains one or more Azure subscriptions.",
  },
]
  .map((d: Definition): Definition => ({ ...d, definition: convertMarkdownToHTML(d.definition) }))
  .concat(commonDefinitions);

export const ociDefinitions: Definition[] = [
  {
    term: "VPC Charge",
    definition:
      "Additional charge from the VPC (Vodafone Procurement Company) team to cover the effort related to finance.",
  },
  {
    term: "PCS Enabler Cost",
    readMore: "https://confluence.sp.vodafone.com/x/kQR4C",
    definition:
      "Additional charge from Public Cloud Services (PCS) to cover the effort related to managing and configuring the cloud estate and for dealing with compliance and security.",
  },
  {
    term: "IDCS",
    definition: "Oracle Cloud Identity Cloud Services",
    needsExpanding: true,
  },
  {
    term: "Public Cloud Services - CLOUDCM",
    definition: "Cloud Cost Management",
    needsExpanding: true,
  },
  {
    term: "Actual Cost",
    formula: "Actual Cost = Usage Cost - Credits",
    definition:
      "Actual cost, also referred to as OCI cost, is the sum of expenses directly related to the usage of OCI services, accounting for any credits applied.",
  },
]
  .map((d: Definition): Definition => ({ ...d, definition: convertMarkdownToHTML(d.definition) }))
  .concat(commonDefinitions);

export const drccDefinitions: Definition[] = [
  {
    term: "VPC Charge",
    definition:
      "Additional charge from the VPC (Vodafone Procurement Company) team to cover the effort related to finance.",
  },
  {
    term: "PCS Enabler Cost",
    readMore: "https://confluence.sp.vodafone.com/x/kQR4C",
    definition:
      "Additional charge from Public Cloud Services (PCS) to cover the effort related to managing and configuring the cloud estate and for dealing with compliance and security.",
  },
]
  .map((d: Definition): Definition => ({ ...d, definition: convertMarkdownToHTML(d.definition) }))
  .concat(commonDefinitions);
