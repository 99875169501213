export const AWS_ACCOUNT_ATTRS = [
  "BusinessEmail",
  "Organisation",
  "TechnicalEmail",
  "OwnerType",
  "ServiceClass",
  "PcsStatus",
  "Sox",
  "WBS",
  "CostCentre",
  "ManagedBy",
  "BusinessService",
  "BusinessService2",
  "BusinessService3",
  "BusinessService4",
  "BusinessService5",
  "Confidentiality",
  "Environment",
  "SecurityAssessmentId",
  "ServiceLevel",
];
export const AWS_ACC_REGEX = new RegExp("^/cloud-providers/aws/accounts/[0-9]{12}$");
export const ARN_REGEX =
  /^arn:(?<partition>[^:]*):(?<service>[^:]*):(?<region>[^:]*):(?<account_id>[^:]*):((?<resource_type>[^:]*)[:/])?(?<resource_id>[^:]*)$/i;
export const DEFAULT_REGION = "eu-west-1";
export const VALID_REGIONS = ["eu-west-1", "eu-west-2", "eu-central-1"];
