<div class="container">
  <div class="row footer">
    <div class="col-4">
      <p class="text-white">
        Copyright 2019 - {{ now }}
        <a class="text-white" target="_blank" href="https://confluence.sp.vodafone.com/x/kQR4C">vodafone.com</a>
      </p>
    </div>
    <div class="col-2">
      <p class="text-white float-right">
        <a href="#" (click)="showPrivacyNotice($event)" class="clickable text-white">Privacy Policy</a>
      </p>
    </div>
    <div class="col-2">
      <p class="text-white float-right">
        <a href="#" (click)="showLegalNotice($event)" class="clickable text-white">Legal</a>
      </p>
    </div>
    <div class="col-2">
      <p class="text-white float-right">
        <a target="_blank" href="/3rdpartylicenses.txt" class="clickable text-white">Licences</a>
      </p>
    </div>
    <div class="col-2">
      <p class="text-white float-right">
        <a routerLink="/changelog" class="clickable text-white">1.32.0 - 2024-08-21</a>
      </p>
    </div>
  </div>
</div>
