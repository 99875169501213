export const GCP_PROJECT_DUPLICATE_LABELS = {
  budget_owner: "BudgetOwner",
  technical_owner: "TechnicalOwner",
  project_owner: "ProjectOwner",
};
export const GCP_PROJECT_ATTRS = [
  "ProjectId",
  "ProjectNumber",
  "CreationDate",
  "FolderName",
  "BudgetOwner",
  "TechnicalOwner",
  "ParentId",
  "ProjectName",
  "ProjectOwner",
  "UpdateDate",
  "Organisation",
];
export const GCP_PROJECT_LABELS = [
  "budget_owner",
  "po_number_gcp",
  "managed_by",
  "confidentiality",
  "sec_assessment",
  "sox",
  "custom",
  "service_level",
  "wbs",
  "business_service",
  "business_service2",
  "business_service3",
  "business_service4",
  "business_service5",
  "pcs_status",
  "service_class",
  "technical_owner",
  "vams_id",
  "environment",
  "bu",
  "project_owner",
  "local_market",
  "enable_sandbox_cleaner",
  "programme",
  "owner_type",
  "cost_centre",
];
export const GCP_PROJ_REGEX = new RegExp("^/cloud-providers/gcp/projects/[^/]+$");
