import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../shared/services/authentication.service";
import Swal from "sweetalert2";
import { CommonModule } from "@angular/common";
import { TenantService } from "../../shared/services/tenant.service";
import { RouterLinkWithHref } from "@angular/router";

@Component({
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref],
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  public now: number;

  constructor(public auth: AuthenticationService, private tenant: TenantService) {}

  ngOnInit(): void {
    this.now = new Date().getFullYear();
  }

  public showLegalNotice(event: MouseEvent): void {
    event.preventDefault();
    Swal.fire({
      title: "Legal Notice",
      confirmButtonColor: this.tenant.colors.primary,
      html: `
          <p><strong>Notice to All Users (Authorized or Unauthorized).</strong></p>
          <br>
          <p class="text-left">
            This computer system is for authorized use only.
          </p>
          <br>
          <p class="text-left">
            By using the PCS Portal you acknowledge that you familiarised yourself with our Privacy Policy.
          </p>
          <p class="text-left">
            Unauthorized or improper use of this system may result in administrative disciplinary action, civil and criminal penalties.
          </p>
          <p class="text-left">
            By continuing to use this system you indicate your awareness of and consent to these terms and conditions of use.
          </p>`,
    });
  }

  showPrivacyNotice(event: MouseEvent) {
    event.preventDefault();
    Swal.fire({
      title: "Privacy Policy",
      confirmButtonColor: this.tenant.colors.primary,
      width: 1200,
      html: `

      <p class="text-left">
        We are committed to protecting your privacy and ensuring the security of your personal information.
        This Privacy Notice outlines the types of personal data we collect, why we collect it, how we use it, and the choices you have regarding your information.
        Please read this notice carefully to understand our practices and how we handle your personal data.
      </p>

      <h2 class="text-left">1) Information We Collect</h2>

      <p class="text-left">
        We may collect the following types of personal information for the purposes described in Section 2:
      </p>

      <ul class="text-left">
        <li> * Email address</li>
        <li> * Usage data about how you interact with the website and underlying API</li>
        <li> * Any other information you voluntarily provide to us either via our API, the PCS Portal or the Service Catalogue or any other means</li>
        <li> * VCloudAI queries and activity including the submitted queries and the associated email address</li>
      </ul>

    <h2 class="text-left">2) How We Use Your Information</h2>

    <p class="text-left">
        We collect and use your personal information for the following purposes:
    </p>

    <ul class="text-left">
      <li>* <strong>Providing and personalizing our products or services</strong>: We use your information to deliver the products or services you request and to customize your experience with us.</li>
      <li>* <strong>Processing of orders and requests</strong>: We utilize your information to process requests and fulfill orders, and provide related customer support.</li>
      <li>* <strong>Communicating with you</strong>: We may contact you regarding your accounts, requests, orders, or to respond to your inquiries or requests.</li>
      <li>* <strong>Analyzing and improving our website and services</strong>: We analyze usage patterns, preferences, and feedback to enhance our website functionality, optimize user experience, and improve our products and services.</li>
      <li>* <strong>Sending communications</strong>: We may send you updates, or communications about our products, services, and events.</li>
      <li>* <strong>Legal compliance and protection</strong>: We may process your information to comply with applicable laws, regulations, legal obligations, or protect our rights, interests, or property.</li>
    </ul>

    <h2 class="text-left">3) Data Sharing</h2>

    <p class="text-left">
        We may share your personal information with third parties in the following circumstances:
    </p>

    <ul class="text-left">
      <li>* <strong>Service providers</strong>: We may share data with trusted service providers who assist us in delivering our services (e.g. AWS). These providers are contractually obligated to maintain the confidentiality and security of your information.</li>
      <li>* <strong>Legal compliance</strong>: We may disclose information if required by law or to protect our legal rights, comply with a judicial proceeding, court order, or legal process.</li>
      <li>* <strong>Business transfers</strong>: If our company undergoes a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction. We will notify you via prominent notice on our website or through other communication channels.</li>
      <li>* <strong>With your consent</strong>: We may share your information with third parties if you have provided your consent for such disclosure.</li>
    </ul>

    <h2 class="text-left">4) Data Security</h2>

    <p class="text-left">
      We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or alteration.
      However, please note that no method of transmission over the internet or electronic storage is 100% secure.
      We cannot guarantee absolute security, but we strive to protect your information to the best of our abilities.
    </p>

    <h2 class="text-left">5) Your Choices</h2>

    <p class="text-left">
        You have the right to access, update, or delete your personal information.
        You can do this by contacting us or updating the cloud metadata in the PCS Portal.
        You may choose not to provide certain information, but this may limit your ability to use some features of our services.
        You can opt-out of certain data collection and tracking technologies, such as cookies, by adjusting your browser settings.
    </p>

    <h2 class="text-left">6) Children's Privacy</h2>

    <p class="text-left">
      Our services are not intended for children and we do not knowingly collect personal information from children.
      If we discover that we have inadvertently collected information from a child, we will promptly delete it.
    </p>

    <h2 class="text-left">7) Termination of Personal Data Processing</h2>

    <p class="text-left">
      You have the right to terminate the processing of your personal data in certain circumstances. This includes:
    </p>

    <ul class="text-left">
      <li>* <strong>Withdrawing consent</strong>: If you previously provided your consent for us to process your personal data, you have the right to withdraw that consent at any time. You can do this by contacting us. Please note that withdrawing consent does not affect the lawfulness of processing based on consent before its withdrawal.</li>
      <li>* <strong>Exercising your rights</strong>: You have the right to exercise your rights regarding your personal data, including the right to access, update, or delete your information. To do so, please contact us or update the cloud metadata in the PCS Portal</li>
    </ul>

    <p class="text-left">
      <strong>NOTE</strong>: Your role might require the usage of our services as it is a Vodafone Group Policy that cloud consumption is done with the assistance of and via PCS (Public Cloud Services).
    </p>

    <h2 class="text-left">8) Changes to this Privacy Notice</h2>

    <p class="text-left">
      We may update this Privacy Notice from time to time to reflect changes in our practices or legal obligations.
      We will notify you of any material changes through prominent notices on our website or by other means.
      Please review this notice periodically for updates.
    </p>

    <p class="text-left">
      By using our services or providing us with your personal information, you acknowledge that you have read and understood this Privacy Notice, including the purposes for which we collect and use your data, and the options available to you to terminate the processing of your personal data as described herein.
    </p>
`,
    });
  }
}
