// css class for the snackbar
import { ArrowPosition, Tutorial } from "../models/tutorial";

const CLOUD_PROVIDERS = ["AWS", "GCP", "Azure", "OCI", "DRCC", "EVO"];

const RESOURCE_NAMES = {
  aws: "accounts",
  gcp: "projects",
  azure: "subscriptions",
  oci: "compartments",
  drcc: "compartments",
  evo: "projects",
};

const regexApiExplorer = new RegExp("^.*/apis/[^/]+$");

const regexDashboard = new RegExp("^.*/dashboard/[^/]+$");

export const SNACKBAR_CLASS = "light-snackbar";

// each highlighted element on the screen is given this new CSS class
// the css class is defined in the global css file
export const CLASS_NAME = "tutorial-element";

export const DURATION_PER_CHAR = 55;

export const TUTORIALS: Tutorial[] = [
  ...["groups", "users", "resources", "permissions"].map((r) => ({
    id: `RBAC ${r} tab`,
    alternativeTutorials: ["groups", "users", "resources", "permissions"]
      .filter((r2) => r !== r2)
      .map((r2) => `RBAC ${r2} entry`),
    url: (url: string) => url.endsWith(`/tenancies/${r}`),
    steps: [
      {
        text: `This is the ${r} RBAC page.`,
      },
      {
        text: `In this dropdown you can search for and select a tenancy`,
        selector: "#tenancy-selector",
        position: "bottom" as ArrowPosition,
      },
      {
        text: `Change between sections to manage different aspects of your tenancies.`,
        selector: "tabset > ul > li > a",
        position: "bottom" as ArrowPosition,
      },
    ],
  })),
  {
    id: "RBAC groups tab (selected)",
    url: (url: string) =>
      url.includes("/tenancies/groups?tenancy=") && ((url.split("?")[1]?.split("=")[1] ?? "")?.length ?? 0) > 1,
    steps: [
      {
        text: `This is the groups RBAC page for your selected tenancy.`,
      },
      {
        text: `Here you can create a new group`,
        selector: "[formcontrolname='newGroup']",
      },
      {
        text: `Click here to confirm`,
        selector: "button[tooltip*='Create']",
      },
    ],
  },
  ...CLOUD_PROVIDERS.map((csp) => ({
    id: `Cloud Inventory ${csp}`,
    url: (url: string) => regexDashboard.test(url),
    alternativeTutorials: CLOUD_PROVIDERS.filter((p) => p !== csp).map((p) => `Cloud Inventory ${p}`),
    steps: [
      {
        text: `This is the cloud inventory for your ${csp} ${RESOURCE_NAMES[csp.toLowerCase()]}`,
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: `You can use it to see all the details of your ${
          RESOURCE_NAMES[csp.toLowerCase()]
        } as well as make any changes to them.`,
      },
      {
        text: "Switch between different cloud providers here",
        selector: ".nav.nav-tabs.pcs-nav-tabs",
      },
      {
        text: `Here you can see some key statistics about your ${RESOURCE_NAMES[csp.toLowerCase()]}.`,
        selector: ".api-controls.card-header.text-center",
      },
      {
        text: `Here are the details of your ${RESOURCE_NAMES[csp.toLowerCase()]}`,
        selector: "table.table.box-shadow",
      },
      {
        text: `Here is a summary of your spend in the ${
          RESOURCE_NAMES[csp.toLowerCase()]
        }. For more details please VCloudSmart.`,
        textFilter: "$",
        selector: "td",
      },
      {
        text: `Here is a summary of your spend in the ${
          RESOURCE_NAMES[csp.toLowerCase()]
        }. For more details please VCloudSmart.`,
        textFilter: "£",
        selector: "td",
      },
      {
        text: `Here is a summary of your spend in the ${
          RESOURCE_NAMES[csp.toLowerCase()]
        }. For more details please VCloudSmart.`,
        textFilter: "€",
        selector: "td",
      },
      {
        text: `You can also search for your ${
          RESOURCE_NAMES[csp.toLowerCase()]
        } here. Type your query in the component above the table and hit enter.`,
        selector: ".text-input",
      },
      {
        text: `Switch between pages here`,
        selector: ".pagination",
      },
      {
        text: "Copy data from the cells by clicking on the copy icon.",
        selector: "app-vficon[iconname=copy]",
      },
      {
        selector: "#dropdownMenuButton",
        text: "You can also export your data by clicking on this button.",
      },
      {
        text: "As there are a lot of details on in the table, you can hide or show columns by clicking on the panel above the table.",
        selector: "#column-select-accordion",
      },
      {
        text: `Before updating, make sure you've selected the ${
          RESOURCE_NAMES[csp.toLowerCase()]
        } by clicking on the checkbox(es).`,
        selector: "input[type=checkbox]",
        position: "right" as ArrowPosition,
      },
      {
        text: `Once you do that, a button will appear here. Click on it to update.`,
        selector: "p.description-text-font-size.text-center",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  })),
  {
    id: "API Explorer",
    url: (url: string) => regexApiExplorer.test(url),
    alternativeTutorials: [],
    steps: [
      {
        text: "This is the API Explorer",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "You can use it to test the endpoints. Everything is pre-authenticated and ready to use.",
      },
      {
        text: "Here are the various endpoints that are available",
        selector: ".opblock-tag-section",
      },
      {
        text: "When testing your integration, you might find it useful to copy the (temporary) token from the API explore here. The token is valid for 1h and will allow you to make API calls from anywhere. IMPORTANT: it uses your permissions.",
        selector: ".btn.btn-outline-primary",
      },
      {
        text: "Click here to download the API specification (swagger file)",
        selector: ".btn.btn-primary",
      },
      {
        text: "We maintain multiple APIs. One for every cloud provider as well as the VCloudSmart API and the tenancies API. Click here to switch between them.",
        selector: "select",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  },
  {
    id: "AI",
    url: (url: string) => url.endsWith("/assistant"),
    alternativeTutorials: [],
    steps: [
      {
        text: "This is the VCloudSmart AI Assistant.",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "Here you can ask any questions about your cost consumption, resources and recommended actions.",
      },
      {
        text: "For example: you can ask it to show you charts, fetch details of instances or explain what rightsizing is.",
      },
      {
        text: "The conversation with the AI is a series of responses (bubbles).",
        selector: "ol > li.custom-card",
      },
      {
        text: "In the top left corner you can see who the message was sent by: the AI, you or if it's data from the database.",
        selector: "app-vficon[iconname='default']",
      },
      {
        text: "You can copy messages by clicking on the icon in the top right corner of the question/response.",
        selector: "app-vficon[iconname='copy']",
      },
      {
        text: "Here are some questions that you might ask. These change depending on the direction the conversation takes. Click to ask the question.",
        selector: ".suggested-questions",
      },
      {
        text: "Type your question here and hit enter.",
        selector: ".custom-textarea",
      },
      {
        text: "You can customise the behaviour of the AI here. For example you can tell the AI about your role, your goals and how you want it to respond (e.g. concisely or in detail).",
        selector: ".btn.ai-customise",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  },
  {
    id: "Welcome to PCS Portal",
    url: (url: string) => url.endsWith("/dashboard") || url.endsWith("/dashboard#services"),
    alternativeTutorials: [],
    steps: [
      {
        text: "The PCS Portal contains a few key features.",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "Services include tools to manage and modify your cloud state. This includes patching, autoremediation etc.",
        selector: ".nav.nav-tabs.card-container.pcs-nav-tabs > li",
        textFilter: "Services",
      },
      {
        text: "RBAC allows to manage and delegate access. It allows to grant and revoke permissions.",
        selector: ".nav.nav-tabs.card-container.pcs-nav-tabs > li",
        textFilter: "RBAC",
      },
      {
        text: "Change approvals might be used by you if you are a accounts/projects/compartments/subscriptions owner and need to approve a change request raised by someone.",
        selector: ".nav.nav-tabs.card-container.pcs-nav-tabs > li",
        textFilter: "Approval",
      },
      {
        text: "Incident management can be used to raise incidents in case any critical issue arises. Please only raise incidents if something is broken in the Portal.",
        selector: ".nav.nav-tabs.card-container.pcs-nav-tabs > li",
        textFilter: "Incident",
      },
      {
        text: "VCloudSmart is the main feature of the PCS Portal, it allows you to visualise and get your data related to billing, assets and recommendations in all major cloud providers.",
        selector: ".nav.nav-tabs.card-container.pcs-nav-tabs > li",
        textFilter: "CloudSmart",
      },
      {
        text: "The AI assistant can be accessed in a pop-up mode by clicking here. You can ask it any question about the cloud, your assets, cost consumption, recommendation etc.",
        selector: ".popup-ai-btn",
      },
      {
        text: "Some more advanced functionality is accessible in the header upon clicking the user icon. This includes API credentials and modifying the color scheme of the website.",
        selector: ".nav-item.dropleft > .btn.nav-link",
        position: "bottom" as ArrowPosition,
      },
      {
        text: "You can leave feedback for the page you are using here.",
        selector: ".give-feedback-btn",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  },
  {
    id: "Request Access",
    url: (url: string) => url.endsWith("/dashboard#request-access"),
    alternativeTutorials: [],
    steps: [
      {
        text: "Here, you can raise a request to gain access to any of the resources managed within your tenancy. Please bear in mind that this request will require approval from your Line Manager and the Tenancy Owner.",
      },
    ],
  },
  {
    id: "RBAC",
    url: (url: string) => url.endsWith("/dashboard#tenancies"),
    alternativeTutorials: [],
    steps: [
      {
        text: "Here you can manage permissions and access to other parts of the application.",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "In our RBAC system we store permissions in tenancies. Think of them as containers for permissions. These are automatically derived from the data we store about your cloud estate (e.g. from the organisation name).",
      },
      {
        text: "However you can also request a new tenancy if you want to manage it manually yourself!",
        selector: ".card",
        textFilter: "Create New Tenancy",
      },
      {
        text: "Each tenancy contains a set of groups. To create groups and manage memberships click here.",
        selector: ".card",
        textFilter: "Groups",
      },
      {
        text: "If you want to see which users are members of the tenancy click here.",
        selector: ".card",
        textFilter: "Users",
      },
      {
        text: "Tenancies also store resource IDs (managed resources). By storing a resource ID in a tenancy you are declaring that this tenancy owns this resource. It is a prerequisite for granting access to it to people within this tenancy. Click here to add and remove resources from your tenancies.",
        selector: ".card",
        textFilter: "Resources",
      },
      {
        text: "After you've added a resource to your tenancy you can manage Read/Write/Create/Delete permissions here.",
        selector: ".card",
        textFilter: "Permissions",
      },
      {
        text: "If you are a developer, API explorer allows you to automate all of the manual permission management. You can see all the available API endpoints, test them and inspect the JSON schemas.",
        selector: ".card",
        textFilter: "API Explorer",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  },
  {
    id: "Incident Management",
    url: (url: string) => url.endsWith("/dashboard#incident-management"),
    alternativeTutorials: [],
    steps: [
      {
        text: "Here, you can raise incidents in case any critical issue arises. Please only raise incidents if something is broken in the Portal",
      },
      {
        text: "You can fill this form to raise a new incident. Upon submission, a Remedy ticket will be automatically created.",
        selector: ".custom-button",
        textFilter: "Report New Incident",
      },
      {
        text: "Here, you can view all the incidents you have reported.",
        selector: ".custom-button",
        textFilter: "List Reported Incidents",
      },
    ],
  },
  {
    id: "Welcome to VCloudSmart",
    url: (url: string) => url.endsWith("/dashboard#vcloudsmart"),
    steps: [
      {
        text: "Welcome to VCloudSmart!",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "VCloudSmart is an internally developed cloud cost optimisation and management tool that gives complete visibility and control over your cloud spend through a single pane of glass, driving cost savings through our recommendation engine and AI assistant.",
      },
      {
        text: "We support all major cloud providers.",
      },
      {
        text: "Here is AWS!",
        textFilter: "Amazon Web Services",
        selector: ".card.custom",
      },
      {
        text: "Here is Google Cloud!",
        textFilter: "Google Cloud",
        selector: ".card.custom",
      },
      {
        text: "Here is Azure!",
        textFilter: "Azure",
        selector: ".card.custom",
      },
      {
        text: "Here is Oracle Cloud!",
        textFilter: "Oracle Cloud",
        selector: ".card.custom",
      },
      {
        text: "Make sure to give our AI assistant a go!",
        textFilter: "AI Assistant",
        selector: ".btn.custom-button",
      },
      {
        text: "If you cannot see any data on the reports it means you need to request access which you can do here.",
        selector: ".btn.custom-button",
        textFilter: "Access Request",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  },
  ...CLOUD_PROVIDERS.map((csp) => ({
    id: `${csp} Sections`,
    url: (url: string) => url.endsWith(`/dashboard#vcloudsmart/${csp.toLowerCase()}`),
    alternativeTutorials: CLOUD_PROVIDERS.filter((csp2) => csp !== csp2).map((csp2) => `${csp2} Sections`),
    steps: [
      {
        text: "In VCloudSmart, there are a few sections under each cloud provider.",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Cost & Billing",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: "The Cost & Billing section is all about billing and cost consumption.",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Cost & Billing",
      },
      {
        text: "The Budgets section allows you to see your budgets and compare them against your spend.",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Budgets",
      },
      {
        text: "The Assets section is where you can see all your resources such as instances, disks etc.",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Asset",
      },
      {
        text: "In Recommendations you can see recommended actions and insights such as suggestions to delete unused resources",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Recommendation",
      },
      {
        text: "In the SAMS/VAMS section you can see financial reports for finance teams that focus on internal and external charges",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "VAMS",
      },
      {
        text: "In DataHub reports you can see how much we've been spending on storage of data used for data analysis, machine learning and AI",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "DataHub",
      },
      {
        text: "In Cloud Recommendations you can see recommended actions and insights such as suggestions to delete unused resources",
        selector: ".bg-light.container.csp-section.rounded.collapse.in.show",
        textFilter: "Cloud Recommendations",
      },
      {
        text: "We define the commonly used terms here. You can click to expand and see more terms and more details including formulas. These terms will also be visible in a side bar on the right of every report.",
        selector: ".terms-and-definitions",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  })),
  ...CLOUD_PROVIDERS.map((csp) => ({
    id: `${csp} Services`,
    url: (url: string) => url.endsWith(`/dashboard#services/${csp.toLowerCase()}`),
    alternativeTutorials: CLOUD_PROVIDERS.filter((csp2) => csp !== csp2).map((csp2) => `${csp2} Services`),
    steps: [
      {
        text: "The PCS Portal offers a number of services related to cloud computing.",
      },
      {
        text: "By the way, you can click here to stop this tutorial if you already know all of this.",
        selector: ".reset-tutorials-btn",
      },
      {
        text: `Use Cloud Inventory to see all your ${csp.toLowerCase()} ${RESOURCE_NAMES[csp.toLowerCase()]}`,
        textFilter: "Cloud Inventory",
        selector: ".custom-button",
      },
      {
        text: "Use the scheduler to shut down your compute instances and save money",
        textFilter: "Scheduler",
        selector: ".custom-button",
      },
      {
        text: "Automatic compliance: opt in into auto-remediation to remediate non-compliances as soon as they arise.",
        textFilter: "Remediation",
        selector: ".custom-button",
      },
      {
        text: "See all the hardened images we offer",
        textFilter: "Hard",
        selector: ".custom-button",
      },
      {
        text: `Generate and download SOX compliance reports for your ${RESOURCE_NAMES[csp.toLowerCase()]}.`,
        textFilter: "SOX",
        selector: ".custom-button",
      },
      {
        text: "Patch management will let you automatically install critical security updates without the need for manual intervention across all your virtual machines.",
        textFilter: "Patch Management",
        selector: ".custom-button",
      },
      {
        text: `If you want to see how many ${RESOURCE_NAMES[csp.toLowerCase()]} you have or how many ${
          RESOURCE_NAMES[csp.toLowerCase()]
        } you have across various environments you can do that here.`,
        textFilter: "Statistics",
        selector: ".custom-button",
      },
      {
        text: "If you are a developer who wishes to leverage our API and build something on top of it, you can use the API explorer to test the endpoints and to see the schemas.",
        textFilter: "API Explorer",
        selector: ".custom-button",
      },
      {
        text: "If you ever want to replay a tutorial you can click here. Enjoy!",
        selector: ".reset-tutorials-btn",
      },
    ],
  })),
];
