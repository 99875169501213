import { Injectable } from "@angular/core";
import {
  CloudProvider,
  FilterableL1,
  FilterableL2,
  GroupByApiResult,
  GroupByPayload,
  JoinType,
  ProjectionOperation,
  QueryFilterSimple,
} from "../models/vcloud-api";
import { LookerStudioService } from "./looker-studio.service";
import { LoggerService } from "./logger.service";
import { VCloudSmartApiService } from "./v-cloud-smart-api.service";
import { Clipboard } from "@angular/cdk/clipboard";
import {
  ChatbotAnswer,
  Creativity,
  Entity,
  LLModel,
  LLMQueryType,
  LLMResponse,
  ModelConfig,
  QueryDomain,
  QueryTypeAnalysis,
  Response,
  ResponseType,
  SelectedTab,
  SuggestedURL,
  VcsApiResponse,
} from "../models/llm";
import { AuthenticationService } from "./authentication.service";
import { Csp } from "../models/vcloudsmart";
import {
  AGENTS,
  AI_NAME,
  CLOUD_PROVIDERS,
  CONFIG_ATTRS_DESCRIPTION,
  CREATIVITY_VALUES,
  DEFAULT_QUESTIONS,
  groupByFieldRegex,
  MODEL_CHEAP,
  MODEL_CHEAP_V1,
  MODEL_CHEAP_V2,
  MODEL_CONFIG,
  MODEL_EXPENSIVE_V1,
  MODEL_EXPENSIVE_V2,
  N_DATA_SENT,
  N_QUESTIONS,
  QUOTA_HIST_ITEM_LEN,
  QUOTA_N_HIST_ITEMS,
  QUOTA_N_HIST_ITEMS_CURRENT_PAGE,
  RACE_N_CLASSIFICATION,
  REGEX_DATE,
  SECOND,
} from "../constant/llm";
import { EventsService } from "./events.service";
import { environment } from "../../../environments/environment";
import { MAX_API_RESPONSE_LIMIT, REGEX_VCS_PAGE_URL, ROWS_PER_PAGE } from "../constant/vcloud-api";
import { ChartOptions } from "../models/google-chart";
import { CHART_OPTIONS } from "../constant/charts";
import { TenantService } from "./tenant.service";
import { TableCell, TableRow } from "../models/common-table";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, map, retry, tap, timeout } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PcsApiService } from "./pcs-api.service";
import { CurrencyId } from "../models/currency";
import { convertMarkdownToHTML, markdownConverter } from "../constant/definitions";
import { AWS_ACC_REGEX } from "../constant/aws-api";
import { AZURE_SUBS_REGEX } from "../constant/azure-api";
import { GCP_PROJ_REGEX } from "../constant/gcp-api";
import { OCI_REGEX } from "../constant/oci-api";
import { DRCC_REGEX } from "../constant/drcc-api";
import { UserPermissions } from "../models/tenancies";
import { transformLegacyPageName, transformLegacySectionName } from "../constant/validation";

const REGEX_PERCENT = /%25/g;
const REGEX_LPAREN = /%28/g;
const REGEX_RPAREN = /%29/g;
const REGEX_SPACE = /%20/g;

@Injectable({
  providedIn: "root",
})
export class LLMService {
  private readonly markdownConverter = markdownConverter;

  public pieChartOptions: ChartOptions = {
    ...CHART_OPTIONS.PieChart,
    // colors: [this.tenant.colors.primary],
    vAxis: {
      format: "short",
    },
    legend: { position: "bottom", textStyle: { color: "black", fontSize: 16 } },
    // hAxis: {
    //   slantedText: true,
    // },
    height: 420,
    pieSliceText: "value",
    chartArea: {
      top: 0,
      right: 20,
      left: 20,
      bottom: 60,
    },
  };

  public columnChartOptions: ChartOptions = {
    ...CHART_OPTIONS.ColumnChart,
    colors: [this.tenant.colors.primary],
    vAxis: {
      format: "short",
    },
    hAxis: {
      slantedText: true,
    },
    height: 300,
    chartArea: {
      top: 10,
      right: 20,
      left: 50,
      bottom: 70,
    },
  };

  constructor(
    private tenant: TenantService,
    private pcsApi: PcsApiService,
    private http: HttpClient,
    private looker: LookerStudioService,
    private events: EventsService,
    private authN: AuthenticationService,
    private log: LoggerService,
    private vcs: VCloudSmartApiService,
    private clipboard: Clipboard
  ) {}

  public describeUserPerms(userPerms: UserPermissions | undefined | null): string[] {
    if (userPerms === undefined || userPerms === null) {
      return [];
    }
    const messages = [];

    const tenancies = userPerms?.user?.tenancies ?? [];

    if (tenancies.length > 0) {
      messages.push(`The user has permissions in the following ${tenancies.length} tenancies: ${tenancies.join(", ")}`);
    }

    const permsSafe = userPerms?.user?.permissions?.Read ?? [];
    const permSet = new Set(permsSafe);

    for (const csp of CLOUD_PROVIDERS) {
      let collection = "";
      let r: RegExp;
      if (csp === "azure") {
        collection = "subscriptions";
        r = AZURE_SUBS_REGEX;
      } else if (csp === "aws") {
        collection = "accounts";
        r = AWS_ACC_REGEX;
      } else if (csp === "gcp") {
        collection = "projects";
        r = GCP_PROJ_REGEX;
      } else if (csp === "oci") {
        collection = "compartments";
        r = OCI_REGEX;
      } else if (csp === "drcc") {
        collection = "compartments";
        r = DRCC_REGEX;
      }
      if (permSet.has(`/cloud-providers/${csp}/${collection}`)) {
        messages.push(`The user has Read permissions to all ${collection} in ${csp}`);
      } else {
        const n = permsSafe.filter((resource) => r.test(resource)).length;
        if (n > 0) {
          messages.push(`The user has Read permissions to ${n} ${collection} in ${csp}`);
        }
      }
    }

    return [`VCloudSmart UI> ${messages.join(". ")}`];
  }

  public stripHTML(html: string): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return (tempDiv.textContent || tempDiv.innerText || "").trim();
  }

  private fmtFilters(body: FilterableL1 | FilterableL2): string[] {
    let allFilters: string[] = [];
    try {
      for (const f of body?.Filters ?? []) {
        if ((f as FilterableL2)?.Filters !== undefined) {
          allFilters = allFilters.concat(this.fmtFilters(f as unknown as FilterableL2));
          continue;
        }

        if (
          (f as QueryFilterSimple)?.Attribute &&
          (f as QueryFilterSimple)?.Type !== "DATE" &&
          (f as QueryFilterSimple)?.Type !== "TIMESTAMP" &&
          (f as QueryFilterSimple)?.Value !== null
        ) {
          let v = Array.isArray((f as QueryFilterSimple).Value)
            ? "[..]"
            : ((f as QueryFilterSimple)?.Value ?? "empty value").toString();
          if (v.length > 10) {
            v = v.slice(0, 10) + "..";
          }

          let k = this.prettifyAttribute((f as QueryFilterSimple).Attribute);
          if (k.length > 10) {
            k = k.slice(0, 10) + "..";
          }

          allFilters.push(`${k} ${(f as QueryFilterSimple).Operator} ${v}`);
        }
      }
    } catch (e) {
      this.log.error(e);
    }
    return allFilters;
  }

  public focusLastMessage(): void {
    const nodes = [];
    document.querySelectorAll("ol > li.custom-card.llm-response").forEach((n) => {
      nodes.push(n);
    });
    for (let i = nodes.length - 1; i >= 0; i--) {
      if ((nodes[i] as HTMLElement).offsetParent) {
        (nodes[i] as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" });
        return;
      }
    }
  }

  public getAllowedTabs(r: VcsApiResponse, sidebar: boolean): SelectedTab[] {
    const allowedTabs: Set<SelectedTab> = new Set(["Table", "Payload"]);
    if (this.canDisplayColumnChart(r, sidebar)) {
      allowedTabs.add("Column Chart");
    }
    if (this.canDisplayPieChart(r, sidebar)) {
      allowedTabs.add("Pie Chart");
    }
    return [...allowedTabs].sort() as SelectedTab[];
  }

  private getSelectedTab(r: VcsApiResponse, sidebar: boolean): SelectedTab {
    if (sidebar) {
      return "Table";
    }
    const maybeVal = localStorage.getItem("llmPreferredTab");
    if (maybeVal === "Table") {
      return maybeVal;
    }
    if (maybeVal === "Pie Chart" && !(r?.chartRows ?? []).some((row) => (row ?? []).some((v) => (v?.v ?? 0) < 0))) {
      return "Pie Chart";
    }
    return "Column Chart";
  }

  public fmtApiResponse(
    requestBody: GroupByPayload,
    output: GroupByApiResult,
    domains: QueryDomain[],
    sidebar: boolean,
    messageId?: string
  ): VcsApiResponse {
    const chartColumns: string[] = (requestBody?.Projection ?? []).map(({ Attribute }) => {
      return this.prettifyAttribute(Attribute ?? "Value");
    });

    const r: VcsApiResponse = {
      who: "VCloudSmart API",
      type: "api_data",
      chartColumns,
      messageId,
      dateRange: this.fmtDateRange(requestBody),
      tabs: undefined,
      limitFmt: this.fmtLimit(requestBody),
      filterTags: this.fmtFilters((requestBody ?? {}) as FilterableL2),
      selectedTab: undefined,
      payload: requestBody,
      columnChartOptions: {
        ...(this.columnChartOptions ?? {}),
        vAxis: {
          ...(this.columnChartOptions?.vAxis ?? {}),
          title: this.getVAxisTitle(requestBody),
        },
        hAxis: {
          ...(this.columnChartOptions?.hAxis ?? {}),
          title: this.getHAxisTitle(requestBody),
        },
      } as ChartOptions,
      pieChartOptions: {
        ...(this.pieChartOptions ?? {}),
        title: this.getVAxisTitle(requestBody),
        vAxis: {
          ...(this.pieChartOptions?.vAxis ?? {}),
          title: this.getVAxisTitle(requestBody),
        },
        hAxis: {
          ...(this.pieChartOptions?.hAxis ?? {}),
          title: this.getHAxisTitle(requestBody),
        },
      } as ChartOptions,
      chartRows: this.getChartRows(requestBody, output, domains),
      tableColumns: chartColumns.map((c) => ({
        title: c,
        label: c,
        sortable: true,
      })),
      tableRows: this.fmtTableRows(output, requestBody, domains),
    };
    r.selectedTab = this.getSelectedTab(r, sidebar);
    r.tabs = this.getAllowedTabs(r, sidebar);
    return r;
  }

  private preProcessCandidatePayload(body: GroupByPayload, domains: QueryDomain[]): GroupByPayload {
    if (!body) {
      return body;
    }

    // drcc needs the INNER join to align numbers
    if (
      body.CloudProvider === "drcc" &&
      (domains ?? []).includes("billing") &&
      body.Join &&
      body?.Join?.Type &&
      body?.Join?.Type !== ("INNER" as JoinType)
    ) {
      body.Join.Type = "INNER" as JoinType;
    }

    // fix 0 based indexing
    if ((body?.GroupBy ?? []).concat(body?.OrderBy ?? []).includes("Value0")) {
      body.GroupBy = body.GroupBy.map((f) => `Value${parseInt(f.replace("Value", "")) + 1}`);
      body.OrderBy = body.OrderBy.map((f) => `Value${parseInt(f.replace("Value", "")) + 1}`);
    }

    // remove duplicate rows
    if ((body?.GroupBy?.length ?? 0) === 0 && !body?.Projection.some((f) => !!f.Operation)) {
      body.Distinct = true;
    }

    // the AI is very persistent with adding Table_1.CaptureTime to all Asset related queries so this is required
    if (body.CloudProvider === "aws" && body.DataStore === "Assets" && (body?.Filters?.length ?? 0) > 0) {
      body.Filters = body.Filters.filter((f) => (f as QueryFilterSimple).Attribute !== "Table_1.CaptureTime");
    }
    if (body.CloudProvider === "aws" && body.DataStore === "AllRecommendations" && (body?.Filters?.length ?? 0) > 0) {
      body.Filters = body.Filters.filter((f) => (f as QueryFilterSimple).Attribute !== "Table_1.LastRefreshTime");
    }

    // the AI is very persistent with using the current time as lastUpdated so this is required
    if (
      body.CloudProvider === "azure" &&
      (body.DataStore === "RecommendationsV2" || body.DataStore === "RecommendationsV3") &&
      (body?.Filters?.length ?? 0) > 0
    ) {
      (body.Filters ?? [])
        .filter((f: QueryFilterSimple) => {
          return (
            f?.Attribute.includes("lastUpdated") &&
            f?.Operator.startsWith(">") &&
            f?.Value.toString().startsWith(new Date().toISOString().split("T")[0])
          );
        })
        .forEach((f: QueryFilterSimple) => {
          f.Value = this.vcs
            .addDays(new Date(f.Value as string), -28)
            .toISOString()
            .split("T")[0];
        });
    }

    // in case there is a duplication of filters we group them and combine them with OR
    let filtersToProcess = [...(body?.Filters ?? [])].filter(
      (f) => (f as FilterableL2).Filters === undefined
    ) as QueryFilterSimple[];

    let finalFilters: (QueryFilterSimple | FilterableL2)[] = (body.Filters ?? []).filter((f) => {
      return (f as unknown as FilterableL2).Filters !== undefined;
    });

    while (filtersToProcess.length > 0) {
      const f = filtersToProcess[0];
      const similarFilters = filtersToProcess.filter(
        (f2: QueryFilterSimple) => f.Attribute === f2.Attribute && f.Operator === f2.Operator && f.Value !== f2.Value
      );
      if (similarFilters.length > 1) {
        finalFilters = [
          {
            FiltersJoinWith: "OR",
            Filters: [...similarFilters],
          } as FilterableL2 | QueryFilterSimple,
        ].concat(finalFilters);
        filtersToProcess = filtersToProcess.filter((f2) => !similarFilters.includes(f2));
      } else {
        finalFilters = [f as FilterableL2 | QueryFilterSimple].concat(finalFilters);
        filtersToProcess = filtersToProcess.slice(1);
      }
    }

    if (finalFilters.length > 0) {
      body.Filters = finalFilters;
    }

    // fix invalid SortOrder
    if (body.SortOrder && body.SortOrder !== "ASC" && body.SortOrder !== "DESC") {
      body.SortOrder = "DESC";
    }

    // This is required for all queries to prevent issues with name clashes
    if ((body.GroupBy ?? []).length > 0) {
      if (body.SelectGroups !== false) {
        body.SelectGroups = false;
      }
    }

    // Fix Limit if out of bounds
    if (body?.Limit === undefined || body?.Limit === null || body?.Limit < 0) {
      body.Limit = ROWS_PER_PAGE;
    } else if (body?.Limit > MAX_API_RESPONSE_LIMIT) {
      body.Limit = MAX_API_RESPONSE_LIMIT;
    }

    // try to fix invalid OrderBy, choose the first aggregate operation you can find
    try {
      for (let i = 0; i < (body?.OrderBy?.length ?? 0); i++) {
        if (body.OrderBy[i].startsWith("Value")) {
          const idx = parseInt(body.OrderBy[i].replace("Value", "")) - 1;
          if (body.Projection[idx] === undefined) {
            body.OrderBy = [
              `Value${
                Math.max(
                  0,
                  body.Projection.findIndex((f) => this.isAggregate(f.Operation))
                ) + 1
              }`,
            ];
          }
        }
      }
    } catch (e) {
      this.log.error(e);
    }

    // cannot combine group by with any_value
    for (const g of body?.GroupBy ?? []) {
      if (g.startsWith("Value")) {
        const idx = parseInt(g.replace("Value", "")) - 1;
        if (idx >= 0 && idx < (body?.Projection?.length ?? 0)) {
          if (body.Projection[idx].Operation === "ANY_VALUE") {
            delete body.Projection[idx].Operation;
          }
        }
      }
    }

    // when using group by all attributes must be grouped on OR they must have an operation
    // we can either add an operation to them
    // or we can add them to group by (usually safer assumption), this is what we do here
    if (((body?.GroupBy ?? [])?.length ?? 0) > 0) {
      for (let aIdx = 0; aIdx < ((body.Projection ?? [])?.length ?? 0); aIdx++) {
        const attribute = body.Projection[aIdx];
        if (!attribute.Operation && !body.GroupBy.some((g) => g === `Value${aIdx + 1}`)) {
          body.GroupBy.push(`Value${aIdx + 1}`);
        }
      }
    }

    // fix invalid <> != IS IS NOT operators for true/false/NULL
    for (const f of body?.Filters ?? []) {
      if (
        ((f as QueryFilterSimple).Value === true ||
          (f as QueryFilterSimple).Value === false ||
          (f as QueryFilterSimple).Value === null) &&
        (f as QueryFilterSimple).Operator === "="
      ) {
        (f as QueryFilterSimple).Operator = "IS";
      } else if (
        ((f as QueryFilterSimple).Value === true ||
          (f as QueryFilterSimple).Value === false ||
          (f as QueryFilterSimple).Value === null) &&
        ((f as QueryFilterSimple).Operator === "!=" || (f as QueryFilterSimple).Operator === "<>")
      ) {
        (f as QueryFilterSimple).Operator = "IS NOT";
      }
    }

    // fix sort when displaying trend over time
    for (let i = 0; i < (body?.Projection?.length ?? 0); i++) {
      if (body.Projection[i].Operation === "DATE_TRUNC" && (body?.GroupBy ?? []).some((g) => g === `Value${i + 1}`)) {
        body.OrderBy = [`Value${i + 1}`];
        body.SortOrder = "ASC";
        break;
      }
    }

    // add default value to normalise similar candidates
    if (!body.SortOrder) {
      body.SortOrder = "DESC";
    }

    // inject sort and limit and order by if needed
    const aggIdx = body.Projection.findIndex((a) => a.Operation && this.isAggregate(a?.Operation));
    if (!body.OrderBy && aggIdx >= 0) {
      body.OrderBy = [`Value${aggIdx + 1}`];
    }

    return body;
  }

  public getSuggestedReports(
    responses: Response[],
    vcsConfig: Csp[] | undefined,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    converationId: string,
    userPerms: UserPermissions
  ): Observable<SuggestedURL[]> {
    const REGEX_HREF = /href="(?<href>\/[^"]+)"/g;
    const reportUrls = new Set<string>();
    const aiAnalysis =
      ([...responses].reverse().find((r) => r.type === "text" && r.who === AI_NAME && r.message) as ChatbotAnswer)
        ?.message ?? "";
    let m: RegExpMatchArray | null;

    do {
      m = REGEX_HREF.exec(aiAnalysis);
      if (m) {
        const url = (m.groups ?? {})["href"];
        if (url.length > 2) {
          reportUrls.add(url);
        }
      }
    } while (m);

    const formattedUrls: SuggestedURL[] = [...reportUrls]
      .map((url) => this.formatURL(url, vcsConfig))
      .filter(
        (r) =>
          r?.Name &&
          r?.URL &&
          r.URL.length > 0 &&
          (location?.href ?? "").replace(location?.origin ?? "", "") !== r?.FullUrl
      );

    if (formattedUrls.length === 0) {
      return this.getSuggestedReportsFromAPI(responses, vcsConfig, cloudProviders, domains, converationId, userPerms);
    } else {
      return of(formattedUrls);
    }
  }

  public handleCasualInteraction(
    responses: Response[],
    query: string,
    model: LLModel,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    conversationId: string,
    userPerms: UserPermissions
  ): Observable<LLMResponse> {
    const creativity: Creativity = "MEDIUM";
    return this.llmQuery(
      query,
      this.formatHistory(
        responses,
        8,
        [AI_NAME, "User", "VCloudSmart API"],
        ["api_data", "text", "generated_api_payload"],
        "casual_interactions",
        userPerms
      ),
      model,
      "casual_interactions",
      {
        ...MODEL_CONFIG,
        top_p: MODEL_CONFIG.top_p[creativity],
        temperature: MODEL_CONFIG.temperature[creativity],
      },
      cloudProviders,
      domains,
      conversationId
    ).pipe(
      retry(2),
      map((s) => ({ ...s, Response: this.convertMarkdownToHTML(s.Response) })),
      catchError((e) => {
        this.log.error(e);
        return of({ Response: "Sorry, I didn't understand that. Can you please rephrase?" });
      })
    );
  }

  public isAggregateCurrency(o: ProjectionOperation): boolean {
    return (
      o === ProjectionOperation.sum ||
      o === ProjectionOperation.stdev_sample ||
      o === ProjectionOperation.stdev_pop ||
      o === ProjectionOperation.max ||
      o === ProjectionOperation.min ||
      o === ProjectionOperation.average
    );
  }

  public parseLLMJsonResponses<T>(response: string, start: string, end: string): T {
    try {
      response = (response || "").replace(new RegExp("\\\\_", "g"), "_").trim();
      let i = 0;
      while (response[i] !== start) {
        i++;
      }
      let j = response.length - 1;
      while (response[j] !== end) {
        j--;
      }
      return JSON.parse(response.slice(i, j + 1));
    } catch (e) {
      this.log.error({ error: e, msg: "failed to parse JSON", json: response, start, end });
    }
  }

  public analyseQuery(
    responses: Response[],
    query: string,
    conversationId: string,
    userPerms: UserPermissions
  ): Observable<QueryTypeAnalysis> {
    const models: LLModel[] = [MODEL_CHEAP_V1, MODEL_CHEAP_V2];
    const history: string[] = this.formatHistory(responses, 4, ["User", AI_NAME], ["text"], "analyse_query", userPerms);
    const candidates: Observable<LLMResponse>[] = Array(RACE_N_CLASSIFICATION)
      .fill(null)
      .map((_, idx: number) => {
        const creativity: Creativity = "LOW";
        return this.llmQuery(
          query,
          history.filter((h) => h !== `User> ${query}`),
          models[idx % models.length],
          "analyse_query",
          {
            ...MODEL_CONFIG,
            top_p: MODEL_CONFIG.top_p[creativity],
            temperature: MODEL_CONFIG.temperature[creativity],
          },
          [],
          [],
          conversationId
        ).pipe(timeout(15 * SECOND));
      });
    return forkJoin(candidates).pipe(
      retry(2),
      map((responses: LLMResponse[]): QueryTypeAnalysis[] => {
        return responses.map(
          (r: LLMResponse): QueryTypeAnalysis => this.parseLLMJsonResponses<QueryTypeAnalysis>(r.Response, "{", "}")
        );
      }),
      map((responses: QueryTypeAnalysis[]): QueryTypeAnalysis => {
        return responses.reduce(
          (a: QueryTypeAnalysis, b: QueryTypeAnalysis): QueryTypeAnalysis => {
            const c = {};
            for (const k in a) {
              c[k] = a[k];
            }
            for (const k in b) {
              c[k] = [...new Set((c[k] ?? []).concat(b[k]))];
            }
            return c;
          },
          { Domains: [], QueryCategories: [], CloudProviders: [] }
        );
      }),
      tap((final: QueryTypeAnalysis) => {
        if (final.QueryCategories.length === 0) {
          final.QueryCategories.push("casual_interactions");
        }
      }),
      catchError((e) => {
        this.log.error(e);
        return of({
          CloudProviders: [],
          QueryCategories: ["casual_interactions"],
          Domains: ["billing", "assets", "recommendations"],
        } as QueryTypeAnalysis);
      })
    );
  }

  public getSuggestedQuestions(
    responses: Response[],
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    conversationId: string,
    userPerms: UserPermissions
  ): Observable<string[]> {
    const creativity: Creativity = "HIGH";
    return this.llmQuery(
      `Generate ${N_QUESTIONS} potential questions relevant to the conversation that the user might want to ask.`,
      this.formatHistory(
        responses,
        5,
        ["VCloudSmart API", "User", AI_NAME],
        ["api_data", "text"],
        "potential_questions",
        userPerms
      ),
      MODEL_CHEAP,
      "potential_questions",
      {
        ...MODEL_CONFIG,
        top_p: MODEL_CONFIG.top_p[creativity],
        temperature: MODEL_CONFIG.temperature[creativity],
      },
      cloudProviders,
      domains,
      conversationId
    ).pipe(
      retry(2),
      map((response: LLMResponse) => this.parseLLMJsonResponses<string[]>(response.Response, "[", "]")),
      map((questions: string[]): string[] => questions.map((q) => this.stripHTML(this.convertMarkdownToHTML(q)))),
      catchError((e) => {
        this.log.error(e);
        return of(DEFAULT_QUESTIONS);
      })
    );
  }

  private getSuggestedReportsFromAPI(
    responses: Response[],
    vcsConfig: Csp[],
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    conversationId: string,
    userPerms: UserPermissions
  ): Observable<SuggestedURL[]> {
    const creativity: Creativity = (cloudProviders ?? []).includes("other" as CloudProvider) ? "EXTREME" : "LOW";
    return this.llmQuery(
      "Which reports (pages) are relevant to this conversation?",
      this.formatHistory(responses, 3, ["User", AI_NAME], ["text"], "suggest_reports", userPerms),
      MODEL_CHEAP,
      "suggest_reports",
      {
        ...MODEL_CONFIG,
        top_p: MODEL_CONFIG.top_p[creativity],
        temperature: MODEL_CONFIG.temperature[creativity],
      },
      cloudProviders,
      domains,
      conversationId
    ).pipe(
      retry(2),
      map((response: LLMResponse): string[] => [
        ...new Set(this.parseLLMJsonResponses<string[]>(response.Response, "[", "]")),
      ]),
      catchError((e) => {
        this.log.error(e);
        return of([] as string[]);
      }),
      map((links: string[]) => {
        return links
          .map((url) => this.formatURL(url, vcsConfig))
          .filter((r) => r?.Name && r?.URL && r.URL.length > 0 && location?.pathname !== r?.URL);
      })
    );
  }

  public getFirstSuccessfulCandidateApiPayload(
    requestBody: { payload: GroupByPayload; messageIds: string[] },
    alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[]
  ): Observable<{
    response: GroupByApiResult;
    requestBody: { payload: GroupByPayload; messageIds: string[] };
    alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[];
  }> {
    return this.vcs.groupBy<any>(requestBody.payload).pipe(
      tap((response) => {
        if (
          ((response?.Results?.length ?? 0) === 0 ||
            (response?.Results?.length === 1 &&
              (response?.Results[0]?.Value ?? []).every(
                (v) => v === 0 || v === "" || v === "0" || v === null || v === undefined
              ))) &&
          alternativeRequestBodies.length > 0
        ) {
          throw new Error("No data");
        }
      }),
      map((response) => {
        return { response, requestBody, alternativeRequestBodies };
      }),
      catchError((e) => {
        this.log.error(e);
        if (alternativeRequestBodies.length > 0) {
          return this.getFirstSuccessfulCandidateApiPayload(
            alternativeRequestBodies[0],
            alternativeRequestBodies.slice(1)
          );
        } else {
          throw e;
        }
      })
    );
  }

  /**
   * This function removes duplicate payloads and produces the proposed payloads in the order of frequency
   */
  public getCandidateApiPayloadsInParallelOrderedByBest(
    responses: Response[],
    query: string,
    model: LLModel,
    nCandidates: number,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    converationId: string,
    userPerms: UserPermissions
  ): Observable<
    {
      payload: GroupByPayload;
      messageIds: string[];
    }[]
  > {
    const models: LLModel[] = [MODEL_CHEAP_V1, model, MODEL_EXPENSIVE_V2, MODEL_CHEAP_V2];
    const tasks: Observable<{ payload?: GroupByPayload; messageId?: string; modelConfig: ModelConfig }>[] = Array(
      nCandidates
    )
      .fill(null)
      .map((_, idx: number) => {
        return this.getCandidateApiPayload(
          responses,
          idx,
          query,
          models[idx % models.length],
          cloudProviders,
          domains,
          converationId,
          userPerms
        );
      });
    return forkJoin(...tasks).pipe(
      map(
        (
          proposals: { payload?: GroupByPayload; messageId?: string; modelConfig: ModelConfig }[]
        ): { payload: GroupByPayload; messageIds: string[] }[] => {
          return this.orderCandidateApiPayloads(proposals);
        }
      )
    );
  }

  public llmQuery(
    prompt: string,
    history: string[] = [],
    model: LLModel,
    queryType: LLMQueryType,
    modelConfig: ModelConfig,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    conversationId: string
  ): Observable<LLMResponse> {
    let headers = new HttpHeaders();

    try {
      headers = this.vcs.getHeaders();
    } catch (e) {
      if (e.message === "Session Expired") {
        this.authN.autoLogout();
      }
      return throwError(e);
    }

    return this.http
      .request<LLMResponse>("POST", `${this.vcs.baseUrl}/queries/llm`, {
        body: {
          Prompt: prompt,
          History: (history?.length ?? 0) === 0 ? undefined : history,
          Model: model,
          ModelConfig: Object.keys(modelConfig ?? {}).length === 0 ? undefined : modelConfig,
          CloudProviders: (cloudProviders?.length ?? 0) === 0 ? undefined : cloudProviders,
          Domains: (domains?.length ?? 0) === 0 ? undefined : domains,
          QueryType: queryType,
        },
        headers,
        observe: "body",
        responseType: "json",
      })
      .pipe(
        timeout(30 * SECOND),
        tap((r) => {
          this.logLLMUsage(
            conversationId,
            r?.MessageId,
            prompt,
            queryType,
            domains,
            cloudProviders,
            r?.Response ?? "",
            parseFloat((r?.Cost ?? "0.0 USD").split(" ")[0])
          );
        }),
        catchError((e) => {
          this.log.error(e);
          let tryAgain = false;
          try {
            tryAgain =
              (model === MODEL_EXPENSIVE_V1 || model === MODEL_EXPENSIVE_V2) &&
              (e.toString().includes("ResourceExhausted") || JSON.stringify(e).includes("ResourceExhausted"));
            this.logLLMUsage(
              conversationId,
              undefined,
              prompt,
              queryType,
              domains,
              cloudProviders,
              `ERROR: ${e.toString()}: ${JSON.stringify(e)}`,
              0
            );
          } catch (e) {}
          if (tryAgain) {
            return this.llmQuery(
              prompt,
              history,
              MODEL_CHEAP,
              queryType,
              modelConfig,
              cloudProviders,
              domains,
              conversationId
            );
          }
          throw e;
        }),
        tap((r: LLMResponse) => {
          if (!environment.production) {
            setTimeout(() => {
              this.vcs.handleQueryCost({
                Metadata: {
                  cost: r?.Cost ?? "0.0 USD",
                  job_id: `llm-${new Date().toISOString()}`,
                },
              });
            }, 2000);
          }
        }),
        map((r: LLMResponse): LLMResponse => {
          for (const agent of AGENTS) {
            const prefix = `${agent}>`;
            if (r.Response.startsWith(prefix)) {
              r.Response = r.Response.slice(prefix.length).trim();
            }
          }
          return r;
        }),
        tap((r) => {
          r.Response = r.Response || "";
        })
      );
  }

  private getCandidateApiPayload(
    responses: Response[],
    idx: number,
    query: string,
    model: LLModel,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[],
    conversationId: string,
    userPerms: UserPermissions
  ): Observable<{ payload?: GroupByPayload; messageId?: string; modelConfig: ModelConfig }> {
    const creativity: Creativity = CREATIVITY_VALUES[idx % CREATIVITY_VALUES.length];
    const modelConfig: ModelConfig = {
      ...MODEL_CONFIG,
      top_p: MODEL_CONFIG.top_p[creativity],
      temperature: MODEL_CONFIG.temperature[creativity],
    };
    return this.llmQuery(
      query,
      this.formatHistory(
        responses,
        10,
        [AI_NAME, "User", "VCloudSmart API"],
        ["api_data", "text", "generated_api_payload"],
        "api_payload",
        userPerms
      ),
      model,
      "api_payload",
      modelConfig,
      cloudProviders,
      domains,
      conversationId
    ).pipe(
      timeout(20 * SECOND),
      map((r: LLMResponse): { payload: GroupByPayload; messageId: string } => {
        return { payload: this.parseLLMJsonResponses<GroupByPayload>(r.Response, "{", "}"), messageId: r.MessageId };
      }),
      map((r: { payload: GroupByPayload; messageId: string }): { payload: GroupByPayload; messageId: string } => {
        return { payload: this.preProcessCandidatePayload(r.payload, domains), ...r };
      }),
      tap((r: { payload: GroupByPayload; messageId: string }) => {
        if (!this.isPayloadValid(r.payload)) {
          throw new Error(`Invalid payload: ${JSON.stringify(r.payload ?? "")}`);
        }
      }),
      catchError((e) => {
        this.log.error(e);
        return of(null);
      }),
      map(
        (
          r: { payload: GroupByPayload; messageId: string } | null
        ): { payload?: GroupByPayload; messageId?: string; modelConfig: ModelConfig } => {
          return {
            payload: r?.payload,
            messageId: r?.messageId,
            modelConfig: { ...modelConfig, model },
          };
        }
      )
    );
  }

  private describeCurrentPage(domains: string[]): string[] {
    const llmComponent = document.querySelector("app-llm");

    const pageUrlPretty = (location?.href ?? "")
      .replace(location?.origin ?? "", "")
      .split("/")
      .map((s) => decodeURIComponent(s).replace(/%28/g, "(").replace(/%29/g, ")"))
      .join("/");

    const currentPageURL: string = `${
      location.pathname.includes("vcloudsmart") ? "VCloudSmart" : "PCS Portal"
    } UI> The current page is \`${pageUrlPretty}\`.`;

    const currentPageInfo: string[] = [];

    if ((document.querySelector("#navbarSupportedContent") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `On the top of the page there is the website header (present on every page) with the following buttons:
        
- About
- FAQ
- Feature Request
- Bug Report
- Service Request

Click on the Vodafone logo next to it to navigate to the [/dashboard#services](Dashboard)`
      );
    }

    if ((document.querySelector("app-footer") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nOn the bottom of this page there is the website footer (present on every page) which contains:
        
- Privacy Policy
- Legal
- Licences
- Releases
 
Click on Releases to see the changelog`
      );
    }

    const headings: string[] = [];
    document.querySelectorAll(".h2.clickable").forEach((h2: HTMLElement) => {
      if (h2?.offsetParent) {
        headings.push(this.normalizeWhitespace(h2.textContent ?? "").trim());
      }
    });

    if (headings.length > 0) {
      currentPageInfo.push(
        `\n\nThis page (report) contains ${headings.length} clickable section headings:\n\n${headings
          .filter(Boolean)
          .map((s) => `- ${s}`)
          .join("\n")}`
      );
    }

    // @ts-ignore
    if (document.querySelector("app-cloud-score-card")?.offsetParent) {
      currentPageInfo.push(
        `\n\nThis page contains a Cloud Maturity Score which you can expand to see sub-categories such as Cost Optimisation, Data Validity and Cloud Native`
      );
    }

    const accordionSections: string[] = [];

    document.querySelectorAll("accordion accordion-group .panel.card").forEach((section: HTMLElement) => {
      try {
        const sectionInfo: string[] = [];
        if (section?.offsetParent) {
          const sectionTitle = this.normalizeWhitespace(
            this.stripOtherInfo(section.querySelector("[accordion-heading]")?.textContent ?? "").trim()
          );
          const isExpanded = section.querySelector(".accordion-toggle")?.getAttribute("aria-expanded") === "true";
          sectionInfo.push(`\`${sectionTitle}\` accordion group (section) ${isExpanded ? "is" : "is not"} expanded`);
          if (isExpanded) {
            try {
              const insideSection = section.querySelector(".panel-body.card-body") as HTMLElement;
              if (insideSection?.offsetParent) {
                const cards = [];
                insideSection.querySelectorAll(".card").forEach((c: HTMLElement) => {
                  if (
                    c?.offsetParent &&
                    !this.getElementsParents(c).some((el) => el?.tagName === "APP-INTERACTIVE-CHART-SECTION")
                  ) {
                    cards.push(this.normalizeWhitespace(this.stripOtherInfo(c?.textContent ?? "")));
                  }
                });

                if (cards.length > 0) {
                  sectionInfo.push(
                    `There are ${cards.length} cards in this accordion group (section): ${cards
                      .filter(Boolean)
                      .map((s) => `"${s}"`)
                      .join(", ")}`
                  );
                }

                if ((insideSection.querySelector("google-chart") as HTMLElement)?.offsetParent) {
                  sectionInfo.push(`it contains a chart`);
                }

                if ((insideSection.querySelector("table") as HTMLElement)?.offsetParent) {
                  const tableHeadings: string[] = [];
                  insideSection.querySelectorAll("table th").forEach((th) => {
                    tableHeadings.push(this.normalizeWhitespace(this.stripOtherInfo(th?.textContent ?? "").trim()));
                  });
                  if (tableHeadings.length > 0) {
                    sectionInfo.push(
                      `it contains a table with the following headings: ${tableHeadings
                        .filter(Boolean)
                        .map((s) => "`" + s + "`")
                        .join(", ")}`
                    );
                  }
                }
              }
            } catch (e2) {
              this.log.error(e2);
            }
          }
        }
        accordionSections.push(sectionInfo.join(", "));
      } catch (e) {
        this.log.error(e);
      }
    });

    if (accordionSections.length > 0) {
      currentPageInfo.push(
        `\n\nThis page (report) consists an expandable accordion with ${
          accordionSections.length
        } expandable sections (accordion groups):\n\n${accordionSections
          .filter(Boolean)
          .map((s) => `- ${s}`)
          .join("\n")}`
      );
    }

    if ((document.querySelector(".give-feedback-btn") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nIn the bottom right corner of the page there is a feedback icon (button) allowing you to send feedback about this page to the development team`
      );
    }

    if ((document.querySelector("app-vficon[iconname='play-arrow']") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `In the bottom right corner of the page there is also a play icon (button) to start an interactive tutorial for new users. It will explain what this page is about`
      );
    }

    if ((document.querySelector("#filterButton") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nIn the top right corner of the page there is a \`Filter\` button allowing you to filter the ${domains.join(
          "/"
        )} data`
      );
      if ((document.querySelector("#shareButtonV2") as HTMLElement)?.offsetParent) {
        currentPageInfo.push(
          `In the top right corner of the page there is also a \`Share\` button allowing you to save the current filters and in a "share URL" which you can bookmark or send to a colleague`
        );
      }
    }

    if ((document.querySelector("app-vcs-help-side-bar") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nOn the right there is a side bar which contains an info icon that can be used to summon helptext/definitions/terms relevant to the report (searching is supported)`
      );
    }

    if ((document.querySelector("app-looker-studio-navigation") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nOn the left there is another side bar. Hover over it to navigate between different cloud providers, sections and reports. You can also find the currency conversion dropdown there to change between USD/EUR/GBP there`
      );
    }

    if (document.querySelector("app-breadcrumbs")) {
      const breadCrumbLinks: string[] = [];
      (document.querySelector("app-breadcrumbs") as HTMLElement).querySelectorAll("a[href]").forEach((a) => {
        breadCrumbLinks.push(
          `[${this.normalizeWhitespace(this.stripOtherInfo(a?.textContent ?? ""))}](${(
            (a as HTMLLinkElement)?.href ?? ""
          ).replace(location.origin, "")})`
        );
      });
      if (breadCrumbLinks.length > 0) {
        currentPageInfo.push(
          `\n\nThe top of the page has a breadcrumb navigation allowing you to navigate to previous sections of the application. It contains the following links: \n\n${breadCrumbLinks
            .filter(Boolean)
            .map((b) => `- ${b}`)
            .join("\n")}`
        );
      }
    }

    const cards = [];

    document.querySelectorAll(".card:not(.panel)").forEach((c: HTMLElement) => {
      if (
        c?.offsetParent &&
        !this.getElementsParents(c).some(
          (el) => el === llmComponent || el?.tagName === "ACCORDION" || el?.tagName === "APP-INTERACTIVE-CHART-SECTION"
        )
      ) {
        cards.push(this.normalizeWhitespace(this.stripOtherInfo(c?.textContent ?? "")));
      }
    });

    if (cards.length > 0) {
      currentPageInfo.push(
        `\n\nThere are ${cards.length} cards on this page:\n\n${cards
          .filter(Boolean)
          .map((s) => `- ${s}`)
          .join("\n")}`
      );
    }

    const customBtns = new Set();

    document.querySelectorAll(".custom-button").forEach((c: HTMLElement) => {
      if (
        c?.offsetParent &&
        !this.getElementsParents(c).some(
          (el) => el === llmComponent || el?.tagName === "ACCORDION" || el.classList.contains("terms-and-definitions")
        )
      ) {
        customBtns.add(this.normalizeWhitespace(this.stripOtherInfo(c?.textContent ?? "")));
      }
    });

    if (customBtns.size > 0) {
      currentPageInfo.push(
        `\n\nThere are also ${customBtns.size} buttons on this page:\n\n${[...customBtns]
          .filter(Boolean)
          .map((s) => `- ${s}`)
          .join("\n")}`
      );
    }

    if ((document.querySelector("app-looker-studio-report") as HTMLElement)?.offsetParent) {
      currentPageInfo.push(
        `\n\nThis is an embedded Looker Studio report. Due to iframe security enabled by Google, it's not possible for ${AI_NAME} to access the data on the report.`
      );
    }

    return [currentPageURL].concat([
      `${location.pathname.includes("vcloudsmart") ? "VCloudSmart" : "PCS Portal"} UI> ${currentPageInfo.join(". ")}`,
    ]);
  }

  public getCurrentPageContextForLLM(maxNApiCalls: number): {
    domains: QueryDomain[];
    cloudProviders: CloudProvider[];
    apiCalls: string[];
    currentPageInfo: string[];
    nApiCalls: number;
    history: string[];
  } {
    const urlSegments: string[] = location.pathname
      .split("/")
      .filter(Boolean)
      .filter((path) => path !== "vcloudsmart" && path !== "v2")
      .map((url) => decodeURIComponent(url));

    const cloudProviders: CloudProvider[] = (["aws", "gcp", "azure", "oci", "drcc"] as CloudProvider[]).filter(
      (csp: string) => {
        return (
          urlSegments.includes(csp) ||
          urlSegments.includes(csp.toUpperCase()) ||
          urlSegments.includes(csp.slice(0, 1).toUpperCase() + csp.slice(1).toLowerCase())
        );
      }
    );

    let domains: QueryDomain[] = [];

    if (REGEX_VCS_PAGE_URL.test(location.pathname)) {
      domains = ["billing"];
      if (
        urlSegments.some(
          (p) =>
            p.includes("cost-and-billing") || p.includes("sams") || p.includes("vams") || p.includes("cost-analysis")
        )
      ) {
        domains = ["billing"];
      } else if (
        urlSegments.some((p) => p.includes("economics") || p.includes("recommendation") || p.includes("insight"))
      ) {
        domains = ["recommendations"];
      } else if (urlSegments.some((p) => p.includes("asset") || p.includes("csb"))) {
        domains = ["assets"];
      }
    }

    const apiCallsPcsPortal: string[] = (
      JSON.parse(
        JSON.stringify(
          (this.pcsApi.capturedResponses[location.pathname] ?? []).filter((r) => r?.results && r?.body?.Url)
        )
      ) as Array<{ body: { Url: string; Params?: any }; results: any }>
    )
      .map((r) => {
        const charLimit = QUOTA_HIST_ITEM_LEN - 500;
        let stringified = r.results;
        let isTruncated = false;

        if (stringified.length >= charLimit) {
          stringified = stringified.slice(0, charLimit);
          isTruncated = true;
        }

        let requestInfo = `PCS Portal UI> GET ${r.body.Url}`;
        if (r.body.Params) {
          requestInfo += ` (${JSON.stringify(r.body.Params)})`;
        }

        return [
          requestInfo,
          `PCS Portal API> ${stringified}${isTruncated ? " (WARNING: This response has been truncated)" : ""}`,
        ];
      })
      .flat();

    const apiCallsVcs: string[] = (
      JSON.parse(
        JSON.stringify((this.vcs.capturedResponses[location.pathname] ?? []).filter((r) => r?.results && r?.body))
      ) as Array<{ body: GroupByPayload; results: Array<{ Group?: any[]; Value: any[] }> }>
    )
      .map((r) => {
        r.results = this.convertCurrencyInResponse(r.body, { Results: JSON.parse(JSON.stringify(r.results)) }, [
          "billing",
          "recommendations",
          "assets",
        ]).Results;
        return r;
      })
      .map(({ results: r, body }) => {
        const charLimit = QUOTA_HIST_ITEM_LEN - 500;
        let rowCount = N_DATA_SENT;
        let stringified = JSON.stringify(
          this.simplifyNumbers(r.map((row) => (row.Group ?? []).concat(row.Value ?? [])).slice(0, rowCount))
        );
        let isTruncated = false;
        let originalLen = r.length;
        let currentLen = r.length;
        while (stringified.length >= charLimit) {
          isTruncated = true;
          rowCount = Math.floor(rowCount * (2 / 3));
          currentLen = rowCount;
          stringified = JSON.stringify(
            this.simplifyNumbers(r.map((row) => (row.Group ?? []).concat(row.Value ?? [])).slice(0, rowCount))
          );
        }
        if (stringified && rowCount > 0) {
          return [
            `VCloudSmart UI> POST /queries/group-by ${this.vcs.JSONStringifyStable(body)}`,
            `VCloudSmart API> ${stringified}${
              isTruncated
                ? ` (WARNING: this response has been truncated to first ${currentLen} rows, it originally contained ${originalLen} rows${
                    originalLen >= ROWS_PER_PAGE
                      ? `. There are over in ${ROWS_PER_PAGE} rows in the database. They could not be fetched in full due to the volume of data`
                      : ""
                  })`
                : ""
            }`,
          ];
        } else {
          return [];
        }
      })
      .flat();

    let apiCalls = apiCallsPcsPortal.concat(apiCallsVcs).slice(-(maxNApiCalls * 2));

    const currentPageInfo: string[] = this.describeCurrentPage(domains);

    if (domains.length > 0) {
      currentPageInfo[1] += `\n\nThis page (report) belongs to the ${domains.join(", ")} domain(s).`;
    }

    if (cloudProviders.length > 0) {
      currentPageInfo[1] += `\n\nThis page (report) provides info about ${cloudProviders.join(" and ")}.`;
    }

    let history: string[] = [...currentPageInfo];

    const nApiCalls = apiCalls.length / 2;

    if (apiCallsPcsPortal.length > 0) {
      history = [
        ...history,
        `PCS Portal UI> Calling the PCS Portal APIs to populate the content of this page...
NOTE: What follows is ${apiCallsPcsPortal.length / 2} API calls (requests & responses) made on this page.`,
        ...apiCallsPcsPortal,
        "PCS Portal UI> Finished calling the PCS Portal APIs!",
      ];
    }

    if (apiCallsVcs.length > 0) {
      history = [
        ...history,
        `VCloudSmart UI> Calling the VCloudSmart API to populate the content of this page (report)...
NOTE: What follows is ${apiCallsVcs.length / 2} API calls (request payloads & responses) made on this page.`,
        ...apiCallsVcs,
        "VCloudSmart UI> Finished calling the VCloudSmart API!",
      ];
    }

    return { apiCalls, domains, cloudProviders, currentPageInfo, nApiCalls, history };
  }

  private formatTableRows(rows: TableRow[]): Array<Array<any>> {
    return rows.map((r) => r.map((cell) => cell.value ?? cell.rawValue ?? "?"));
  }

  public formatLocaleInfo(queryType: LLMQueryType): string[] {
    const finalResult: string[] = [];

    try {
      const now = new Date();

      const timeInfo: string = [
        `The date is: ${now.toLocaleDateString()}`,
        `ISO timestamp: ${now.toISOString().split("T")[0]}`,
      ]
        .filter(Boolean)
        .join(". ")
        .trim();

      finalResult.push(`VCloudSmart UI> ${timeInfo}.`);

      const currency =
        this.events?.currency?.value?.id ??
        this.events?.azureCurrency?.value?.id ??
        this.events?.drccCurrency?.value?.id ??
        (localStorage.getItem("currency") as CurrencyId) ??
        "USD";

      const currencyDetails = (this.looker.getCurrencies(currency) ?? [])[0];

      const languages: string[] = (navigator?.languages as string[]) || [
        (navigator?.language as string) ||
          // @ts-ignore
          (navigator?.userLanguage as string) ||
          currencyDetails?.locale ||
          document.querySelector("html").getAttribute("lang") ||
          "en-US",
      ];

      const preferredLanguage = languages[0];

      if (currencyDetails && preferredLanguage && queryType === "casual_interactions") {
        try {
          if (!!(document.querySelector("app-looker-studio-navigation") as HTMLElement)?.offsetParent) {
            finalResult.push(
              `VCloudSmart UI> The user has selected ${currencyDetails?.id} (${currencyDetails?.symbol}) currency so all values from the VCloudSmart API are in ${currencyDetails?.text}.`
            );
          }
        } catch (e) {
          this.log.error(e);
        }

        try {
          const n1 = 1_234_567.89;
          const n2 = 12.3456789;
          const nFormat = new Intl.NumberFormat(preferredLanguage, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            notation: "compact" as "compact" | "standard" | "scientific" | "engineering",
          });
          const timeZone = Intl.DateTimeFormat().resolvedOptions();
          finalResult.push(
            `
          
VCloudSmart UI> Since user's locale is ${preferredLanguage} (${
              timeZone?.timeZone
            }), exact/precise numbers such as \`${n1}\` and \`${n2}\` MUST be formatted as follows:

currency values:
- ${currencyDetails.format.format(n2)}
- ${currencyDetails.format.format(n1)}

non-currency values:
- ${nFormat.format(n1)}
- ${nFormat.format(n2)}
`.trim()
          );
        } catch (e) {
          this.log.error(e);
        }
      }
    } catch (e2) {
      this.log.error(e2);
    }

    return finalResult;
  }

  private formatHistory(
    responses: Response[],
    nResponses: number,
    who: Array<Entity>,
    types: Array<ResponseType>,
    queryType: LLMQueryType,
    userPerms: UserPermissions | undefined | null
  ): string[] {
    const conversationHistory: string[] = responses
      .filter((r) => (who ?? []).includes(r?.who))
      .filter((r) => (types ?? []).includes(r?.type))
      .map((r) => {
        try {
          if (r.type === "api_data") {
            let limitOfRows: number = N_DATA_SENT;
            let originalRowCount: number = (r?.tableRows ?? [])?.length ?? 0;
            let dataToBeSent = this.formatTableRows((r?.tableRows ?? []).slice(0, limitOfRows));
            let nRows: number = dataToBeSent.length;
            let chars: string = JSON.stringify(dataToBeSent);

            while (chars.length > QUOTA_HIST_ITEM_LEN) {
              limitOfRows = Math.floor(limitOfRows * (2 / 3));
              dataToBeSent = dataToBeSent.slice(0, limitOfRows);
              chars = JSON.stringify(dataToBeSent);
              nRows = dataToBeSent.length;
            }

            return `${r.who}> ${chars}${
              nRows < originalRowCount
                ? `. WARNING: this is the first ${nRows} rows from the API, there are ${
                    originalRowCount >= ROWS_PER_PAGE
                      ? `over ${ROWS_PER_PAGE} rows in the database which due to size could not be sent here`
                      : `${originalRowCount} rows which due to size could not be sent in full`
                  }.`
                : ""
            }`;
          } else if (r.type === "text") {
            const message = this.convertHTMLToMarkdown(r.message.trim()).trim();
            return `${r.who}> ${
              message.length < QUOTA_HIST_ITEM_LEN
                ? message
                : `${message.slice(0, QUOTA_HIST_ITEM_LEN)}... (this message has been truncated)`
            }`;
          } else if (r.type === "generated_api_payload") {
            const payloadStringified = this.vcs.JSONStringifyStable(r.payload);
            return `VCloudSmart UI> POST /queries/group-by ${
              payloadStringified.length < QUOTA_HIST_ITEM_LEN
                ? payloadStringified
                : `${payloadStringified.slice(0, QUOTA_HIST_ITEM_LEN)}... (this API payload has been truncated)`
            }`;
          }
        } catch (e) {
          this.log.error(e);
        }
      })
      .filter(Boolean)
      .slice(-nResponses);

    const userPreferences: string[] = [];
    const userSentences: string[] = [];

    for (const [attribute, sentence] of Object.entries(CONFIG_ATTRS_DESCRIPTION)) {
      const userValue = localStorage.getItem(attribute);
      if (userValue) {
        userSentences.push(sentence.replace("{placeholder}", userValue));
      }
    }

    if (userSentences.length > 0) {
      userPreferences.push(`User> ${userSentences.join(". ")}`);
    }

    const localeInfo = this.formatLocaleInfo(queryType);
    const userPermInfo = this.describeUserPerms(userPerms);
    if (queryType === "casual_interactions") {
      const pageContext = this.getCurrentPageContextForLLM(QUOTA_N_HIST_ITEMS_CURRENT_PAGE);
      return [
        ...localeInfo,
        ...userPermInfo,
        ...userPreferences,
        ...pageContext.history,
        ...conversationHistory.slice(
          -(
            QUOTA_N_HIST_ITEMS -
            userPreferences.length -
            pageContext.history.length -
            localeInfo.length -
            userPermInfo.length
          )
        ),
      ];
    } else if (queryType === "analyse_query") {
      const currentPageInfo = this.getCurrentPageContextForLLM(0).currentPageInfo.slice(0, 1);
      return [
        ...userPreferences,
        ...currentPageInfo,
        ...conversationHistory.slice(-(QUOTA_N_HIST_ITEMS - userPreferences.length - currentPageInfo.length)),
      ];
    } else {
      return [
        ...localeInfo,
        ...userPermInfo,
        ...userPreferences,
        ...conversationHistory.slice(
          -(QUOTA_N_HIST_ITEMS - userPreferences.length - localeInfo.length - userPermInfo.length)
        ),
      ];
    }
  }

  public getTableName(r: VcsApiResponse, idx: number): string {
    return `llm-table-${idx}-${r?.tableRows?.length ?? 0}-${r?.tableColumns?.length ?? 0}`;
  }

  public shouldDisplayTable(r: VcsApiResponse): boolean {
    return r?.selectedTab === "Table";
  }

  public canDisplayPieChart(r: VcsApiResponse, sidebar: boolean): boolean {
    if ((r?.chartRows ?? []).some((row) => (row ?? []).some((v) => (v?.v ?? 0) < 0))) {
      return false;
    }
    return this.canDisplayColumnChart(r, sidebar);
  }

  public canDisplayColumnChart(r: VcsApiResponse, sidebar: boolean): boolean {
    const colLen = (r?.chartColumns ?? [])?.length ?? 0;
    if (colLen !== 2) {
      if ((r?.selectedTab ?? "Chart").endsWith("Chart")) {
        r.selectedTab = "Table";
      }
      for (const c in r || {}) {
        if (c.endsWith("ChartError")) {
          r[c] = true;
        }
      }
      return false;
    }
    const rowLen = (r?.chartRows ?? [])?.length ?? 0;
    if (rowLen === 0 || rowLen === 1 || rowLen > (sidebar ? 50 : 200)) {
      if ((r?.selectedTab ?? "Chart").endsWith("Chart")) {
        r.selectedTab = "Table";
      }
      for (const c in r || {}) {
        if (c.endsWith("ChartError")) {
          r[c] = true;
        }
      }
      return false;
    }
    return true;
  }

  public shouldDisplayPayload(r: VcsApiResponse): boolean {
    return r?.selectedTab === "Payload";
  }

  public shouldDisplayPieChart(r: VcsApiResponse): boolean {
    return r?.selectedTab === "Pie Chart";
  }

  public shouldDisplayColumnChart(r: VcsApiResponse): boolean {
    return r?.selectedTab === "Column Chart";
  }

  private fmtTableRows(output: GroupByApiResult, requestBody: GroupByPayload, domains: QueryDomain[]): TableRow[] {
    return (output?.Results ?? []).map((r) => {
      const row = [];
      for (let cIdx = 0; cIdx < (requestBody?.Projection?.length ?? 0); cIdx++) {
        const cell: TableCell<any> = {
          data: r,
          rawValue: r.Value[cIdx],
          value: undefined,
        };
        const safeDomains = domains ?? [];
        if (
          (this.isAggregateSI(requestBody?.Projection[cIdx]?.Operation) ||
            (requestBody?.Projection[cIdx]?.Attribute ?? "").endsWith("Count")) &&
          (safeDomains.includes("assets") || safeDomains.includes("recommendations"))
        ) {
          cell.value = this.looker.formatNumber(cell.rawValue as number)?.fmt;
        } else if (
          this.isAggregateCurrency(requestBody?.Projection[cIdx]?.Operation) &&
          !(requestBody?.Projection[cIdx]?.Attribute ?? "").endsWith("Count") &&
          (safeDomains.includes("billing") || safeDomains.includes("recommendations"))
        ) {
          cell.value = this.events.currency.value.format.format(cell.rawValue as number);
        } else {
          cell.value = cell.rawValue;
        }
        row.push(cell);
      }
      return row;
    });
  }

  private simplifyNumbers<T extends string | number | boolean, K extends string | number | boolean>(
    rows: Array<Array<T>>
  ): Array<Array<K>> {
    /**
     * This is to reduce the JSON payload size
     */
    return rows.map((row: T[]) => {
      return row.map((cell: T): K => {
        if (typeof cell === "number" && Math.trunc(cell) !== cell) {
          return parseFloat((cell as number).toFixed(2)) as K;
        }
        return cell as unknown as K;
      });
    });
  }

  private getElementsParents(el: HTMLElement): HTMLElement[] {
    const parents: HTMLElement[] = [];
    let parent: HTMLElement = el.parentElement;
    while (parent) {
      parents.push(parent);
      parent = parent.parentElement;
    }
    return parents;
  }

  private normalizeWhitespace(s: string): string {
    return s.replace(/\s+/g, " ").trim();
  }

  private stripOtherInfo(s: string): string {
    return s
      .replace("NEW", "")
      .replace("ALPHA", "")
      .replace("BETA", "")
      .replace("View", "")
      .replace("Explore", "")
      .replace("Read mode", "");
  }

  public convertCurrencyInResponse(
    requestBody: GroupByPayload,
    response: GroupByApiResult,
    domains: QueryDomain[]
  ): GroupByApiResult {
    for (let colIdx = 0; colIdx < requestBody.Projection.length; colIdx++) {
      const p = requestBody.Projection[colIdx] ?? {};
      const domainsSafe = domains ?? [];
      if (
        this.isAggregateCurrency(p.Operation) &&
        !(p?.Attribute ?? "").endsWith("Count") &&
        (domainsSafe.includes("billing") || domainsSafe.includes("recommendations"))
      ) {
        this.log.info({ msg: "applying currency conversion", attribute: p, domains });
        for (const row of response.Results) {
          if (row.Value[colIdx] !== undefined && row.Value[colIdx] !== null) {
            try {
              if (requestBody.CloudProvider === CloudProvider.azure) {
                row.Value[colIdx] = this.events?.azureCurrency?.value?.exchangeRate
                  .mul(row.Value[colIdx])
                  .toDecimalPlaces(2)
                  .toNumber();
              } else if (requestBody.CloudProvider === CloudProvider.drcc) {
                row.Value[colIdx] = this.events?.drccCurrency?.value?.exchangeRate
                  .mul(row.Value[colIdx])
                  .toDecimalPlaces(2)
                  .toNumber();
              } else {
                row.Value[colIdx] = this.events?.currency?.value?.exchangeRate
                  .mul(row.Value[colIdx])
                  .toDecimalPlaces(2)
                  .toNumber();
              }
            } catch (e) {
              this.log.error(e);
            }
          }
        }
      }
    }

    return response;
  }

  private fmtDateRange(body: GroupByPayload): string | undefined {
    try {
      const dateFilters: QueryFilterSimple[] = (body.Filters ?? []).filter(
        (f: QueryFilterSimple) => f?.Type === "DATE" || f?.Type === "TIMESTAMP"
      ) as QueryFilterSimple[];

      const startDate: QueryFilterSimple = dateFilters.find(
        (f) => f?.Operator === ">=" || f?.Operator === ">" || f?.Operator === "="
      );
      const startDateFmtted = new Date(startDate?.Value as string).toLocaleDateString();

      const endDate: QueryFilterSimple = dateFilters.find(
        (f: QueryFilterSimple) => f?.Operator === "<=" || f?.Operator === "<"
      ) as QueryFilterSimple;

      if (endDate) {
        let endDateFmtted: string;
        if (endDate?.Operator === "<") {
          endDateFmtted = this.vcs.addDays(endDate.Value as string, -1).toLocaleDateString();
        } else {
          endDateFmtted = new Date(endDate.Value as string).toLocaleDateString();
        }

        if (startDateFmtted === endDateFmtted) {
          if (startDateFmtted.toLowerCase().includes("invalid")) {
            return;
          }
          return startDateFmtted;
        } else {
          return `${startDateFmtted} - ${endDateFmtted}`;
        }
      } else {
        if (startDateFmtted.toLowerCase().includes("invalid")) {
          return;
        }
        return startDateFmtted;
      }
    } catch (e) {}
  }

  private fmtLimit(body: GroupByPayload): number | undefined {
    if (body?.Limit !== undefined && body?.Limit !== MAX_API_RESPONSE_LIMIT && body.Limit !== ROWS_PER_PAGE) {
      return body.Limit;
    }
  }

  private orderCandidateApiPayloads(
    proposals: { payload?: GroupByPayload; messageId?: string; modelConfig: ModelConfig }[]
  ): {
    payload: GroupByPayload;
    messageIds: string[];
  }[] {
    const counter: { [payload: string]: { count: number; modelConfigs: ModelConfig[]; messageIds: string[] } } = {};
    for (const p of proposals) {
      if (p?.payload !== null && p?.payload !== undefined) {
        const k = this.vcs.JSONStringifyStable(p.payload);
        const existingVal = (counter || {})[k];
        counter[k] = {
          count: (existingVal?.count ?? 0) + 1,
          modelConfigs: (existingVal?.modelConfigs ?? []).concat([p.modelConfig]),
          messageIds: (existingVal?.messageIds ?? []).concat([p.messageId]),
        };
      }
    }
    const entries = Object.entries(counter).sort(
      (
        [_payload1, { count: count1, modelConfigs: modelConfig1 }],
        [_payload2, { count: count2, modelConfigs: modelConfig2 }]
      ) => {
        if (count1 > count2) {
          return -1;
        } else if (count1 < count2) {
          return 1;
        } else {
          const temp1: number =
            modelConfig1.map((c): number => c.temperature).reduce((left, right) => (left > right ? left : right)) ?? 1;
          const temp2: number =
            modelConfig2.map((c): number => c.temperature).reduce((left, right) => (left > right ? left : right)) ?? 1;
          // prefer more conservative answers
          if (temp1 > temp2) {
            return 1;
          } else if (temp1 < temp2) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    );
    if (!environment.production) {
      this.log.info({
        msg: "narrowed down candidates",
        candidates: entries.map(([payload, { count, modelConfigs, messageIds }]) => ({
          count,
          payload: JSON.parse(payload),
          messageIds,
          modelConfigs,
        })),
      });
    }
    return entries.map(([payload, { messageIds }]): { payload: GroupByPayload; messageIds: string[] } => {
      return { payload: JSON.parse(payload) as GroupByPayload, messageIds };
    });
  }

  private getChartRows(
    requestBody: GroupByPayload,
    response: GroupByApiResult,
    domains: QueryDomain[]
  ): Array<Array<{ v: any; f: string }>> {
    const isAttrDate: RegExpExecArray[][] = Array(requestBody.Projection.length)
      .fill(null)
      .map((_, cIdx) => {
        return response.Results.map((r): RegExpExecArray | null => {
          const s = r.Value[cIdx] ?? "";
          return typeof s === "string" ? REGEX_DATE.exec(s) : null;
        });
      });

    const isDateCol: boolean[] = isAttrDate.map((matches) => !matches.some((v) => v === null));

    const isDateColDay: boolean[] = isAttrDate.map((matches, cIdx): boolean => {
      if (!isDateCol[cIdx]) {
        return false;
      }
      for (const m of matches) {
        if ((m.groups["day"] ?? "01") !== "01") {
          return true;
        }
      }
      return false;
    });

    const isDateColYear: boolean[] = isAttrDate.map((matches, cIdx): boolean => {
      if (!isDateCol[cIdx]) {
        return false;
      }
      for (const m of matches) {
        if ((m.groups["month"] ?? "01") !== "01") {
          return false;
        }
      }
      return true;
    });

    return (response?.Results ?? []).map((r, rIdx: number) => {
      const row = (r.Group ?? []).map((g) => ({ v: g, f: g }));
      for (let cIdx = 0; cIdx < requestBody.Projection.length; cIdx++) {
        if (isDateCol[cIdx]) {
          const matchDate = isAttrDate[cIdx][rIdx];
          const newVal: { v: any; f: string } = {
            v: r.Value[cIdx],
            f: undefined,
          };
          const dateString = `${matchDate.groups["year"]}-${matchDate.groups["month"]}-${
            matchDate.groups["day"] ?? "01"
          }`;
          if (isDateColYear[cIdx]) {
            newVal.f = this.looker.fmtYear(dateString);
          } else if (isDateColDay[cIdx]) {
            newVal.f = this.looker.fmtDay(dateString);
          } else {
            newVal.f = this.looker.fmtMonth(dateString);
          }
          row.push(newVal);
        } else {
          const safeDomains = domains ?? [];
          if (
            (safeDomains.includes("assets") || safeDomains.includes("recommendations")) &&
            (this.isAggregateSI(requestBody.Projection[cIdx].Operation) ||
              (requestBody?.Projection[cIdx]?.Attribute ?? "").endsWith("Count"))
          ) {
            row.push({
              v: r.Value[cIdx],
              f: this.looker.formatNumber(r.Value[cIdx] ?? 0).fmt,
            });
          } else if (
            (safeDomains.includes("billing") || safeDomains.includes("recommendations")) &&
            this.isAggregateCurrency(requestBody.Projection[cIdx].Operation) &&
            !(requestBody?.Projection[cIdx]?.Attribute ?? "").endsWith("Count")
          ) {
            if (requestBody.CloudProvider === CloudProvider.azure) {
              row.push({
                v: r.Value[cIdx],
                f: this.events.azureCurrency.value.format.format(r.Value[cIdx] ?? 0),
              });
            } else if (requestBody.CloudProvider === CloudProvider.drcc) {
              row.push({
                v: r.Value[cIdx],
                f: this.events.drccCurrency.value.format.format(r.Value[cIdx] ?? 0),
              });
            } else {
              row.push({
                v: r.Value[cIdx],
                f: this.events.currency.value.format.format(r.Value[cIdx] ?? 0),
              });
            }
          } else {
            row.push(r.Value[cIdx]);
          }
        }
      }
      return row;
    });
  }

  private isPayloadValid(parsed?: GroupByPayload): boolean {
    try {
      if (!parsed?.DataStore || !parsed?.CloudProvider) {
        this.log.warning({ msg: "missing data store or cloud provider in payload", payload: parsed });
        return false;
      }
      if ((parsed?.Projection?.length ?? 0) === 0) {
        this.log.warning({ msg: "empty projection", payload: parsed });
        return false;
      }
      if ((parsed.GroupBy ?? []).length > 0) {
        if (parsed.Projection.some((p, pIdx) => !p.Operation && !(parsed.GroupBy ?? []).includes(`Value${pIdx + 1}`))) {
          this.log.warning({ msg: "missing group by operation on projection item", payload: parsed });
          return false;
        }
        for (const p of parsed.GroupBy ?? []) {
          if (!groupByFieldRegex.test(p)) {
            this.log.warning({ msg: "invalid group by attribute", payload: parsed });
            return false;
          }
          const idx = parseInt(groupByFieldRegex.exec(p)[1]) - 1;
          const linkedAttr = parsed?.Projection[idx] ?? {};
          if (!linkedAttr.Attribute) {
            this.log.warning({ msg: "unable to link attr to group by item", payload: parsed });
            return false;
          }
          if (this.isAggregate(linkedAttr.Operation)) {
            this.log.warning({ msg: "cannot apply projection operation to a group by attr", payload: parsed });
            return false;
          }
        }
      }

      if ((parsed.OrderBy ?? []).length > 0) {
        for (let i = 0; i < parsed.OrderBy.length; i++) {
          if (!groupByFieldRegex.test(parsed.OrderBy[i])) {
            this.log.warning({ msg: "invalid order by referencing invalid attr", payload: parsed });
            return false;
          }
          const idx = parseInt(groupByFieldRegex.exec(parsed.OrderBy[i])[1]) - 1;
          if (!parsed?.Projection[idx]?.Attribute) {
            this.log.warning({ msg: "invalid order by referencing invalid attr", payload: parsed });
            return false;
          }
        }
      }

      for (const p of parsed?.Projection ?? []) {
        if (parsed.SelfJoin === undefined) {
          if (p.Attribute !== "*" && !p.Attribute.startsWith("Table_1.") && !p.Attribute.startsWith("Table_2.")) {
            this.log.warning({ msg: "missing Table_ prefix", payload: parsed });
            return false;
          }
        } else {
          const selfJoinAttr = parsed.SelfJoin.Attribute;
          if (p.Attribute.startsWith(`Table_1.${selfJoinAttr}`) || p.Attribute.startsWith(`Table_2.${selfJoinAttr}`)) {
            this.log.warning({
              msg: `invalid table reference, should be Table_N_${selfJoinAttr}. not ${p.Attribute}`,
              payload: parsed,
              selfJoinAttr,
            });
            return false;
          }
          if (
            p.Attribute !== "*" &&
            !p.Attribute.startsWith("Table_1.") &&
            !p.Attribute.startsWith("Table_2.") &&
            !p.Attribute.startsWith(`Table_1_${selfJoinAttr}.`) &&
            !p.Attribute.startsWith(`Table_2_${selfJoinAttr}.`)
          ) {
            this.log.warning({ msg: `missing Table_N_${selfJoinAttr} prefix`, payload: parsed, selfJoinAttr });
            return false;
          }
        }
      }

      return true;
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }

  private prettifyAttribute(a: string): string {
    try {
      return this.looker.prettifyAttribute(
        a
          .split(".")
          .slice(-1)[0]
          .replace(/^line_item_/, "")
      );
    } catch (e) {
      return a;
    }
  }

  private logLLMUsage(
    conversationId: string,
    messageId: string | undefined,
    query: string,
    queryType: LLMQueryType,
    domains: QueryDomain[],
    cloud_providers: CloudProvider[],
    response: string,
    cost: number
  ): void {
    setTimeout(() => {
      let headers = new HttpHeaders();

      try {
        headers = this.vcs.getHeaders();
      } catch (e) {
        if (e.message === "Session Expired") {
          this.authN.autoLogout();
        }
        return;
      }

      this.http
        .request("POST", `${this.vcs.baseUrl}/log/llm`, {
          body: Object.fromEntries(
            Object.entries({
              Timestamp: new Date().toISOString(),
              ConversationId: conversationId,
              MessageId: messageId,
              Query: query,
              Response: response,
              Cost: cost,
              QueryType: queryType,
              Domains: domains,
              CloudProviders: cloud_providers,
            }).filter((e) => e[1] !== undefined)
          ),
          headers,
          observe: "body",
          responseType: "json",
        })
        .pipe(timeout(SECOND * 20))
        .subscribe(
          () => {},
          (e) => {
            this.log.error(e);
          }
        );
    }, 5000);
  }

  public convertHTMLToMarkdown(message: string): string {
    return this.stripHTML(
      this.markdownConverter
        .makeMarkdown((message ?? "").trim())
        .replace("<!-- -->", "")
        .trim()
    ).replace(/\n{3,}/g, "\n\n");
  }

  public copyHtmlTextAsMarkdown(message: string): void {
    this.clipboard.copy(this.convertHTMLToMarkdown(message));
  }

  public formatURL(generatedLink: string, vcsConfig: Csp[]): SuggestedURL | undefined {
    if (!generatedLink) {
      return;
    }

    if (generatedLink.includes("%25")) {
      return this.formatURL(generatedLink.replace(REGEX_PERCENT, "%"), vcsConfig);
    }

    if (generatedLink.includes("(")) {
      return this.formatURL(generatedLink.replace(REGEX_LPAREN, "%28").replace(REGEX_RPAREN, "%29"), vcsConfig);
    }

    if (generatedLink.includes("%20")) {
      return this.formatURL(generatedLink.replace(REGEX_SPACE, " "), vcsConfig);
    }

    const url = new URL(`${location.origin}${generatedLink}`);

    const linkObj: SuggestedURL = {
      Name: "",
      Query: {},
      URL: (url?.pathname ?? "")
        .split("/")
        .map((s) => decodeURIComponent(s).replace(REGEX_LPAREN, "%28").replace(REGEX_RPAREN, "%29"))
        .join("/"),
      Fragment: (url?.hash ?? "").slice(1),
      FullUrl: (url?.href ?? "").replace(url?.origin ?? "", ""),
    };

    if (linkObj.Fragment === "#" || linkObj.Fragment === "") {
      delete linkObj.Fragment;
    }

    url.searchParams.forEach((value, key) => {
      linkObj.Query[key] = value;
    });

    if (Object.entries(linkObj.Query).length === 0) {
      delete linkObj.Query;
    }

    try {
      const urlSegmentsPretty: string[] = (linkObj?.URL ?? "")
        .split("/")
        .filter((part) => part.toLowerCase() !== "v2")
        .map((s) => s.replace(REGEX_LPAREN, "(").replace(REGEX_RPAREN, ")"))
        .filter(Boolean);

      for (const csp of CLOUD_PROVIDERS) {
        if (
          urlSegmentsPretty.map((s) => s.toLowerCase()).includes(csp) ||
          (url?.hash ?? "").toLowerCase().includes(csp) ||
          JSON.stringify(linkObj?.Query ?? {})
            .toLowerCase()
            .includes(csp)
        ) {
          linkObj.CloudProvider = csp.toUpperCase();
          break;
        }
      }

      if (
        urlSegmentsPretty.includes("vcloudsmart") &&
        urlSegmentsPretty.filter((part) => part !== "vcloudsmart").length >= 3
      ) {
        const [cspId, sectionId, pageId] = urlSegmentsPretty.filter((part) => part !== "vcloudsmart");

        linkObj.Name = pageId;
        linkObj.CloudProvider = cspId.toUpperCase();
        linkObj.Section = sectionId;

        const lastSegment = urlSegmentsPretty.filter((part) => part !== "vcloudsmart").slice(-1)[0];

        if (lastSegment !== linkObj.Name) {
          linkObj.Section = linkObj.Name.slice(0, 1).toUpperCase() + linkObj.Name.slice(1);
          linkObj.Name = lastSegment.slice(0, 1).toUpperCase() + lastSegment.slice(1);
        }

        if (linkObj.Fragment) {
          linkObj.Name += ` (${this.looker.prettifyAttribute(linkObj.Fragment.split("/")[0])})`;
        }

        if (
          vcsConfig === undefined ||
          vcsConfig.some((csp) => {
            return (
              (csp?.id ?? "").toLowerCase() === (cspId ?? "").toLowerCase() &&
              (csp?.sections ?? []).some((section) => {
                return (
                  (section?.title === sectionId || transformLegacySectionName(section.title) === sectionId) &&
                  (section?.pages ?? []).some((page) => {
                    return page?.title === pageId || transformLegacyPageName(page?.title) == pageId;
                  })
                );
              })
            );
          })
        ) {
          return linkObj;
        }
      } else if (urlSegmentsPretty.length > 0) {
        try {
          const lastSegment = urlSegmentsPretty.slice(-1)[0];
          linkObj.Name = lastSegment.slice(0, 1).toUpperCase() + lastSegment.slice(1);
        } catch (e) {
          this.log.error(e);
          return;
        }

        if (linkObj.Fragment) {
          linkObj.Name += ` (${this.looker.prettifyAttribute(linkObj.Fragment.split("/")[0])})`;
        }

        if (urlSegmentsPretty.length > 1) {
          try {
            const secondToLastSegment = urlSegmentsPretty.slice(-2)[0];
            linkObj.Section = secondToLastSegment.slice(0, 1).toUpperCase() + secondToLastSegment.slice(1);
            return linkObj;
          } catch (e) {
            this.log.error(e);
            return linkObj;
          }
        } else {
          return linkObj;
        }
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  public convertMarkdownToHTML(s: string): string {
    return convertMarkdownToHTML(s);
  }

  private isAggregateSI(o: ProjectionOperation): boolean {
    return o === ProjectionOperation.count;
  }

  private isAggregate(o: ProjectionOperation): boolean {
    return this.isAggregateSI(o) || this.isAggregateCurrency(o);
  }

  private getVAxisTitle(r: GroupByPayload): string {
    try {
      return this.prettifyAttribute(
        (r?.Projection ?? []).find((p) => this.isAggregate(p?.Operation))?.Attribute ?? "Value"
      );
    } catch (e) {
      this.log.error(e);
      return "Value";
    }
  }

  private getHAxisTitle(r: GroupByPayload): string {
    try {
      const groupByAttrs = r?.GroupBy ?? [];
      const attrIdx = parseInt((groupByAttrs[0] ?? "").replace("Value", "")) - 1;
      return this.prettifyAttribute((r?.Projection ?? [])[attrIdx]?.Attribute ?? "Item");
    } catch (e) {
      this.log.error(e);
      return "Item";
    }
  }
}
