export function getEmailRegexes(): { REGEX_EMAIL: RegExp; REGEX_EMAIL_STR: string } {
  try {
    const { Domains: emailDomains } = JSON.parse(localStorage.getItem("matchingTenants") ?? "[]")[0];
    const emailDomainsNew = [];
    for (const domain of emailDomains.map((d) => d.toLowerCase()).map((d) => d.replace(/\./g, "\\."))) {
      let caseInsensitiveDomain = "";
      for (let i = 0; i < domain.length; i++) {
        const code = (domain as string).charCodeAt(i);
        // is lowercase character
        if (code >= 97 && code <= 122) {
          caseInsensitiveDomain += `[${domain[i]}${domain[i].toUpperCase()}]`;
        } else {
          caseInsensitiveDomain += domain[i];
        }
      }
      emailDomainsNew.push(caseInsensitiveDomain);
    }
    const regexEmailStr = `^[-_.a-zA-Z0-9]+@(${emailDomainsNew.join("|")})$`;
    return {
      REGEX_EMAIL: new RegExp(regexEmailStr),
      REGEX_EMAIL_STR: regexEmailStr,
    };
  } catch (e) {
    const regexEmailStr = "^.*@.*$";
    return {
      REGEX_EMAIL: new RegExp(regexEmailStr),
      REGEX_EMAIL_STR: regexEmailStr,
    };
  }
}
