import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { LoggerService } from "../../services/logger.service";
import { VFIconComponent } from "../vficon/vficon.component";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { FilterItem, TableColumn } from "../../models/common-table";

const REGEX_COLUMN_SEARCH = /^\s*(.+?)\s+::\s+(.+?)\s*$/;

@Component({
  standalone: true,
  imports: [CommonModule, VFIconComponent, ReactiveFormsModule, TooltipModule],
  selector: "app-table-search",
  templateUrl: "./table-search.component.html",
  styleUrls: ["./table-search.component.scss"],
})
export class TableSearchComponent implements AfterViewChecked, OnChanges, OnInit {
  public filtered = false;
  @Input() columns: TableColumn[];
  @Input() filterItems: FilterItem[] = [];
  @Input() downloadSelected: boolean = false;
  @Input() disableDownload: boolean = false;
  @Input() lowerCaseInput = true;
  @Output() changeEvent: EventEmitter<FilterItem[]> = new EventEmitter<FilterItem[]>();
  @Output() downloadEvent = new EventEmitter();
  @Input() isDynamic = false;

  // this must be here, please don't remove
  public form = new FormGroup({});

  public isSearchBoxOpen: boolean = false;
  public searchText = new FormControl("", [Validators.required]);
  public isInputLoaded: boolean = false;
  public searchColumn: string = "";
  public typedColumn: string = "";
  public columnsDropdown: boolean = false;
  public sortedColumns: TableColumn[] = [];

  // HTML element references
  @ViewChild("searchTextInput") searchTextInput: ElementRef;
  @ViewChild("searchTextContainer") searchTextContainer: ElementRef;

  // listeners
  @HostListener("click", ["$event.target"])
  public clickOutside(element: HTMLElement) {
    if (!this.searchTextContainer?.nativeElement?.contains(element)) {
      this.columnsDropdown = false;
    }
  }

  constructor(private log: LoggerService) {}

  ngOnInit() {
    this.changeEvent.subscribe((filterItems) => {
      this.log.info("changed filter items to ", filterItems);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.columns?.firstChange) {
      this.log.info("Search Table Changes: ", { changes });
      this.sortedColumns = this.getSortedColumns([...this.columns]);
    }
  }

  ngAfterViewChecked() {
    if (this.isInputLoaded) {
      this.searchTextInput?.nativeElement?.focus();
      this.isInputLoaded = false;
    }
    if (document.activeElement === this.searchTextInput?.nativeElement && this.typedColumn === "") {
      this.openColumnsDropdown(true);
    }
    if (this.searchText.value === "") {
      this.searchColumn = "";
      this.typedColumn = "";
      this.sortedColumns = this.getSortedColumns([...this.columns]);
      this.openColumnsDropdown(true);
    }
  }

  public getSortedColumns(arr: TableColumn[]): TableColumn[] {
    return arr.sort((a, b) => (a.label || a.title).localeCompare(b.label || b.title));
  }

  public openSearchBox(open: boolean): void {
    this.isSearchBoxOpen = open;
    this.isInputLoaded = true;
    if (!open) this.resetFilterForm();
  }

  //reset search text and column
  public resetFilterForm(): void {
    this.searchText.setValue("");
    this.searchColumn = "";
    this.typedColumn = "";
  }

  public openColumnsDropdown(open: boolean) {
    this.columnsDropdown = open;
  }

  public searchColumns(event: KeyboardEvent): void {
    let text: string = (<HTMLInputElement>event.target).value;
    const match = this.columns.filter((col: TableColumn) => {
      return col.label.toLowerCase().includes(text.toLowerCase());
    });
    if (match) {
      this.sortedColumns = this.getSortedColumns([...match]);
    }
  }

  public selectColumn(column: Partial<TableColumn>): void {
    this.searchColumn = column.title;
    this.typedColumn = column.label;
    this.searchText.setValue(`${column.label || column.title} :: `);
    this.searchTextInput.nativeElement.focus();
    this.columnsDropdown = false;
  }

  public onFilterFormSubmit(): void {
    this.filtered = true;
    let value = this.searchText.value.trim();
    const match = REGEX_COLUMN_SEARCH.exec(value);
    let columnLabel = "";
    let searchText = "";

    if (!value) {
      this.searchTextInput.nativeElement.style.color = "red";
      return;
    } else if (match !== null) {
      columnLabel = match[1];
      searchText = match[2];
      if (!columnLabel || !searchText) {
        this.searchTextInput.nativeElement.style.color = "red";
        return;
      }
    } else {
      columnLabel = "ALL";
      searchText = value;
      this.searchColumn = "all";
    }

    // check if this column already queried
    const index: number = this.filterItems.findIndex(
      (filterItem) => (filterItem.columnLabel || filterItem.columnTitle).toLowerCase() === columnLabel.toLowerCase()
    );

    const newFilterItems = JSON.parse(JSON.stringify(this.filterItems));

    if (index !== -1) {
      newFilterItems[index].searchText.push(this.lowerCaseInput ? searchText.toLowerCase() : searchText);
    } else {
      if (!this.searchColumn) {
        for (const column of this.sortedColumns) {
          if ((column.label || column.title).toLowerCase() === columnLabel.toLowerCase()) {
            this.searchColumn = column.title;
            columnLabel = column.label || column.title;
            break;
          }
        }
      }
      newFilterItems.push({
        searchText: [this.lowerCaseInput ? searchText.toLowerCase() : searchText],
        columnTitle: this.searchColumn,
        columnLabel,
      });
    }
    this.openSearchBox(false);
    this.sortedColumns = this.getSortedColumns([...this.columns]);
    this.changeEvent.emit(newFilterItems);
  }

  public removeFilterItem(index: number): void {
    this.log.info("removing filter item", index, this.filterItems[index]);
    this.changeEvent.emit(this.filterItems.filter((_, idx) => idx !== index));
  }

  public clickDownload(option: string): void {
    this.downloadEvent.emit(option);
  }
}
