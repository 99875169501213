<div id="outlet-wrapper" class="position-relative d-flex flex-column min-vh-100">
  <div class="min-vh-100" style="padding-bottom: 50px">
    <ng-container *ngIf="isLoggedIn">
      <app-header class="header"></app-header>

      <img
        *ngIf="hasTutorials"
        id="tutorial-arrow"
        class="d-none position-fixed"
        src="/assets/images/tutorial-arrow.svg"
        alt="tutorial-arrow"
      />

      <div *ngIf="router.url.startsWith('/dashboard') && (router.url === '/dashboard' || router.url.includes('#'))">
        <img class="h-100 w-100" src="/assets/images/banner.png" alt="banner" />
      </div>

      <app-breadcrumbs></app-breadcrumbs>

      <div
        [class.d-none]="!showAI"
        *ngIf="popUpAIActivated && !isLLMPage"
        class="position-fixed overflow-auto bg-light p-5 rounded shadow"
        style="max-height: 80vh; max-width: 480px; bottom: 88px; z-index: 20; right: 80px"
      >
        <app-llm (closeLLM)="showAI = false" [sidebar]="true"></app-llm>
      </div>

      <ul
        *ngIf="hasTutorials || showContactButton || showFeedbackButton"
        class="feedback-buttons d-none d-md-flex list-unstyled flex-nowrap"
        [ngClass]="{ right: isRight }"
      >
        <button
          tooltip="Give feedback about the current page"
          *ngIf="showFeedbackButton"
          class="btn mt-3 give-feedback-btn"
          (click)="toggleFeedbackPopUp()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="like"
          ></app-vficon>
        </button>
        <a tooltip="Reach out to CCE team" *ngIf="showContactButton" class="btn mt-3" [href]="mailToURL">
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="sms"
          ></app-vficon>
        </a>

        <button
          *ngIf="hasTutorials && !tutorialInProgress"
          tooltip="Play '{{ currentTutorial }}' tutorial"
          class="btn mt-3 reset-tutorials-btn"
          (click)="replayTutorial()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="play-arrow"
          ></app-vficon>
        </button>

        <button
          *ngIf="hasTutorials && tutorialInProgress"
          tooltip="Stop '{{ currentTutorial }}' tutorial"
          class="btn mt-3 reset-tutorials-btn"
          (click)="tutorial.stopTutorial.next(true)"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="stop-circle"
          ></app-vficon>
        </button>

        <button
          tooltip="AI Assistant"
          class="btn mt-3 popup-ai-btn"
          [class.d-none]="isLLMPage"
          (click)="showAI = !showAI"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-tobi-icons"
            iconName="default"
          ></app-vficon>
        </button>

        <button
          [class.invisible]="window.scrollY < 500"
          tooltip="Navigate to the top of the page"
          class="btn mt-3"
          (click)="scrollToTop()"
        >
          <app-vficon
            style="position: relative; bottom: 3px"
            iconPack="source-system-icons"
            iconName="arrow-up"
          ></app-vficon>
        </button>
      </ul>
    </ng-container>

    <div class="container-fluid d-inline-block p-0 {{ isLoggedIn ? 'min-vh-100' : '' }}">
      <router-outlet></router-outlet>
      <app-user-feedback></app-user-feedback>
    </div>
    <app-footer class="footer-container {{ isLoggedIn ? 'mb-n5' : '' }}"></app-footer>
  </div>
</div>

<div class="overlay-loader" [class.d-none]="!showLoad">
  <div class="logo">
    <img [priority]="true" ngSrc="/assets/images/spinner_logo.svg" alt="overlay-spinner" height="460" width="460" />
  </div>
</div>
