export const URL_REGEX =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
export const EMPTY_VALS: string[] = ["", "--", "na", "unknown", "n/a", "NA", "N/A", "0"];
export const REGEX_QUOTE = /"/g;
export const REGEX_POINTLESS_NAME = /(([-_a-zA-Z0-9]{2,}) ?)+([Nn]ame)$/g;
export const REGEX_ID_CASE = /(([-_a-zA-Z0-9]{2,}) ?)+([iI]d)$/g;
export const REGEX_CAMEL_CASE = /([a-z])([A-Z])/g;
export const WHITESPACE_REGEX = /\s+/g;
export const PARENTHESIS_REGEX = /[()]/g;
export const AMPERSAND_REGEX = /&/g;

export const transformLegacySectionName = (s: string): string => {
  return (s || "")
    .replace(WHITESPACE_REGEX, "-")
    .replace(PARENTHESIS_REGEX, "")
    .replace(AMPERSAND_REGEX, "and")
    .toLowerCase();
};

export const transformLegacyPageName = (s: string): string => {
  return (s || "").replace(WHITESPACE_REGEX, "-").replace(PARENTHESIS_REGEX, "").toLowerCase();
};
