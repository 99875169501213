import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CloudProvider, GroupByApiResult, GroupByPayload } from "../../../shared/models/vcloud-api";
import { InteractiveChartSectionComponent } from "../../../shared/component/interactive-chart-section/interactive-chart-section.component";
import { GoogleChartsModule } from "angular-google-charts";
import { ChartOptions } from "../../../shared/models/google-chart";
import { catchError, map, mergeMap, retry, tap } from "rxjs/operators";
import { LookerStudioService } from "../../../shared/services/looker-studio.service";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { CommonTableComponent } from "../../../shared/component/common-table/common-table.component";
import { LoggerService } from "../../../shared/services/logger.service";
import { VFIconComponent } from "../../../shared/component/vficon/vficon.component";
import { forkJoin, interval, Observable, of, Subscription } from "rxjs";
import { RouterLinkWithHref } from "@angular/router";
import { AuthenticationService } from "../../../shared/services/authentication.service";
import { AuthorisationService } from "../../../shared/services/authorisation.service";
import {
  AI_NAME,
  CLOUD_PROVIDERS,
  CONFIG_ATTRS,
  DEFAULT_QUESTIONS,
  DOMAINS,
  GREETING,
  INTERPRET_DATA_QUERY,
  MODEL_CHEAP,
  MODEL_EXPENSIVE,
  N_DATA_SAVED,
  N_QUESTIONS,
  N_SUGGESTED_REPORTS,
  RACE_N_DATA_QUERY,
  REGEX_DEFINITION,
  SAVE_EVERY_INTERVAL,
  TOOLTIPS_FOR_WHO,
} from "../../../shared/constant/llm";
import {
  ChatbotAnswer,
  GeneratedPayload,
  LLModel,
  LLMQueryType,
  LLMResponse,
  QueryDomain,
  QueryTypeAnalysis,
  Response,
  ResponseType,
  SelectedTab,
  SuggestedURL,
  UserQuestion,
  VcsApiResponse,
  ViewType,
  VisibleResponse,
} from "../../../shared/models/llm";
import { LookerStudioNavigationComponent } from "../../../common/looker-studio-navigation/looker-studio-navigation.component";
import { PrettifyAttributePipe } from "../../../shared/pipes/prettify-attribute.pipe";
import { Csp } from "../../../shared/models/vcloudsmart";
import { LLMService } from "../../../shared/services/llm.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { TableColumn, TableRow } from "../../../shared/models/common-table";
import { PcsApiService } from "../../../shared/services/pcs-api.service";
import { UserPermissions } from "../../../shared/models/tenancies";
import { MAX_API_RESPONSE_LIMIT, ROWS_PER_PAGE } from "../../../shared/constant/vcloud-api";
import { VCloudSmartApiService } from "../../../shared/services/v-cloud-smart-api.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { RatingModule } from "ngx-bootstrap/rating";

interface BestCandidate {
  requestBody: GroupByPayload;
  response: GroupByApiResult;
  messageIds: string[];
  alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[];
}

@Component({
  standalone: true,
  selector: "app-llm",
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InteractiveChartSectionComponent,
    GoogleChartsModule,
    TooltipModule,
    CommonTableComponent,
    VFIconComponent,
    RouterLinkWithHref,
    LookerStudioNavigationComponent,
    PrettifyAttributePipe,
    RatingModule,
  ],
  templateUrl: "./llm.component.html",
  styleUrls: ["./llm.component.scss"],
})
export class LLMComponent implements OnInit, OnDestroy {
  @Input() sidebar = false;
  @Output() closeLLM = new EventEmitter<boolean>();

  protected readonly MODEL_EXPENSIVE = MODEL_EXPENSIVE;
  protected readonly TOOLTIPS = TOOLTIPS_FOR_WHO;
  protected readonly AI_NAME = AI_NAME;
  protected readonly decodeURIComponent = decodeURIComponent;
  protected readonly MAX_API_RESPONSE_LIMIT = MAX_API_RESPONSE_LIMIT;
  protected readonly Math = Math;
  protected readonly ROWS_PER_PAGE = ROWS_PER_PAGE;

  public rating: number | undefined = undefined;
  public ratingFeedback: string = "";
  public ratingMessageId: string;
  public ratingApplied = false;

  public userPermsDescription: string = "";

  public conversationId = this.pcsApi.uuidv4();

  private responsesCache: Response[];
  private exampleQuestionsCache: string[];
  private suggestedURLsCache: SuggestedURL[];

  private subscriptions: Subscription[] = [];
  private subscriptionsOther: Subscription[] = [];

  public viewType: ViewType = "default";
  public widthRatio = 1;
  public loading: "" | "user_info" | "analyse_query" | ResponseType = "";

  public readonly configureForm: FormGroup = new FormGroup({
    role: new FormControl("", []),
    goals: new FormControl("", []),
    application: new FormControl("", []),
    instructions: new FormControl("", []),
    cloudProvider: new FormControl("", []),
  });

  private vcsConfig: Csp[] = [];
  private userPerms: UserPermissions = undefined;

  public ratingModalRef?: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private llm: LLMService,
    private clipboard: Clipboard,
    private pcsApi: PcsApiService,
    private vcs: VCloudSmartApiService,
    public authorisation: AuthorisationService,
    private authentication: AuthenticationService,
    public looker: LookerStudioService,
    public log: LoggerService
  ) {}

  public get columnChartOptions(): ChartOptions {
    return this.llm?.columnChartOptions ?? ({} as ChartOptions);
  }

  public get pieChartOptions(): ChartOptions {
    return this.llm?.pieChartOptions ?? ({} as ChartOptions);
  }

  public get suggestedURLs(): SuggestedURL[] {
    if (this.suggestedURLsCache !== undefined) {
      return this.suggestedURLsCache;
    }
    const item = localStorage.getItem("llmSuggestedURLs");
    if (item) {
      try {
        this.suggestedURLsCache = JSON.parse(item);
      } catch (e) {
        localStorage.removeItem("llmSuggestedURLs");
        this.suggestedURLsCache = [];
      }
    } else {
      this.suggestedURLsCache = [];
    }
    return this.suggestedURLsCache;
  }

  public set suggestedURLs(v: SuggestedURL[]) {
    this.suggestedURLsCache = v;
    localStorage.setItem("llmSuggestedURLs", JSON.stringify(v));
  }

  public get userQuery(): string {
    return localStorage.getItem("llmUserQuery") ?? "";
  }

  public set userQuery(v: string) {
    localStorage.setItem("llmUserQuery", v);
  }

  public get responses(): Response[] {
    if (this.responsesCache !== undefined) {
      return this.responsesCache;
    }
    const item = localStorage.getItem("llmResponses");
    if (item) {
      try {
        this.responsesCache = JSON.parse(item);
      } catch (e) {
        localStorage.removeItem("llmResponses");
        this.responsesCache = JSON.parse(JSON.stringify(GREETING));
      }
    } else {
      this.responsesCache = JSON.parse(JSON.stringify(GREETING));
    }
    return this.responsesCache;
  }

  public set responses(v: Response[]) {
    this.responsesCache = v;
    setTimeout(() => {
      const shortened = (JSON.parse(JSON.stringify(v)) as Response[])
        .map((r) => {
          if (r.who === "User" && r.type === "text") {
            if ((r as UserQuestion)?.alternativeRequestBodies) {
              (r as UserQuestion).alternativeRequestBodies = [];
            }
          }

          if (r.who === "VCloudSmart API" && r.type === "api_data") {
            if ((r as VcsApiResponse)?.columnChartOptions) {
              delete (r as VcsApiResponse).columnChartOptions;
            }

            if ((r as VcsApiResponse)?.pieChartOptions) {
              delete (r as VcsApiResponse).pieChartOptions;
            }

            if (((r as VcsApiResponse)?.tableRows?.length ?? 0) > N_DATA_SAVED) {
              (r as VcsApiResponse).tableRows = (r as VcsApiResponse).tableRows.slice(0, N_DATA_SAVED);
            }
          }

          return r;
        })
        .slice(-N_DATA_SAVED);
      localStorage.setItem("llmResponses", JSON.stringify(shortened));
    }, 500);
  }

  public set exampleQuestions(v: string[]) {
    this.exampleQuestionsCache = v;
    localStorage.setItem("llmExampleQuestions", JSON.stringify(v));
  }

  public get exampleQuestions(): string[] {
    if (this.exampleQuestionsCache !== undefined) {
      return this.exampleQuestionsCache;
    }
    const item = localStorage.getItem("llmExampleQuestions");
    if (item) {
      try {
        if (this.vcsConfig.length > 0) {
          this.exampleQuestionsCache = JSON.parse(item);
          if (!this.exampleQuestionsCache || this?.exampleQuestionsCache?.length === 0) {
            localStorage.removeItem("llmExampleQuestions");
            this.exampleQuestionsCache = JSON.parse(JSON.stringify(DEFAULT_QUESTIONS));
          }
        } else {
          this.exampleQuestionsCache = JSON.parse(item);
        }
      } catch (e) {
        localStorage.removeItem("llmExampleQuestions");
        this.exampleQuestionsCache = JSON.parse(JSON.stringify(DEFAULT_QUESTIONS));
      }
    } else {
      this.exampleQuestionsCache = JSON.parse(JSON.stringify(DEFAULT_QUESTIONS));
    }
    return this.exampleQuestionsCache;
  }

  public ngOnInit() {
    this.loading = "user_info";
    this.subscriptionsOther.push(
      this.authorisation.describeUserPerms().subscribe((s) => {
        this.userPermsDescription = s;
      })
    );

    this.subscriptionsOther.push(
      this.authorisation
        .getUserPermissions({
          resourcePrefix: "/cloud-providers/",
          attributes: ["permissions", "groups", "tenancies"],
          permTypes: ["Read"],
        })
        .pipe(
          tap((r) => {
            this.userPerms = r;
          }),
          map((user) => this.looker.getVCSConfig(this.authentication.tenant, user))
        )
        .subscribe(
          (config) => {
            this.vcsConfig = config;
            this.loading = "";
          },
          (e) => {
            this.log.error(e);
            this.vcsConfig = [];
            this.loading = "";
          }
        )
    );

    setTimeout(() => {
      this.injectClickableDefinitions();
    }, 1500);

    setTimeout(() => {
      this.llm.focusLastMessage();
    }, 2000);

    // FIXME
    // this.subscriptionsOther.push(
    //   this.events.currency.subscribe((c) => {
    //     this.vcs.clearCapturedResponses();
    //   })
    // );
    //
    // this.subscriptionsOther.push(
    //   this.events.azureCurrency.subscribe(() => {
    //     this.vcs.clearCapturedResponses();
    //   })
    // );
    //
    // this.subscriptionsOther.push(
    //   this.events.drccCurrency.subscribe(() => {
    //     this.vcs.clearCapturedResponses();
    //   })
    // );

    this.subscriptionsOther.push(
      interval(SAVE_EVERY_INTERVAL).subscribe(() => {
        // save in localStorage
        this.responses = this.responsesCache;
        this.suggestedURLs = this.suggestedURLsCache;
        this.exampleQuestions = this.exampleQuestionsCache;
      })
    );
  }

  public ngOnDestroy() {
    this.clearSubscriptions();
    this.subscriptionsOther.forEach((s) => s.unsubscribe());
  }

  public onTextareaInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height based on scrollHeight
  }

  public onTextareaKeydown(event: KeyboardEvent, query: string): void {
    if ((event?.key || "").toLowerCase() === "enter" && !event?.shiftKey && !this.loading && this.userQuery.trim()) {
      this.submitUserQuery(query, MODEL_EXPENSIVE);
    }
  }

  public submitRating(messageId: string, rating: number, feedback?: string): void {
    this.ratingModalRef?.hide();
    this.vcs.submitLLMRating(messageId, rating, feedback).subscribe(
      () => {
        this.rating = undefined;
        this.ratingApplied = false;
        this.ratingFeedback = "";
        this.ratingMessageId = "";
        (
          (this.responses ?? []).find((r) => (r as VisibleResponse)?.messageId === messageId) as VisibleResponse
        ).ratingProvided = true;
      },
      (e) => {
        this.log.error(e);
      }
    );
  }

  private injectClickableDefinitions(): void {
    document
      .querySelectorAll(".custom-card.message-card .generatedHtml u:not(.processed)")
      .forEach((u: HTMLElement) => {
        const text = u.innerText.trim();
        if (REGEX_DEFINITION.test(text)) {
          u.classList.add("processed");
          u.classList.add("clickable");
          u.onclick = () => {
            if (!this.loading) {
              const idx = this.responses.length;
              this.loading = "text";
              const query = `Elaborate on, define and explain "${text}".`;
              const newInteraction: UserQuestion = {
                who: "User",
                message: query,
                type: "text",
                queryTypes: ["casual_interactions"] as LLMQueryType[],
                domains: [],
                cloudProviders: [],
              };
              this.responses = this.responses.slice(0, idx).concat([newInteraction]).concat(this.responses.slice(idx));
              this.subscriptions.push(
                this.llm
                  .analyseQuery(this.responses, query, this.conversationId, this.userPerms)
                  .pipe(
                    mergeMap((queryDetails: QueryTypeAnalysis) => {
                      newInteraction.domains = queryDetails?.Domains ?? newInteraction.domains ?? [];
                      newInteraction.cloudProviders =
                        queryDetails?.CloudProviders ?? newInteraction.cloudProviders ?? [];
                      newInteraction.queryTypes = queryDetails?.QueryCategories ?? newInteraction.queryTypes ?? [];
                      return this.submitUserQueryCasualInteraction(
                        query,
                        MODEL_EXPENSIVE,
                        queryDetails.CloudProviders,
                        queryDetails.Domains
                      );
                    }),
                    retry(2),
                    catchError((e) => {
                      this.log.error(e);
                      const errorMsg = `I'm sorry, I haven't been able to find any information on "${text}".`;
                      this.responses = this.responses
                        .slice(0, idx)
                        .concat([
                          {
                            message: errorMsg,
                            type: "text",
                            who: AI_NAME,
                          } as ChatbotAnswer,
                        ])
                        .concat(this.responses.slice(idx));
                      return of();
                    })
                  )
                  .subscribe(
                    () => {
                      this.loading = "";
                    },
                    (e) => {
                      this.log.error(e);
                      this.loading = "";
                    }
                  )
              );
            }
          };
        }
      });
  }

  private getBestCandidate(
    query: string,
    model: LLModel,
    nCandidates: number,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[]
  ): Observable<BestCandidate> {
    return this.llm
      .getCandidateApiPayloadsInParallelOrderedByBest(
        this.responses,
        query,
        model,
        nCandidates,
        cloudProviders,
        domains,
        this.conversationId,
        this.userPerms
      )
      .pipe(
        mergeMap((payloads: { payload: GroupByPayload; messageIds: string[] }[]) => {
          if (payloads.length === 0) {
            throw new Error("Could not generate payload");
          }
          this.loading = "api_data";
          const requestBody: { payload: GroupByPayload; messageIds: string[] } = payloads[0];
          const alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[] = payloads.slice(1);
          return this.llm.getFirstSuccessfulCandidateApiPayload(requestBody, alternativeRequestBodies);
        }),
        tap(({ response, requestBody }) => {
          this.formatDates(requestBody.payload, response);
        }),
        map(({ requestBody, response, alternativeRequestBodies }) => ({
          requestBody: requestBody.payload,
          messageIds: requestBody.messageIds,
          alternativeRequestBodies,
          response: this.llm.convertCurrencyInResponse(requestBody.payload, response, domains),
        }))
      );
  }

  private formatDates(payload: GroupByPayload, response: GroupByApiResult): void {
    try {
      for (let aIdx = 0; aIdx < (payload?.Projection ?? []).length; aIdx++) {
        const a = (payload?.Projection ?? [])[aIdx];
        if (!a) {
          continue;
        }
        if (a.Operation !== "DATE_TRUNC") {
          continue;
        }
        for (const row of response?.Results ?? []) {
          if (typeof row.Value[aIdx] === "string" && (row.Value[aIdx] as string).includes("T")) {
            row.Value[aIdx] = row.Value[aIdx].split("T")[0];
          }
        }
      }
    } catch (e) {
      this.log.error(e);
    }
  }

  private analyseQuery(query: string): Observable<QueryTypeAnalysis> {
    this.loading = "analyse_query";
    return this.llm.analyseQuery(this.responses, query, this.conversationId, this.userPerms);
  }

  private validateTrialDataQuery(queryDetails: QueryTypeAnalysis, idx: number): void {
    if (
      this.authentication.tenant === "trial" &&
      queryDetails.QueryCategories.includes("api_payload") &&
      queryDetails.CloudProviders.some((csp) => csp !== "aws")
    ) {
      const errorMsg = `The ${this.authentication.tenant} tenant only supports AWS related data queries.`;
      const error = new Error(errorMsg);
      this.responses = this.responses
        .slice(0, idx)
        .concat([
          {
            message: errorMsg,
            type: "text",
            who: AI_NAME,
          } as ChatbotAnswer,
        ])
        .concat(this.responses.slice(idx));
      this.loading = "";
      throw error;
    }
  }

  public submitUserQuery(query: string, preferredModel: LLModel): void {
    query = (query ?? "").trim();

    if (this.loading || !query) {
      return;
    }

    if (query.includes("<")) {
      query = this.llm.stripHTML(this.llm.convertHTMLToMarkdown(query));
    }

    const idx = this.responses.length;
    const newInteraction: UserQuestion = {
      who: "User",
      message: this.llm.convertMarkdownToHTML(query),
      type: "text",
      domains: [],
      cloudProviders: [],
      queryTypes: [],
    };

    this.responses = this.responses.slice(0, idx).concat([newInteraction]).concat(this.responses.slice(idx));
    this.clearSubscriptions();

    this.subscriptions.push(
      this.analyseQuery(query)
        .pipe(
          mergeMap((queryDetails: QueryTypeAnalysis) => {
            this.validateTrialDataQuery(queryDetails, idx + 1);
            // this.validateCrossCspQuery(queryDetails, idx + 1);

            newInteraction.cloudProviders = queryDetails?.CloudProviders ?? [];
            newInteraction.domains = queryDetails?.Domains ?? [];

            if (newInteraction.cloudProviders.includes("other" as CloudProvider)) {
              newInteraction.queryTypes = ["api_payload"];
            } else {
              newInteraction.queryTypes = queryDetails?.QueryCategories ?? [];
            }

            const clearTextBox = tap(() => {
              this.loading = "";
              this.userQuery = "";
            });

            const fetchExtras = mergeMap(() => {
              return forkJoin([
                this.submitPotentialQuestions(newInteraction.cloudProviders, newInteraction.domains),
                this.submitSuggestedReports(newInteraction.cloudProviders, newInteraction.domains),
              ]);
            });

            if (queryDetails.QueryCategories.includes("api_payload")) {
              this.loading = "generated_api_payload";
              return this.submitUserQueryApiPayload(
                query,
                MODEL_CHEAP,
                this.getCandidateCount(queryDetails?.Domains ?? []),
                newInteraction.cloudProviders,
                newInteraction.domains
              ).pipe(
                clearTextBox,
                tap(({ alternativeRequestBodies }) => {
                  newInteraction.alternativeRequestBodies = alternativeRequestBodies;
                }),
                mergeMap(() => {
                  if (!newInteraction.cloudProviders.includes("other" as CloudProvider)) {
                    this.setAnalysingData(true);
                    return this.submitUserQueryCasualInteraction(
                      INTERPRET_DATA_QUERY,
                      preferredModel,
                      newInteraction.cloudProviders,
                      newInteraction.domains
                    );
                  } else {
                    return of(null);
                  }
                }),
                fetchExtras,
                catchError((e) => {
                  this.log.error(e);
                  this.setAnalysingData(false);
                  newInteraction.queryTypes = ["casual_interactions"];
                  return this.submitUserQueryCasualInteraction(
                    query,
                    preferredModel,
                    newInteraction.cloudProviders,
                    newInteraction.domains
                  ).pipe(clearTextBox, fetchExtras);
                })
              );
            } else {
              this.loading = "text";
              return this.submitUserQueryCasualInteraction(
                query,
                preferredModel,
                newInteraction.cloudProviders,
                newInteraction.domains
              ).pipe(clearTextBox, fetchExtras);
            }
          })
        )
        .subscribe(
          () => {},
          (e) => {
            this.setAnalysingData(false);
            this.log.error(e);
            this.loading = "";
          }
        )
    );
  }

  private setAnalysingData(v: boolean): void {
    if (v) {
      const o = [...this.responses].reverse().find((r) => r.type === "api_data") as VcsApiResponse;
      if (o) {
        o.analysing = v;
      }
    } else {
      for (const r of [...this.responses].reverse().filter((r) => r.type === "api_data") as VcsApiResponse[]) {
        if (r.analysing !== undefined) {
          delete r.analysing;
        }
      }
    }
  }

  public drillDown(event: { selection: Array<{ row: number }> }, r: VcsApiResponse): void {
    if (this.loading) {
      return;
    }
    try {
      const rows = r?.chartRows ?? [];
      const rowIdx = (event?.selection ?? [])[0]?.row;

      if (rowIdx === undefined) {
        return;
      }

      const val = (rows[rowIdx] ?? [])[0] as unknown as { f: string; v: string } | string;

      if (val === undefined) {
        return;
      }

      const col = (val as { f: string; v: string })?.f ?? (val as { f: string; v: string })?.v ?? val;

      if (!col) {
        return;
      }

      this.submitUserQuery(`Filter on "${col}" and drill down.`, MODEL_EXPENSIVE);
    } catch (e) {
      this.log.error(e);
    }
  }

  private submitSuggestedReports(cloudProviders: CloudProvider[], domains: QueryDomain[]): Observable<SuggestedURL[]> {
    return this.llm
      .getSuggestedReports(this.responses, this.vcsConfig, cloudProviders, domains, this.conversationId, this.userPerms)
      .pipe(
        tap((suggestedReports) => {
          this.suggestedURLs = suggestedReports.slice(0, N_SUGGESTED_REPORTS);
        })
      );
  }

  private submitUserQueryCasualInteraction(
    query: string,
    model: LLModel,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[]
  ): Observable<LLMResponse> {
    const idx = this.responses.length;
    return this.llm
      .handleCasualInteraction(
        this.responses,
        query,
        model,
        cloudProviders,
        domains,
        this.conversationId,
        this.userPerms
      )
      .pipe(
        tap((casual) => {
          const newInteraction: ChatbotAnswer = {
            messageId: casual.MessageId,
            message: casual.Response,
            type: "text",
            who: AI_NAME,
          };
          this.responses = this.responses.slice(0, idx).concat([newInteraction]).concat(this.responses.slice(idx));
          this.setAnalysingData(false);
          setTimeout(() => {
            this.llm.focusLastMessage();
            this.injectClickableDefinitions();
          }, 2000);
        })
      );
  }

  private submitPotentialQuestions(cloudProviders: CloudProvider[], domains: QueryDomain[]): Observable<string[]> {
    return this.llm
      .getSuggestedQuestions(this.responses, cloudProviders, domains, this.conversationId, this.userPerms)
      .pipe(
        tap((questions: string[]): void => {
          this.exampleQuestions = questions.slice(0, N_QUESTIONS);
        })
      );
  }

  private submitUserQueryApiPayload(
    query: string,
    model: LLModel,
    raceN: number,
    cloudProviders: CloudProvider[],
    domains: QueryDomain[]
  ): Observable<{
    requestBody: GroupByPayload;
    alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[];
    response: GroupByApiResult;
  }> {
    const idx = this.responses.length;
    return this.getBestCandidate(query, model, raceN, cloudProviders, domains).pipe(
      tap(({ response, requestBody, messageIds }: BestCandidate): void => {
        const newInteraction: GeneratedPayload = {
          payload: requestBody,
          type: "generated_api_payload",
          who: AI_NAME,
        };
        this.responses = this.responses
          .slice(0, idx)
          .concat([
            newInteraction,
            this.llm.fmtApiResponse(requestBody, response, domains, this.sidebar, messageIds[0]),
          ])
          .concat(this.responses.slice(idx));
        this.llm.focusLastMessage();
      })
    );
  }

  public retryUserQuery(idx: number, userQuery: UserQuestion): void {
    if (this.loading) {
      return;
    }

    const clearLoading = tap(() => (this.loading = ""));

    const fetchExtras = mergeMap(() => {
      return forkJoin([
        this.submitPotentialQuestions(userQuery.cloudProviders, userQuery.domains),
        this.submitSuggestedReports(userQuery.cloudProviders, userQuery.domains),
      ]);
    });

    const message = userQuery.message.includes("<")
      ? this.llm.stripHTML(this.llm.convertHTMLToMarkdown(userQuery.message))
      : userQuery.message;

    this.clearSubscriptions();

    const casualInteraction = this.submitUserQueryCasualInteraction(
      message,
      MODEL_EXPENSIVE,
      userQuery.cloudProviders,
      userQuery.domains
    ).pipe(clearLoading, fetchExtras);

    if (
      userQuery.queryTypes.includes("api_payload") &&
      (userQuery?.alternativeRequestBodies?.length ?? 0) > 0 &&
      (userQuery?.domains?.length ?? 0) > 0 &&
      (userQuery?.cloudProviders?.length ?? 0) > 0
    ) {
      // clear all but include the user query
      this.responses = this.responses.slice(0, idx + 1);
      this.loading = "api_data";
      this.subscriptions.push(
        this.llm
          .getFirstSuccessfulCandidateApiPayload(
            userQuery.alternativeRequestBodies[0],
            userQuery.alternativeRequestBodies.slice(1)
          )
          .pipe(
            map(
              ({
                requestBody,
                response,
                alternativeRequestBodies,
              }): {
                requestBody: { payload: GroupByPayload; messageIds: string[] };
                response: GroupByApiResult;
                alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[];
              } => ({
                requestBody,
                alternativeRequestBodies,
                response: this.llm.convertCurrencyInResponse(requestBody.payload, response, userQuery.domains),
              })
            ),
            tap(
              ({
                response,
                requestBody,
                alternativeRequestBodies,
              }: {
                requestBody: { payload: GroupByPayload; messageIds: string[] };
                response: GroupByApiResult;
                alternativeRequestBodies: { payload: GroupByPayload; messageIds: string[] }[];
              }): void => {
                userQuery.alternativeRequestBodies = alternativeRequestBodies;
                const newInteraction: GeneratedPayload = {
                  payload: requestBody.payload,
                  type: "generated_api_payload",
                  who: AI_NAME,
                };
                this.responses = this.responses
                  .slice(0, idx + 1)
                  .concat([
                    newInteraction,
                    this.llm.fmtApiResponse(
                      requestBody.payload,
                      response,
                      userQuery.domains,
                      this.sidebar,
                      requestBody.messageIds[0]
                    ),
                  ])
                  .concat(this.responses.slice(idx + 1));
              }
            ),
            clearLoading,
            mergeMap(() => {
              if (userQuery.cloudProviders.includes("other" as CloudProvider)) {
                return of(null);
              } else {
                this.setAnalysingData(true);
                return this.submitUserQueryCasualInteraction(
                  message,
                  MODEL_EXPENSIVE,
                  userQuery.cloudProviders,
                  userQuery.domains
                );
              }
            }),
            fetchExtras,
            catchError((e) => {
              this.log.error(e);
              this.setAnalysingData(false);
              userQuery.queryTypes = ["casual_interactions"];
              return casualInteraction;
            })
          )
          .subscribe(
            () => {},
            (e) => {
              this.setAnalysingData(false);
              this.log.error(e);
            }
          )
      );
    } else {
      this.responses = this.responses.slice(0, idx);
      this.submitUserQuery(message, MODEL_EXPENSIVE);
    }
  }

  public shouldDisplayTable(r: VcsApiResponse): boolean {
    return this.llm.shouldDisplayTable(r);
  }

  public canDisplayColumnChart(r: VcsApiResponse): boolean {
    return (r?.tabs ?? []).includes("Column Chart");
  }

  public canDisplayTable(r: VcsApiResponse): boolean {
    return (r?.tabs ?? ["Table", "Column Chart", "Pie Chart", "Payload"]).includes("Table");
  }

  public canDisplayPieChart(r: VcsApiResponse): boolean {
    return (r?.tabs ?? ["Table", "Column Chart", "Pie Chart", "Payload"]).includes("Pie Chart");
  }

  public canDisplayPayload(r: VcsApiResponse): boolean {
    return (r?.tabs ?? ["Table", "Column Chart", "Pie Chart", "Payload"]).includes("Payload");
  }

  public shouldDisplayPayload(r: VcsApiResponse): boolean {
    return this.llm.shouldDisplayPayload(r);
  }

  public shouldDisplayColumnChart(r: VcsApiResponse): boolean {
    return this.llm.shouldDisplayColumnChart(r);
  }

  public shouldDisplayPieChart(r: VcsApiResponse): boolean {
    return this.llm.shouldDisplayPieChart(r);
  }

  public getTableName(r: VcsApiResponse, idx: number): string {
    return this.llm.getTableName(r, idx);
  }

  public clearConversation(): void {
    this.conversationId = this.pcsApi.uuidv4();
    this.clearSubscriptions();
    this.suggestedURLs = [];
    this.responses = GREETING;
    this.exampleQuestions = DEFAULT_QUESTIONS;
    this.loading = "";
    this.llm.focusLastMessage();
    setTimeout(() => {
      this.injectClickableDefinitions();
    }, 1500);
  }

  public removeResponseFromConversation(responseIdx: number): void {
    this.responses = this.responses.slice(0, responseIdx);
  }

  private getCandidateCount(_domains: QueryDomain[]): number {
    // return 5;
    // if (((domains ?? [])?.length ?? 0) === 1) {
    //   if (domains[0] === "recommendations") {
    //     return 4;
    //   }
    //   if (domains[0] === "billing") {
    //     return 3;
    //   }
    // }
    return RACE_N_DATA_QUERY;
  }

  public changeUserQueryCloudProvider(r: UserQuestion, csp: CloudProvider, idx: number): void {
    const queryTypes = r?.queryTypes ?? [];

    const cspIdx = CLOUD_PROVIDERS.indexOf(csp);
    r.cloudProviders = [CLOUD_PROVIDERS[(cspIdx + 1) % CLOUD_PROVIDERS.length]];

    const message = r.message.includes("<") ? this.llm.stripHTML(this.llm.convertHTMLToMarkdown(r.message)) : r.message;

    this.clearSubscriptions();
    this.responses = this.responses.slice(0, idx + 1);

    const extras = mergeMap(() => {
      return forkJoin([
        this.submitPotentialQuestions(r.cloudProviders, r.domains),
        this.submitSuggestedReports(r.cloudProviders, r.domains),
      ]);
    });

    const endLoading = tap(() => {
      this.loading = "";
    });

    if (queryTypes.includes("api_payload")) {
      this.loading = "generated_api_payload";
      this.subscriptions.push(
        this.submitUserQueryApiPayload(
          message,
          MODEL_CHEAP,
          this.getCandidateCount(r?.domains ?? []),
          r.cloudProviders,
          r.domains
        )
          .pipe(
            endLoading,
            tap(({ alternativeRequestBodies }) => {
              r.alternativeRequestBodies = alternativeRequestBodies;
            }),
            mergeMap(() => {
              if (r.cloudProviders.includes("other" as CloudProvider)) {
                return of(null);
              } else {
                this.setAnalysingData(true);
                return this.submitUserQueryCasualInteraction(
                  INTERPRET_DATA_QUERY,
                  MODEL_EXPENSIVE,
                  r.cloudProviders,
                  r.domains
                );
              }
            }),
            extras
          )
          .subscribe(
            () => {},
            (e) => {
              this.setAnalysingData(false);
              this.log.error(e);
            }
          )
      );
    } else {
      this.loading = "text";
      this.subscriptions.push(
        this.submitUserQueryCasualInteraction(message, MODEL_EXPENSIVE, r.cloudProviders, r.domains)
          .pipe(endLoading, extras)
          .subscribe(
            () => {},
            (e) => {
              this.log.error(e);
            }
          )
      );
    }
  }

  public changeUserQueryType(r: UserQuestion, queryType: LLMQueryType, idx: number): void {
    const queryTypes = r?.queryTypes ?? [];

    if (!(queryTypes.includes("api_payload") || queryTypes.includes("casual_interactions"))) {
      return;
    }

    if (queryType === "api_payload") {
      r.queryTypes = ["casual_interactions"];
    } else {
      r.queryTypes = ["api_payload"];
    }

    const message = r.message.includes("<") ? this.llm.stripHTML(this.llm.convertHTMLToMarkdown(r.message)) : r.message;

    this.clearSubscriptions();
    this.responses = this.responses.slice(0, idx + 1);

    const endLoading = tap(() => {
      this.loading = "";
    });

    const extras = mergeMap(() => {
      return forkJoin([
        this.submitPotentialQuestions(r.cloudProviders, r.domains),
        this.submitSuggestedReports(r.cloudProviders, r.domains),
      ]);
    });
    if (r.queryTypes.includes("api_payload")) {
      this.loading = "generated_api_payload";
      this.subscriptions.push(
        this.submitUserQueryApiPayload(
          message,
          MODEL_CHEAP,
          this.getCandidateCount(r?.domains ?? []),
          r.cloudProviders,
          r.domains
        )
          .pipe(
            endLoading,
            tap(({ alternativeRequestBodies }) => {
              r.alternativeRequestBodies = alternativeRequestBodies;
            }),
            mergeMap(() => {
              if (r.cloudProviders.includes("other" as CloudProvider)) {
                return of(null);
              } else {
                this.setAnalysingData(true);
                return this.submitUserQueryCasualInteraction(
                  INTERPRET_DATA_QUERY,
                  MODEL_EXPENSIVE,
                  r.cloudProviders,
                  r.domains
                );
              }
            }),
            extras
          )
          .subscribe(
            () => {},
            (e) => {
              this.log.error(e);
              this.setAnalysingData(false);
            }
          )
      );
    } else {
      this.loading = "text";
      this.subscriptions.push(
        this.submitUserQueryCasualInteraction(message, MODEL_EXPENSIVE, r.cloudProviders, r.domains)
          .pipe(endLoading, extras)
          .subscribe(
            () => {},
            (e) => {
              this.log.error(e);
            }
          )
      );
    }
  }

  private clearSubscriptions(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this.setAnalysingData(false);
  }

  public changeUserQueryDomain(r: UserQuestion, d: QueryDomain, idx: number): void {
    const queryTypes = r?.queryTypes ?? [];

    if (!(queryTypes.includes("api_payload") || queryTypes.includes("casual_interactions"))) {
      return;
    }

    if (d === "billing") {
      r.domains = ["assets"];
    } else if (d === "assets") {
      r.domains = ["recommendations"];
    } else if (d === "recommendations") {
      r.domains = ["billing"];
    } else {
      return;
    }

    this.clearSubscriptions();
    this.responses = this.responses.slice(0, idx + 1);

    const endLoading = tap(() => {
      this.loading = "";
    });

    const extras = mergeMap(() => {
      return forkJoin([
        this.submitPotentialQuestions(r.cloudProviders, r.domains),
        this.submitSuggestedReports(r.cloudProviders, r.domains),
      ]);
    });

    const message = r.message.includes("<") ? this.llm.stripHTML(this.llm.convertHTMLToMarkdown(r.message)) : r.message;

    if (queryTypes.includes("api_payload")) {
      this.loading = "generated_api_payload";
      this.subscriptions.push(
        this.submitUserQueryApiPayload(
          message,
          MODEL_CHEAP,
          this.getCandidateCount(r?.domains ?? []),
          r.cloudProviders,
          r.domains
        )
          .pipe(
            endLoading,
            tap(({ alternativeRequestBodies }) => {
              r.alternativeRequestBodies = alternativeRequestBodies;
            }),
            mergeMap(() => {
              if (r.cloudProviders.includes("other" as CloudProvider)) {
                return of(null);
              } else {
                this.setAnalysingData(true);
                return this.submitUserQueryCasualInteraction(
                  INTERPRET_DATA_QUERY,
                  MODEL_EXPENSIVE,
                  r.cloudProviders,
                  r.domains
                );
              }
            }),
            extras
          )
          .subscribe(
            () => {},
            (e) => {
              this.setAnalysingData(false);
              this.log.error(e);
            }
          )
      );
    } else {
      this.loading = "text";
      this.subscriptions.push(
        this.submitUserQueryCasualInteraction(message, MODEL_EXPENSIVE, r.cloudProviders, r.domains)
          .pipe(endLoading, extras)
          .subscribe(
            () => {},
            (e) => {
              this.log.error(e);
            }
          )
      );
    }
  }

  public switchToUserPreferencesView(): void {
    for (const a of CONFIG_ATTRS) {
      const v = localStorage.getItem(a);
      if (v) {
        this.configureForm.controls[a].setValue(v);
      }
    }
    this.viewType = "configure";
  }

  public submitUserPreferences(): void {
    for (const a of CONFIG_ATTRS) {
      localStorage.setItem(a, this.configureForm?.controls[a]?.value ?? "");
    }
    this.viewType = "default";
  }
  public resetForm(): void {
    this.configureForm.reset();
  }

  public editUserQuery(idx: number, r: UserQuestion): void {
    if (this.loading) {
      return;
    }
    if (r.isEditable) {
      r.isEditable = false;
      r.message = this.llm.convertMarkdownToHTML(r.message);
      if (r.oldMessage !== r.message) {
        this.retryUserQuery(idx, r);
      }
      if (r.oldMessage) {
        delete r.oldMessage;
      }
    } else {
      r.oldMessage = r.message.trim();
      r.message = this.llm.convertHTMLToMarkdown(r.oldMessage).trim();
      r.isEditable = true;
    }
  }

  public copyHtmlText(message: string): void {
    return this.llm.copyHtmlTextAsMarkdown(message);
  }

  public retryPotentialQuestions(): void {
    const cloudProviders =
      (this.responses.find((r) => ((r as UserQuestion)?.cloudProviders?.length ?? 0) > 0) as UserQuestion)
        ?.cloudProviders ?? CLOUD_PROVIDERS;
    const domains =
      (this.responses.find((r) => ((r as UserQuestion)?.domains?.length ?? 0) > 0) as UserQuestion)?.domains ?? DOMAINS;
    this.subscriptions.push(
      this.submitPotentialQuestions(cloudProviders, domains).subscribe(
        () => {},
        (e) => {
          this.log.error(e);
        }
      )
    );
  }

  protected readonly JSON = JSON;

  public copyPayload(payload: GroupByPayload): void {
    this.clipboard.copy(JSON.stringify(payload ?? {}, null, 2));
  }

  public copy(r: Response): void {
    if (r?.type === "api_data") {
      if ((r as VcsApiResponse)?.selectedTab === "Payload") {
        this.copyPayload((r as VcsApiResponse)?.payload);
      } else {
        this.copyData((r as VcsApiResponse)?.tableColumns, (r as VcsApiResponse)?.tableRows);
      }
    } else {
      this.copyHtmlText((r as UserQuestion | ChatbotAnswer)?.message);
    }
  }

  private copyData(tableColumns: TableColumn[], tableRows: TableRow[]): void {
    const csv = [(tableColumns ?? []).map((c) => c?.title ?? c?.label ?? "").join("\t")];

    for (const r of tableRows ?? []) {
      csv.push(r.map((c) => (c?.rawValue ?? c?.value ?? "").toString()).join("\t"));
    }

    this.clipboard.copy(csv.join("\n"));
  }

  protected readonly localStorage = localStorage;

  public handleChartError(r: VcsApiResponse) {
    if (this.shouldDisplayColumnChart(r)) {
      r.tabs = (r.tabs ?? ["Table", "Column Chart", "Pie Chart", "Payload"]).filter((t) => t !== "Column Chart");
      r.selectedTab = "Table";
    } else if (this.shouldDisplayPieChart(r)) {
      r.tabs = (r.tabs ?? ["Table", "Column Chart", "Pie Chart", "Payload"]).filter((t) => t !== "Pie Chart");
      r.selectedTab = "Column Chart";
    }
  }

  public handleSelectTab(r: VcsApiResponse, tab: SelectedTab): void {
    r.selectedTab = tab;
    if (tab !== "Payload") {
      localStorage.setItem("llmPreferredTab", tab);
    }
  }

  public fetchNextPage(r: VcsApiResponse): void {
    const clone = JSON.parse(JSON.stringify(r.payload));
    clone.Limit = clone.Limit ?? MAX_API_RESPONSE_LIMIT;
    clone.Offset = (clone.Offset ?? 0) + clone.Limit;
    this.loading = "api_data";
    this.vcs.groupBy(clone).subscribe((response) => {
      const formattedResponse = this.llm.fmtApiResponse(clone, response, DOMAINS, this.sidebar, r.messageId);
      r.tableRows = r.tableRows.concat(formattedResponse?.tableRows ?? []);
      r.chartRows = r.chartRows.concat(formattedResponse?.chartRows ?? []);
      r.payload = clone;
      this.loading = "";
    });
  }

  public triggerRatingPopUp(ratingTemplate: TemplateRef<void>, messageId: string): void {
    this.ratingMessageId = messageId;
    this.ratingModalRef = this.modalService.show(ratingTemplate, { ignoreBackdropClick: true, class: "modal-lg" });
  }

  public closeRatingPopUp(): void {
    this.ratingMessageId = "";
    this.ratingApplied = false;
    this.ratingModalRef?.hide();
  }
}
