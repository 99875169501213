<div class="user-feedback">
  <div class="user-feedback-container">
    <div
      class="user-feedback-box py-2 px-4 mb-2"
      [@slideInOut]="events.userFeedbackState.value.open ? 'open' : 'closed'"
    >
      <h2 class="mr-2 subscribed-text" *ngIf="feedbackGiven">
        <b>Thank you</b> for taking the time to give us your opinion
      </h2>

      <app-vficon
        size="20px"
        class="close-icon float-right"
        (click)="closeFeedback()"
        iconPack="source-system-icons"
        iconName="close"
      ></app-vficon>
      <div *ngIf="!feedbackGiven">
        <h3 class="font-weight-bold mt-3 d-flex align-items-center">
          <app-vficon
            *ngIf="!allowContact"
            size="20px"
            class="info-icon mr-2"
            tooltip="Data is anonymised"
            iconPack="source-system-icons"
            iconName="info-circle"
          ></app-vficon>
          Please rate your experience
        </h3>
        <div class="user-feedback-questions">
          <h5 class="mb-0">{{ feedbackQuestion.title }}</h5>
          <div class="start-container">
            <rating
              class="rating-stars mb-0"
              [(ngModel)]="feedbackQuestion.rate"
              (ngModelChange)="updateFeedbackRate($event)"
              [max]="numberOfStars"
              [readonly]="false"
            >
            </rating>
            <div class="rating-scale mt-1">
              <span>not likely</span>
              <span>extremely likely</span>
            </div>
          </div>
        </div>
        <textarea
          placeholder="Type your feedback here... (optional)"
          class="d-block w-100 mt-1"
          [(ngModel)]="feedbackText"
          cols="30"
          maxlength="100000"
          rows="3"
        ></textarea>

        <div class="d-flex flex-row justify-content-between mt-4">
          <div
            tooltip="Allow the developers to store your email and potentially reach out to you in case they have any questions or to notify that something has been fixed following your feedback."
          >
            <label for="">Contact Me?</label>
            <input [(ngModel)]="allowContact" type="checkbox" class="ml-1 position-relative" style="top: 2px" />
          </div>

          <button
            class="btn btn-primary d-flex flex-row justify-content-between"
            [disabled]="!modified || (feedbackText || '').length > 100000 || !feedbackQuestion.rate"
            (click)="sendFeedback(allowContact)"
          >
            <span>Send</span>
            <app-vficon
              size="20px"
              class="info-icon ml-2"
              iconPack="source-system-icons"
              iconName="send-tobi"
            ></app-vficon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
