import { environment } from "../../../environments/environment";
import { VALID_PAGE_SIZES } from "./pagination";

const MAX_PAGE_SIZE = VALID_PAGE_SIZES.reduce((a, b) => Math.max(a, b), 0);

export const MAX_API_RESPONSE_LIMIT = 100_000;
export const ROWS_PER_PAGE = environment.production ? 25_000 - MAX_PAGE_SIZE : 5_000 - MAX_PAGE_SIZE;

export const OPERATOR_SCORES = {
  "=": 1,
  IS: 1,
  "IS NOT": 2,
  "!=": 2,
  "<>": 2,
  ">=": 3,
  ">": 3,
  "<=": 4,
  "<": 4,
  LIKE: 10,
  "NOT LIKE": 11,
};
export const REGEX_VCS_PAGE_URL =
  /^\/vcloudsmart\/(?<version>v\d+)\/(?<csp>[^/]+)\/(?<section>[^/]+)\/(?<page>[^/]+)(\/(?<tab>[^/]+))?.*$/;
