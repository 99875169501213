import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd, RouterModule, Params } from "@angular/router";
import { VFIconComponent } from "../../shared/component/vficon/vficon.component";
import { CommonModule } from "@angular/common";
import { filter, map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { LoggerService } from "../../shared/services/logger.service";

interface BreadCrumb {
  url: string;
  text: string;
  fragment: string;
  queryParams: { [key: string]: string };
}

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, VFIconComponent],
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.html",
  styleUrls: ["./breadcrumbs.scss"],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  public breadcrumbs: BreadCrumb[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private log: LoggerService) {}

  public static isBreadCrumb(obj: { [key: string]: any }): obj is BreadCrumb {
    return "url" in obj && "text" in obj;
  }

  public generateBreadcrumbData(routeData: { [key: string]: any }, routeParams: { [key: string]: any }): BreadCrumb[] {
    // reset breadcrumbs on re-render
    const generatedBreadcrumbs = [];

    // check and replace route daynamic params in the data object(described in routes)

    for (const routeDatakey in routeData) {
      // generatedBreadcrumb - contains the object with current route parmas
      let generatedBreadcrumb = routeData[routeDatakey];

      if (!BreadcrumbsComponent.isBreadCrumb(generatedBreadcrumb)) {
        continue;
      }

      // push and proccess the data if the object is of type BreadCrumb
      for (const routeParamsKey in routeParams) {
        // uniqueKey - concatenate the routeParamsKey with unique params identifier ":"
        const uniqueKey = ":".concat(routeParamsKey);

        // replace data daynamic parameter with selected params in current route
        const url: string = generatedBreadcrumb.url
          .toLowerCase()
          .replaceAll(uniqueKey.toLowerCase(), routeParams[routeParamsKey]);

        // check if text has daynamic parameter with lower or uppercase text(this will help you to replace acronyms with uppercase)
        let text = generatedBreadcrumb.text.replaceAll(
          uniqueKey.toUpperCase(),
          routeParams[routeParamsKey].toUpperCase()
        );

        // if text is not in uppercase search for lower case
        if (!(generatedBreadcrumb.text.search(new RegExp(`${routeParamsKey.toUpperCase()}`, "g")) >= 0)) {
          text = generatedBreadcrumb.text.replaceAll(uniqueKey, routeParams[routeParamsKey]);
        }

        if (generatedBreadcrumb.fragment) {
          generatedBreadcrumb.fragment = generatedBreadcrumb.fragment.replaceAll(
            uniqueKey,
            routeParams[routeParamsKey]
          );
        }

        // generate a breadcrumb object
        generatedBreadcrumb = {
          url,
          text,
          fragment: generatedBreadcrumb.fragment,
          queryParams: generatedBreadcrumb.queryParams,
        };
      }
      generatedBreadcrumbs.push(generatedBreadcrumb);
    }

    return generatedBreadcrumbs;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  private transformRouteChange(): { routesParams: Params; routesData: any } | undefined {
    // search for the current active route
    let child = this.activatedRoute.firstChild;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data) {
        return {
          routesData: child.snapshot.data,
          routesParams: child.snapshot.params,
        };
      } else {
        return;
      }
    }

    return;
  }

  private handleRouteChange(routesData: Params, routesParams: any): void {
    // update the breadcrumbs with daynimic data
    try {
      this.breadcrumbs = this.generateBreadcrumbData(routesData, routesParams);
    } catch (e) {
      this.breadcrumbs = [];
      this.log.error(e);
    }
  }

  ngOnInit(): void {
    // to access the route custom data globally from a parent or upper level component you need to listen to router events

    try {
      const result = this.transformRouteChange();
      if (result?.routesParams && result?.routesData) {
        const { routesData, routesParams } = result;
        this.handleRouteChange(routesData, routesParams);
      }
    } catch (e) {
      this.log.error(e);
      this.breadcrumbs = [];
    }

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.transformRouteChange()),
          filter((result) => result?.routesParams && result?.routesData)
        )
        .subscribe(
          ({ routesData, routesParams }) => this.handleRouteChange(routesData, routesParams),
          (e) => {
            this.log.error(e);
            this.breadcrumbs = [];
          }
        )
    );
  }
}
