import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TitleWrapperService } from "./shared/services/title-wrapper.service";
import { EventsService } from "./shared/services/events.service";
import { AuthenticationService } from "./shared/services/authentication.service";
import { TenantService } from "./shared/services/tenant.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TutorialService } from "./shared/services/tutorial.service";
import { filter } from "rxjs/operators";

const layout = {
  bottom: [
    "/dashboard/",
    "/scheduler/",
    "/patch-mgmt/",
    "/auto-remediation/",
    "/endpoints",
    "/tenancies/permissions",
    "/dashboard#change-approvals",
    "/api-credentials",
    "/api-users",
  ],
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public showLoad = false;
  public feedbackEmail = "dl-vfgroupvcloud@internal.vodafone.com";
  public mailToURL = "";
  public isLLMPage = false;
  public isRight = false;
  public isLoggedIn = false;

  public popUpAIActivated = false;

  public set showAI(v: boolean) {
    this.events.aiPopUpVisible.next(v);
  }

  public get showAI(): boolean {
    return this.events.aiPopUpVisible.value;
  }

  constructor(
    public router: Router,
    public titleService: TitleWrapperService,
    private eventsService: EventsService,
    private auth: AuthenticationService,
    private bsLocaleService: BsLocaleService,
    private events: EventsService,
    public tutorial: TutorialService,
    private tenant: TenantService
  ) {}

  ngOnInit() {
    this.titleService.setTitle("");
    if (["pt-br", "hi", "de", "tr", "es", "ro", "it", "fr", "nl", "en-GB"].includes(navigator.language)) {
      this.bsLocaleService.use(navigator.language.toLowerCase());
    } else {
      this.bsLocaleService.use("en-gb");
    }

    this.tenant.customiseColorScheme(localStorage.getItem("colorScheme"));

    this.eventsService.loading.subscribe((value) => {
      this.showLoad = value;
    });

    this.setVars();

    this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe(() => {
      this.setVars();
    });

    this.events.aiPopUpVisible.subscribe((v) => {
      if (v) {
        this.popUpAIActivated = true;
      }
    });

    this.tutorial.setup();
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  private setVars(): void {
    const url = this.router?.url || "";
    this.isLoggedIn = url !== "/" && !url.includes("login") && !url.includes("code=");
    this.mailToURL = `mailto:${this.feedbackEmail}?subject=${encodeURIComponent(
      `Feedback on ${this.titleService.getTitle()}`
    )}`;
    this.isLLMPage = location.pathname.startsWith("/vcloudsmart/assistant");
    this.isRight = this.isLayoutRight();
  }

  public toggleFeedbackPopUp(): void {
    this.events.userFeedbackState.next({
      open: !this.events.userFeedbackState.value.open,
      trigger: "help side bar toggle",
    });
  }

  public get showFeedbackButton(): boolean {
    return (
      !(this.events?.userFeedbackState?.value?.open ?? false) && !(location?.pathname || "").startsWith("/vcloudsmart/")
    );
  }

  public get showContactButton(): boolean {
    return this.showFeedbackButton && this.auth.tenant === "vodafone";
  }

  public isLayoutRight(): boolean {
    const pathname = (location?.pathname || "") + (location?.hash || "");
    for (const s of layout?.bottom ?? []) {
      if (pathname.startsWith(s)) {
        return false;
      }
    }
    return true;
  }

  public async replayTutorial(): Promise<void> {
    await this.tutorial.replayTutorial();
  }

  public get tutorialInProgress(): boolean {
    return this.tutorial.tutorialInProgress;
  }

  public get currentTutorial(): string {
    return this.tutorial.currentTutorial;
  }

  public get hasTutorials(): boolean {
    return this.tutorial.hasTutorials;
  }

  protected readonly window = window;
}
