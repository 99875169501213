import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { LoggerService } from "../../shared/services/logger.service";
import { Router, RouterModule } from "@angular/router";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthorisationService } from "../../shared/services/authorisation.service";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { EventsService } from "../../shared/services/events.service";
import { AlertsService } from "../../shared/services/alerts.service";
import { CommonModule } from "@angular/common";
import { VFIconComponent } from "../../shared/component/vficon/vficon.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AlertModule } from "ngx-bootstrap/alert";
import { FormsModule } from "@angular/forms";
import { TenantService } from "../../shared/services/tenant.service";
import { VCloudSmartApiService } from "../../shared/services/v-cloud-smart-api.service";
import { environment } from "../../../environments/environment";
import { SignInEvent, UserPermissions } from "../../shared/models/tenancies";

interface NavBarItem {
  tooltip: string;
  href: string;
  innerHTML: string;
  isInvisible?: boolean;
  isDisabled?: boolean;
}

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, VFIconComponent, TooltipModule, FontAwesomeModule, AlertModule, FormsModule],
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  protected readonly environment = environment;
  public Object = Object;
  public faBars = faBars;
  public startTime: string;
  public endTime: string;
  public defaultDays = 90;
  public navBarItems: NavBarItem[] = [];
  public readonly window = window;

  public lastSignIn?: SignInEvent;
  public user: UserPermissions = { user: {}, permissions: {} };

  public get allowedTenants(): string[] {
    return (localStorage.getItem("allowedTenants") ?? "").split(",").filter(Boolean);
  }

  constructor(
    public events: EventsService,
    public authNService: AuthenticationService,
    public authZservice: AuthorisationService,
    public alerts: AlertsService,
    public vcs: VCloudSmartApiService,
    private router: Router,
    private logger: LoggerService,
    public tenant: TenantService
  ) {}

  public get currentTenant(): string {
    return this.authNService.tenant;
  }

  public set currentTenant(t: string) {
    this.authNService.tenant = t;
  }

  public get email(): string {
    return this.authNService.email;
  }

  public get pathname(): string {
    return location.pathname;
  }

  ngOnInit(): void {
    if (this.authNService.isLoggedIn()) {
      // don't wait for sign in events (not critical)
      this.authZservice
        .getUserPermissions({
          attributes: ["sign_in_events"],
        })
        .subscribe((user) => {
          const {
            user: { sign_in_events },
          } = user;
          this.lastSignIn = (sign_in_events || [])[1];
        });
      this.authZservice
        .getUserPermissions({
          attributes: ["permissions", "groups", "tenancies", "allowed_tenants", "current_tenant"],
        })
        .subscribe((user) => {
          const {
            user: { current_tenant },
          } = user;
          this.user = user;
          this.currentTenant = current_tenant;
          this.navBarItems = this.getNavBarItems(current_tenant);
        });
    }
    this.endTime = new Date().toISOString().split("T")[0];
    this.startTime = this.addDays(new Date(), -this.defaultDays).toISOString().split("T")[0];
  }

  private addDays(date: number | string | Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public logout(): void {
    this.events.loading.next(true);
    this.logger.info("signing out");
    const email = this.authNService.email;
    const tenant = this.authNService.tenant;
    this.authNService.clearSession();

    let observable = of(null);

    if (email) {
      observable = this.authNService.logout(email);
    }

    observable.pipe(catchError(() => of(null))).subscribe(() => {
      if (tenant === "vodafone") {
        this.router.navigate(["/"]);
      } else {
        this.router.navigateByUrl("/login/form");
      }
      this.events.loading.next(false);
    });
  }

  public switchTenant(newTenant: string): void {
    if (newTenant !== this.currentTenant) {
      this.authNService.tenant = newTenant;
      sessionStorage.removeItem("expensiveQueries");
      localStorage.removeItem("links");
      localStorage.removeItem("responses");
      localStorage.removeItem("exampleQuestions");
      for (const key of Object.keys(sessionStorage).filter(
        (k) => k.startsWith("response-") || k.startsWith("cache-")
      )) {
        sessionStorage.removeItem(key);
      }
      window.location.reload();
    }
  }

  private getNavBarItems(tenant: string): NavBarItem[] {
    return [
      {
        tooltip: "View information about PCS on Confluence",
        href: "https://confluence.sp.vodafone.com/x/kQR4C",
        innerHTML: "About",
        isDisabled: tenant !== "vodafone",
      },
      {
        tooltip: "View documentation about the PCS Portal on Confluence",
        href: "https://confluence.sp.vodafone.com/x/ixNyEg",
        innerHTML: "FAQ",
        isDisabled: tenant !== "vodafone",
      },
      {
        tooltip: "Request a new feature in the PCS Portal in the Service Catalogue",
        href: "https://servicecatalogue.vodafone.com/catalogue?id=sc_cat_item&sys_id=53b419e333c871d03f7343bfcd5c7b7a",
        innerHTML: "Feature Request",
        isDisabled: tenant !== "vodafone",
      },
      // {
      //   tooltip: "Report a problem in the PCS Portal in the Service Catalogue",
      //   href: "https://servicecatalogue.vodafone.com/catalogue/?id=sc_cat_item&sys_id=012175c887d05550997b8776cebb351f&sysparm_category=3350e5e21be53740e52e0e9c5e4bcbe0&catalog_id=-1",
      //   innerHTML: "Bug Report",
      //   isDisabled: tenant !== "vodafone",
      // },
      {
        tooltip:
          "Service requests can be used to get access to additional projects or accounts as well as for any other issues such as missing values in drop-downs (Service Catalogue).",
        href: "https://servicecatalogue.vodafone.com/catalogue/?id=sc_cat_item&sys_id=42fe382bdbb1f740ef48b5ca6896198d&sysparm_category=c364b7c1db6a84d8324a19340596196a&catalog_id=-1",
        innerHTML: "Service Request",
        isDisabled: tenant !== "vodafone",
      },
    ];
  }

  protected readonly localStorage = localStorage;
  public isExpanded = false;
  protected readonly location = location;
}
