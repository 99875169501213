import { FormattedValue } from "../models/value-formatting";
import Decimal from "decimal.js";

export const SI_UNIT_MAP = [
  { suffix: "T", threshold: 1e12 },
  { suffix: "B", threshold: 1e9 },
  { suffix: "M", threshold: 1e6 },
  { suffix: "K", threshold: 1e3 },
  { suffix: "", threshold: 1 },
];
export const SI_SIZES = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
export const EMPTY_BYTES: FormattedValue = {
  unit: SI_SIZES[0],
  decimal: new Decimal(0),
  value: 0,
  fmt: "0",
};
export const EMPTY_VAL: FormattedValue = {
  unit: "",
  fmt: "0",
  decimal: new Decimal(0),
  value: 0,
};
export const ATTR_REGEX = /^((?<table>Table_\d+)\.)?(?<rest>.+)/;
