import { Injectable } from "@angular/core";
import { PcsApiService } from "./pcs-api.service";
import { Observable } from "rxjs";
import { LoggerService } from "./logger.service";

import { Event } from "../models/monitoring";

const NO_REPORT_URLS = ["/", "/login/form", "/dashboard"];

@Injectable({
  providedIn: "root",
})
export class MetricCollectionService {
  constructor(private api: PcsApiService, private log: LoggerService) {}

  private publishMetricData(events: Event[], service: string): Observable<void> {
    this.log.info("not sending metric data, API changes not published to PROD yet");
    this.log.info({ msg: "publishing metrics", events });
    return this.api.post<void>(`tenancies-v1/metadata/services/${service}/metrics`, {
      Events: events,
    });
  }

  private publishMetricEvent(event: Event, service: string): void {
    this.publishMetricData([event], service).subscribe(
      (response) => {
        this.log.info({ msg: "metric published", event, response });
      },
      (e) => {
        this.log.error(e);
      }
    );
  }

  public reportAction(action: string, dimensions: { [key: string]: string }, value = 1, service = "PCS-1013"): void {
    try {
      this.publishMetricEvent(
        {
          Dimensions: Object.entries(dimensions).map(([k, v]) => ({ Key: k, Value: v })),
          Event: action,
          Value: value,
        },
        service
      );
    } catch (e) {
      this.log.error(e);
    }
  }

  public reportCurrencyChanged(newCurrency: string): void {
    return this.reportAction("CurrencyChanged", { Currency: newCurrency });
  }

  public reportWidthChanged(page: string, sizeName: string): void {
    this.reportAction("ReportWidthChanged", { Width: sizeName, Page: this.sanitiseUrl(page) });
  }

  public reportSwitchedToLegacy(page: string): void {
    return this.reportAction("SwitchedToLegacy", { Page: this.sanitiseUrl(page) });
  }

  public reportFiltersApplied(page: string): void {
    return this.reportAction("FiltersApplied", { Page: this.sanitiseUrl(page) });
  }

  public reportReportShared(page: string): void {
    return this.reportAction("ReportShared", { Page: this.sanitiseUrl(page) });
  }

  private sanitiseUrl(url: string): string {
    try {
      return new URL(`https://localhost${url}`).pathname.split("/").map(decodeURIComponent).join("/");
    } catch (e) {
      this.log.error(e);
    }
  }

  public reportPageVisited(page: string): void {
    const pathname = this.sanitiseUrl(page);
    if (pathname && !NO_REPORT_URLS.includes(pathname)) {
      return this.reportAction("PageVisited", { Page: pathname });
    }
  }
}
