import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, map, retry, tap } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { environment } from "../../../environments/environment";
import { LoggerService } from "./logger.service";
import Swal from "sweetalert2";
import { EventsService } from "./events.service";
import { TenantService } from "./tenant.service";
import { SignInDetails, TokenResponse } from "../models/authentication";
import { TrialUserDetails } from "../models/trial";

const UNDERSCORE_REGEX = /^[^_]+_/;

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public oafApiUrl: string;

  constructor(
    private http: HttpClient,
    private events: EventsService,
    private tenantService: TenantService,
    private logger: LoggerService
  ) {
    this.oafApiUrl = environment.url;
  }

  public get tenant(): string {
    const t = localStorage.getItem("tenant");
    return t === undefined || t === "undefined" ? "vodafone" : t;
  }

  public set tenant(t: string) {
    if (t) {
      localStorage.setItem("tenant", t);
    } else {
      localStorage.removeItem("tenant");
    }
  }

  public getSignInDetails(email: string): Observable<SignInDetails[]> {
    return this.http
      .get<{
        Resources: SignInDetails[];
      }>(`${environment.urlPcsApi}/tenancies-v1/authentication/${email}`)
      .pipe(
        map((response) => response.Resources),
        tap((response) => {
          localStorage.setItem("matchingTenants", JSON.stringify(response));
        })
      );
  }

  public getTrialJWT(
    trialUserDetails: TrialUserDetails
  ): Observable<{ IdToken: string; AccessToken: string; ExpiresIn?: number }> {
    return this.http
      .get<{
        IdToken: string;
        AccessToken: string;
        ExpiresIn?: number;
      }>(
        `${environment.urlPcsApi}/tenancies-v1/trial/user?${Object.entries(trialUserDetails)
          .filter((e) => !!e[1])
          .map((e) => e.join("="))
          .join("&")}`
      )
      .pipe(retry(2));
  }

  public onLoginClickCognitoV2SignIn(): void {
    this.events.loading.next(true);
    this.getSignInDetails("example@vodafone.com").subscribe((response) => {
      this.tenant = "vodafone";
      this.logger.log("On Click Sign in with cognito credentials");
      const { ClientId, OAuth2Flow, OAuth2Domain } = response[0];
      const guid = uuidv4();
      localStorage.setItem("state", guid);
      const urlSigninAAD = `https://${OAuth2Domain}/login?redirect_uri=${encodeURIComponent(
        `${location.protocol}//${location.host}/`
      )}&response_type=${OAuth2Flow}&client_id=${ClientId}&state=${guid}`;
      window.location.assign(urlSigninAAD);
    });
  }

  public onLoginClickAADGeneric(domain: string, flow: string, idp: string, clientId: string, scope: string): void {
    this.logger.log("On Click Sign in with vodafone credentials");
    const guid = uuidv4();
    localStorage.setItem("state", guid);
    const urlSigninAAD = `https://${domain}/authorize?identity_provider=${idp}&response_type=${flow}&client_id=${clientId}&redirect_uri=${environment.callback}&scope=${scope}&state=${guid}`;
    window.location.assign(urlSigninAAD);
  }

  public onLoginClickAAD(): void {
    const { ClientId, Idp, OAuth2Domain, OAuth2Flow, OAuth2Scopes } = JSON.parse(
      localStorage.getItem("matchingTenants") ?? "[]"
    )[0];
    return this.onLoginClickAADGeneric(OAuth2Domain, OAuth2Flow, Idp, ClientId, OAuth2Scopes);
  }

  public signInAAD(code: string) {
    const redirect = location.hostname === "localhost" ? `http://${location.host}/` : undefined;
    return this.http.post<TokenResponse>(`${this.oafApiUrl}signinaad`, { code, redirect }).pipe(
      catchError((e) => {
        this.logger.error(e);
        return throwError(e);
      })
    );
  }

  public isLoggedIn(): boolean {
    try {
      const accessTokenString = localStorage.getItem("ACCESS_TOKEN");
      if (accessTokenString) {
        const accessToken = JSON.parse(accessTokenString);
        const IdToken = accessToken.IdToken;
        if (IdToken) {
          const timerString = localStorage.getItem("timer");
          if (timerString) {
            const timer = JSON.parse(timerString);
            if (timer && Date.now() < timer) {
              return true;
            }
          }
        }
      }
    } catch (e) {
      this.logger.error(e);
    }
    return false;
  }

  public logout(email: string): Observable<any> {
    return this.http.post(`${this.oafApiUrl}signout`, { email }).pipe(
      catchError((e) => {
        this.logger.error(e);
        return throwError(e);
      })
    );
  }

  public getIdToken(): string {
    if (localStorage.getItem("runningCypressTest")) {
      return "asdlkfjaslkdjflaskdjfalsdf";
    }
    try {
      const accessTokenString = localStorage.getItem("ACCESS_TOKEN");
      if (accessTokenString) {
        const accessToken = JSON.parse(accessTokenString);
        const IdToken = accessToken?.IdToken;
        if (IdToken) {
          const timerString = localStorage.getItem("timer");
          if (timerString) {
            const timer = JSON.parse(timerString);
            if (timer && Date.now() < timer) {
              return IdToken;
            }
          }
        }
      }
    } catch (e) {
      this.logger.error(e);
    }
    throw new Error("Session Expired");
  }

  public autoLogout(): void {
    const email = this.email;

    this.logger.log({ email });

    (email ? this.logout(email) : of(null)).pipe(catchError(() => of(null))).subscribe(async () => {
      this.events.loading.next(false);

      // clear storage after subscribe, this will let the api consume storage data before logout
      this.clearSession();

      localStorage.setItem("navigateToUrlAfterAuth", location.pathname);

      await Swal.fire({
        icon: "warning",
        title: "Session Expired",
        text: `Your session has expired, click on the button below to re-authenticate.`,
        confirmButtonText: "Reauthenticate",
        allowOutsideClick: false,
        confirmButtonColor: this.tenantService.colors.primary,
        footer: "",
      });
      if (environment.local && location.hostname === "localhost") {
        this.onLoginClickCognitoV2SignIn();
      } else {
        this.onLoginClickAAD();
      }
    });
  }

  public get claims(): { [key: string]: any } {
    try {
      const v = localStorage.getItem("ACCESS_TOKEN");
      if (v) {
        const { AccessToken } = JSON.parse(v);
        if (AccessToken) {
          const parts = AccessToken.split(".");
          if (parts.length > 1) {
            const claims = parts[1];
            if (claims) {
              return JSON.parse(atob(claims));
            }
          }
        }
      }
    } catch (e) {}
    return {};
  }

  public get email(): string | undefined {
    try {
      const claims = this.claims;
      const email = claims.username || claims.email || "";
      if (email) {
        return email.replace(UNDERSCORE_REGEX, "");
      }
    } catch (e) {
      this.logger.error("Failed to get email from claims", e);
    }
  }

  public clearSession(): void {
    localStorage.removeItem("ACCESS_TOKEN");
    sessionStorage.removeItem("capturedResponsesVcs");
    sessionStorage.removeItem("capturedResponsesPcsApi");
    localStorage.removeItem("timer");
    sessionStorage.removeItem("expensiveQueries");
    for (const key of Object.keys(sessionStorage).filter((k) => k.startsWith("response-") || k.startsWith("cache-"))) {
      sessionStorage.removeItem(key);
    }
  }
}
