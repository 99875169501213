import { Component, OnDestroy, OnInit } from "@angular/core";
import { trigger, transition, style, animate, state } from "@angular/animations";
import { PcsApiService } from "../../shared/services/pcs-api.service";
import { mergeMap, tap } from "rxjs/operators";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { CommonModule } from "@angular/common";
import { VFIconComponent } from "../../shared/component/vficon/vficon.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { RatingModule } from "ngx-bootstrap/rating";
import { FormsModule } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { LoggerService } from "../../shared/services/logger.service";
import { EventsService } from "../../shared/services/events.service";
import { AuthorisationService } from "../../shared/services/authorisation.service";

interface FeedbackQuestion {
  title: string;
  metricId: string;
  rate: number;
}

@Component({
  standalone: true,
  imports: [CommonModule, VFIconComponent, TooltipModule, RatingModule, FormsModule],
  selector: "app-user-feedback",
  templateUrl: "./user-feedback.html",
  styleUrls: ["./user-feedback.scss"],

  // animate popup transition
  animations: [
    trigger("slideInOut", [
      state(
        "closed",
        style({
          transform: "translateX(100%)",
        })
      ),
      state(
        "open",
        style({
          transform: "translateX(-20%)",
        })
      ),
      transition("* => *", animate(300)),
    ]),
  ],
})
export class UserFeedbackComponent implements OnInit, OnDestroy {
  private developing = false; // true when testing and making changes
  private subscriptions: Subscription[] = [];
  public numberOfStars: number = 5;
  public modified = false;
  private timeouts: number[] = [];
  public feedbackText: string;
  public feedbackQuestion: FeedbackQuestion = {
    title: "How likely are you to recommend the PCS Portal to others?",
    metricId: "UserLikelihoodOfRecommendationRating",
    rate: undefined,
  };

  constructor(
    private api: PcsApiService,
    private authN: AuthenticationService,
    private authZ: AuthorisationService,
    private log: LoggerService,
    public events: EventsService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.timeouts.forEach((s) => clearTimeout(s));
  }

  ngOnInit() {
    if (environment.production || this.developing) {
      this.showFeedbackPopUP();
    }
    this.events.userFeedbackState.subscribe((state) => {
      if (state.open && state.trigger === "help side bar toggle") {
        this.feedbackGiven = false;
      }
    });
  }

  closeFeedback(): void {
    // add to local storage the date when the user refused feedback
    localStorage.setItem("FeedbackDate", new Date().toJSON());
    this.feedbackGiven = true;
    this.events.userFeedbackState.next({ open: false, trigger: "feedback closed" });
  }

  openFeedback(): void {
    this.events.userFeedbackState.next({ open: true, trigger: "scheduled timer" });
  }

  // wait for user to log in and then show the feedback
  public showFeedbackPopUP(): void {
    // calculate how many days have passed since user last feedback
    const feedbackDate = localStorage.getItem("FeedbackDate")
      ? new Date(localStorage.getItem("FeedbackDate"))
      : new Date("2000-01-01");
    const currentDate = new Date();
    const diffDays = Math.floor((currentDate.getTime() - feedbackDate.getTime()) / 1000 / 60 / 60 / 24);

    // show the feedback popup only if the user is logged in and if more that 30 days have passed
    if (!this.authN.isLoggedIn()) {
      // reapeat until user loggin
      this.timeouts.push(setTimeout(() => this.showFeedbackPopUP(), 10000) as unknown as number);
    } else if (diffDays >= 30 || this.developing) {
      // open the feedback after 3 minutes
      this.timeouts.push(setTimeout(() => this.openFeedback(), this.developing ? 0 : 120000) as unknown as number);
    }
  }

  // set user selected rate
  public updateFeedbackRate(value: number) {
    this.modified = true;
    this.feedbackQuestion.rate = value;
  }

  public getTenancyWithMostGroups(groups: { [key: string]: Array<string> }) {
    let selectedGroupLength = 0;
    let selectedTenancy = "";

    // get the tenancy with the most alocated groups
    for (let key in groups) {
      if (groups[key].length >= selectedGroupLength) {
        selectedTenancy = key;
        selectedGroupLength = groups[key].length;
      }
    }

    return selectedTenancy;
  }

  private sendTextFeedback(rating: number, feedback: string, email?: string): Observable<unknown> {
    if ((this.feedbackText || "").trim()) {
      const body: any = {
        Feedback: feedback,
        Rating: rating,
      };
      if (email) {
        body.Email = email;
      }
      return this.api.post("tenancies-v1/metadata/services/PCS-1013/user-feedback", body).pipe(
        tap(() => {
          this.feedbackText = "";
        })
      );
    } else {
      return of(null);
    }
  }

  public feedbackGiven = false;
  public allowContact = true;

  public sendFeedback(allowContact: boolean): void {
    // add to local storage the date when the user sent the feedback
    localStorage.setItem("FeedbackDate", new Date().toJSON());

    this.feedbackGiven = true;

    this.subscriptions.push(
      forkJoin({
        rating: this.sendFeedbackRating(),
        text: this.sendTextFeedback(
          this.feedbackQuestion?.rate ?? 3,
          this.feedbackText,
          allowContact ? this.authN.email : undefined
        ),
      }).subscribe(
        () => {
          // close feedback popup after the 3 seconds
          this.timeouts.push(
            setTimeout(() => {
              this.feedbackQuestion.rate = undefined;
              this.events.userFeedbackState.next({ open: false, trigger: "submitted" });
            }, 2000) as unknown as number
          );
        },
        (error) => {
          this.log.error(error);
          // close feedback popup after the 3 seconds
          this.timeouts.push(
            setTimeout(() => {
              this.events.userFeedbackState.next({ open: false, trigger: "failed" });
            }, 2000) as unknown as number
          );
        }
      )
    );
  }

  private sendFeedbackRating(): Observable<unknown> {
    return (
      this.authZ
        // get user details
        .getUserPermissions({
          attributes: ["groups"],
        })
        .pipe(
          mergeMap(({ user: { groups } }) => {
            const tenancy = this.getTenancyWithMostGroups(groups);
            const dimensions = [{ Key: "CurrentPage", Value: location.pathname }];

            // add dimension only if a tenancy was found
            if (tenancy) {
              dimensions.push({ Key: "LocalMarket", Value: tenancy });
            }

            // set the feedback with user selected rating
            return this.api.post(`tenancies-v1/metadata/services/PCS-1013/metrics`, {
              Events: [
                {
                  Event: this.feedbackQuestion.metricId,
                  Value: this.feedbackQuestion.rate,
                  Dimensions: dimensions,
                },
              ],
            });
          })
        )
    );
  }
}
